import styled from "styled-components";
import { Menu, Search } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import { md } from "../../styles/responsive";
import { TextField, Input, FormControl } from "@mui/material";

export const FormInputSelect = styled(FormControl)`
  display: block;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #c5c5c5;
  border-radius: 6px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  .MuiInput-underline:before {
    border-bottom: none !important;
  }
  .MuiInput-underline:after {
    border-bottom: none !important;
  }
  .MuiInputBase-root {
    width: 100%;
  }
  .MuiInputBase-root {
    font-weight: 500 !important;
  }
  .MuiInput-underline {
    &:before {
      position: relative !important;
    }
  }
`;

export const MenuIcon = styled(Menu)`
  color: ${({ color }) => color};
`;

export const FooterContainer = styled.div`
  background: #D5AE35;
  display: flex;
  align-items: center;
  padding: 18rem 0 2.5rem 0;
  /* box-shadow: 1px -9px 5px -3px rgba(240,240,240,1); */
  position: relative;
  width: 100%;
  z-index: 1;

  @media screen and (min-width: 992px) {
    padding: 8rem 0 2.5rem 0;
  }
`;

export const FooterList = styled.div`
  display: block;
  width: 100%;
`;

export const NavbarUpper = styled.div`
  justify-content: space-between;
  align-items: center;
  display: flex;
  flex-grow: 1;
`;

export const NavbarLower = styled.div`
  justify-content: center;
  display: flex;
  flex-grow: 1;
  padding: 1rem 0;
`;

export const NavbarLowerList = styled.div`
  padding-left: 0;
  margin-bottom: 0;
  margin-top: 0;
  list-style: none;
  align-items: center;
  display: flex;
`;

export const NavbarLink = styled.a`
  color: ${({ color }) => color};
  font-weight: ${({ bold }) => bold};
  // align-items: center;
  display: block !important;
  position: relative;
  margin-bottom: 8px;
  font-size: 14px;
  cursor: pointer;
  display: block;
  // margin: 0 1rem;
  margin-bottom: 1rem;
  // justify-content: center;

  @media screen and (min-width: 788px) {
    // display: flex;
  }

  // @media screen and (min-width: 992px) {
  //   margin-bottom: 0;
  // }

  &:last-child {
    border-right: unset;
  }
`;

export const NavbarLogo = styled.a`
  width: 80px;
  // padding-top: 1rem;
  // display: block;
  @media screen and (min-width: 992px) {
    width: 80px;
    // padding-top: 0rem;
  }

  @media screen and (min-width: 1440px) {
    width: 80px;
  }
  /* padding-top: 8px; */
  img {
    width: 80px;

    @media screen and (min-width: 992px) {
      width: 80px;
    }

    @media screen and (min-width: 1440px) {
      width: 80px;
    }
  }
`;

export const EmailInput = styled(TextField)`
  display: block !important;
  /* padding: 0.375rem 0.75rem !important; */
  font-size: 1rem;
  line-height: 1.5;
  color: #fff;
  background: ${(props) => props.bg};
  background-clip: padding-box;
  /* border: 1px solid #eaeaea; */
  /* border: ${(props) => props.border}; */
  border-radius: 5px;
  position: absolute !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 94%;

  .MuiInput-underline:before {
    border-bottom: none !important;
  }
  .MuiInput-underline:after {
    border-bottom: none !important;
  }
  .MuiInputBase-root {
    width: 100%;
  }
  .MuiInputBase-root {
    font-weight: 500 !important;
  }
  .MuiInput-underline {
    &:before {
      position: absolute !important;
    }
  }
  /*.MuiInputBase-root-MuiOutlinedInput-root{
    color: unset;
  } */
  .MuiOutlinedInput-notchedOutline {
    border: ${(props) => (props.border ? props.border : "unset")};
  }

  .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
    color: #fff;
    font-weight: 500;
  }
  .css-1sqnrkk-MuiInputBase-input-MuiOutlinedInput-input {
    color: #fff;
    font-weight: 500;
  }

  .MuiFormControl-root-MuiTextField-root {
    position: absolute;
  }
`;

export const ModalBox = styled.div`
  position: absolute;
  background-color: #2c3248;
  /* background-image: url("../assets/images/bgoverlaymodal.png"); */
  /* width: 100%;
	background-size: 100% 100%; */
  top: 50%;
  width: 90%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* box-shadow: 0 0 5px #ccc; */
  /* padding: 1rem; */
  /* height: 750px; */
  overflow: scroll;
  border-radius: 25px;
  @media screen and (min-width: 768px) {
    width: 50%;
  }
  @media screen and (min-width: 992px) {
    width: 40%;
  }
  @media screen and (min-width: 1920px) {
    width: 24%;
  }
`;

const config = {
  theSocial: {
    position: "relative",
    width: "8%",
    marginRight: "1rem",
    "& img": {
      width: "100%",
    },
  },

  theFlex: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingTop: "1rem",

    ...md("sm", {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      width: "60%",
      margin: "0 auto",
    }),
  },
};

export default makeStyles(config);
