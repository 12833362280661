import React, { useState } from "react";
import { Routes as Router, Route } from "react-router-dom";
import HomePage from "./pages/Home/Home";
import LoginPage from "./pages/Login/Login";
import RegisterPage from "./pages/Register/Register";
import CheckoutPage from "./pages/Checkout/Checkout";
import AboutPage from "./pages/About/About";
import ContactPage from "./pages/Contact/Contact";
import FAQPage from "./pages/FAQ/FAQ";
import ProductPillowPage from "./pages/ProductPillow/ProductPillow";
import ProductMattressPage from "./pages/ProductMattress/ProductMattress";
import SalePage from "./pages/Sale/Sale";
import ProductPillowDetailPage from "./pages/ProductPillowDetail/ProductPillowDetail";
import ProductDetailPage from "./pages/ProductDetail/ProductDetail";
import ProductMattressDetailPage from "./pages/ProductMattressDetail/ProductMattressDetail";
import CartPage from "./pages/Cart/Cart";
import DashboardMemberPage from "./pages/DashboardMember/DashboardMember";
import DashboardOrderPage from "./pages/DashboardOrder/DashboardOrder"
import DashboardReferralPage from "./pages/DashboardReferral/DashboardReferral"
import DashboardOrderAgentPage from "./pages/DashboardOrderAgent/DashboardOrderAgent"
import DashboardMemberReferralPage from "./pages/DashboardMemberReferral/DashboardMemberReferral"
import DashboardAgentReferralPage from "./pages/DashboardAgentReferral/DashboardAgentReferral"
import DashboardAllReferralPage from "./pages/DashboardAllReferral/DashboardAllReferral"
import DashboardAllPointPage from "./pages/DashboardAllPoint/DashboardAllPoint"
import DashboardSettingPage from "./pages/DashboardSetting/DashboardSetting"
import DashboardAgentPage from "./pages/DashboardAgent/DashboardAgent"
import DashboardAgentWalletPage from "./pages/DashboardAgentWallet/DashboardAgentWallet"
import OrderDetailPage from "./pages/OrderDetail/OrderDetail"
import PaymentPage from "./pages/Payment/Payment";
import ChooseMattress from "./pages/Blog/ChooseMattress";
import BadMattress from "./pages/Blog/BadMattress";
import MemoryFoam from "./pages/Blog/MemoryFoam";

export default function Routes() {
  return (
    <Router>
      <Route path="/" element={<HomePage />} />
      <Route path="/articles/ChooseMattress" element={<ChooseMattress />} />
      <Route path="/articles/BadMattress" element={<BadMattress />} />
      <Route path="/articles/MemoryFoam" element={<MemoryFoam />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/register" element={<RegisterPage />} />
      <Route path="/checkout" element={<CheckoutPage />} />
      <Route path="/about" element={<AboutPage />} />
      <Route path="/contact" element={<ContactPage />} />
      <Route path="/faq" element={<FAQPage />} />
      <Route path="/pillow" element={<ProductPillowPage />} />
      <Route path="/mattress" element={<ProductMattressPage />} />
      <Route path="/sale" element={<SalePage />} />
      <Route path="/pillowdetail" element={<ProductPillowDetailPage />} />
      <Route path="/product/:product_id" element={<ProductDetailPage />} />
      <Route path="/mattressdetail" element={<ProductMattressDetailPage />} />
      <Route path="/cart" element={<CartPage />} />
      <Route path="/dashboard" element={<DashboardMemberPage />} />
      <Route path="/dashboard-agent" element={<DashboardAgentPage />} />
      <Route path="/dashboard-order" element={<DashboardOrderPage />} />
      <Route path="/dashboard-agent-order" element={<DashboardOrderAgentPage />} />
      <Route path="/dashboard-referral" element={<DashboardReferralPage />} />
      <Route path="/dashboard-member-referral" element={<DashboardMemberReferralPage />} />
      <Route path="/dashboard-agent-referral" element={<DashboardAgentReferralPage />} />
      <Route path="/dashboard-all-referral" element={<DashboardAllReferralPage />} />
      <Route path="/dashboard-all-point" element={<DashboardAllPointPage />} />
      <Route path="/dashboard-setting" element={<DashboardSettingPage />} />
      <Route path="/dashboard-agent-wallet" element={<DashboardAgentWalletPage />} />
      <Route path="/order-detail/:orders_id" element={<OrderDetailPage />} />
      <Route path="/payment/:type" element={<PaymentPage />} />
    </Router>
  );
}
