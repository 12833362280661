import styled from "styled-components/macro";
import { makeStyles } from "@mui/styles";
import { md } from "../../styles/responsive";
import bannercontact from "../../assets/images/bannerContact.png";

export const CarouselContainer = styled.div`
  position: relative;
  width: 100%;
`;

export const CarouselIMG = styled.img`
  width: 100%;
`;

const config = {
  theFont: {
    fontSize: "14px",
    ...md("md", {
      fontSize: "34px",
    }),
  },

  theBoxWhite: {
    position: "relative",
    // width: "100%",
    padding: "2rem",
    background: "#fff",
    margin: "0 auto",

    ...md("md", {
      width: "60%",
    }),
  },

  theBG: {
    position: "relative",
    background: "#F1E2C3",
  },
};

export default makeStyles(config);
