import styled from "styled-components/macro";
import { makeStyles } from "@mui/styles";
import { md } from "../../styles/responsive";
import { TextField, FormControl } from "@mui/material";

export const FormInputSelect = styled(FormControl)`
  //   display: block;
  //   padding: 0.375rem 0.75rem;
  //   font-size: 1rem;
  //   line-height: 1.5;
  //   color: #495057;
  //   background-color: #fff;
  //   background-clip: padding-box;
  //   border-bottom: 1px solid #c5c5c5;
  //   border-radius: 6px;
  //   transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  //   white-space: nowrap;
  //   overflow: hidden;
  //   text-overflow: ellipsis;
  //   width: 100%;
  //   .MuiInput-underline:before {
  //     border-bottom: none !important;
  //   }
  //   .MuiInput-underline:after {
  //     border-bottom: none !important;
  //   }
  //   .MuiInputBase-root {
  //     width: 100%;
  //   }
  //   .MuiInputBase-root {
  //     font-weight: 500 !important;
  //   }
  //   .MuiInput-underline {
  //     &:before {
  //       position: relative !important;
  //     }
  //   }
`;

const config = {
  theBG: {
    position: "relative",
    width: "100%",
    "& img": {
      width: "100%",
    },
  },

  theProd: {
    position: "relative",
    width: "100%",
    marginBottom: "1rem",

    "& img": {
      width: "100%",
    },
  },

  theBox_: {
    position: "relative",
    background: "#fff",
    padding: "1rem",
    boxShadow: "0px 0px 9px 3px rgba(0,0,0,0.1)",
  },
};

export default makeStyles(config);
