import React from "react";
import {
  AppBar,
  Container,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  useMediaQuery,
  Box,
  Button as Btn,
  Dialog,
  DialogContent,
  Grid,
  Select,
  Divider,
  Checkbox,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";
import { Link, useLocation, useNavigate } from "react-router-dom";
import SidebarMenuDashboardAgent from "../SidebarMenuDashboardAgent/SidebarMenuDashboardAgent";

import Button from "../Button/Button";
import FormInput from "../FormInput/FormInput";
import logo from "../../assets/images/logo.png";
import lorry from "../../assets/images/delivery.png";

import useStyles, {
  MenuIcon,
  NavbarContainer,
  NavbarList,
  NavbarLogo,
  NavbarLogo_,
  NavbarMobileLogo,
  NavbarIconMobileList,
  NavLinking,
  NavbarLower,
  NavbarLowerList,
  NavbarLink,
  NavbarIconLink,
  NavbarUpper,
} from "./styles";

import {
  AccountCircleOutlined,
  FavoriteBorderOutlined,
  Close,
  LockOutlined,
  Phone,
  CorporateFareOutlined,
  EmailOutlined,
  PersonOutlined,
  FeedOutlined,
  BuildOutlined,
  Public,
  InsertPhoto,
  AutoFixNormal,
  AccountCircleRounded,
  ShoppingBagOutlined,
} from "@mui/icons-material";
import Paragraph from "../Typography/Paragraph";
import "./styles.css";
const drawerWidth = 240;

const NavbarDashboardAgent = ({ children }) => {
  const history = useNavigate();
  const location = useLocation();
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const [openSideMenu, setOpenSideMenu] = React.useState({ left: false });

  const [openSideCart, setOpenSideCart] = React.useState({ right: false });

  const [openLogin, setOpenLogin] = React.useState(false);

  const [openSignUp, setOpenSignUp] = React.useState(false);

  // LOGIN & SIGN UP MODAL

  const handleOpenLogin = () => {
    setOpenLogin(true);
    setOpenSignUp(false);
  };

  const handleOpenSignUp = () => {
    setOpenSignUp(true);
    setOpenLogin(false);
  };

  const handleCloseLogin = () => {
    setOpenLogin(false);
  };

  const handleCloseSignUp = () => {
    setOpenSignUp(false);
  };

  const handleDrawerToggle = (anchor, open) => (e) => {
    if (e.type === "keydown" && (e.key === "Tab" || e.key === "Shift")) {
      return;
    }
    setOpenSideMenu({ [anchor]: open });
    setOpenSideCart({ [anchor]: open });
  };

  //

  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // HANDLE LOGOUT

  const handleLogout = () => {
    window.localStorage.removeItem("token");
    history("/");
    window.location.reload();
  };
  //

  const [token, setToken] = React.useState([]);

  React.useEffect(() => {
    let token = window.localStorage.getItem("token");
    setToken(token || "");
    console.log("CURRENT TOKEN", token);
  }, []);

  const handleChange = (e) => {
    // setLoginData({
    //   ...loginData,
    //   [e.target.name]: e.target.value,
    // });
    // setRegisterDataUser({
    //   ...registerDataUser,
    //   [e.target.name]: e.target.value,
    // });
    // setRegisterDataDesigner({
    //   ...registerDataDesigner,
    //   [e.target.name]: e.target.value,
    // });
    // setUploadForm({
    //   ...uploadform,
    //   [e.target.name]: e.target.value,
    // });
  };

  return (
    <>
      <NavbarContainer>
        <div
          style={{
            padding: ".8rem",
            background: "#402313",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div className={classes.theLorry}>
            <img src={lorry} />
          </div>
          <Paragraph center color="#fff" size="14px">
            FREE Delivery ! * Terms & Condition
          </Paragraph>
        </div>
        <Container maxWidth="xxl">
          <AppBar position="static">
            <Toolbar>
              {isMobile ? (
                <>
                  <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    onClick={handleDrawerToggle("left", true)}
                  >
                    <MenuIcon color="#402313" />
                  </IconButton>
                  <NavbarMobileLogo>
                    <Link to="/">
                      <img src={logo} />
                    </Link>
                  </NavbarMobileLogo>
                  <NavbarIconMobileList>
                    <div style={{ display: "flex" }}>
                      <a href="/login" style={{ marginRight: "2rem" }}>
                        <AccountCircleOutlined style={{ color: "#D5AE35" }} />
                      </a>
                      <a href="/cart">
                        <ShoppingBagOutlined style={{ color: "#D5AE35" }} />
                      </a>
                    </div>
                    {/* <NavbarIconLink onClick={handleDrawerToggle("right", true)}>
                    <ShapeCircle margin={"0 0 0 1rem"}>
                      <Icon src={shoppingcart} />
                    </ShapeCircle>
                    {cart !== undefined ? (
                      <Badge>{cart ? cart.length : null}</Badge>
                    ) : (
                      <></>
                    )}
                  </NavbarIconLink> */}
                    {/* {!token ? (
                      <NavbarIconLink onClick={handleMenu}>
                        <AccountCircleRounded style={{ color: "#28329B" }} />
                      </NavbarIconLink>
                    ) : (
                      <></>
                    )} */}
                  </NavbarIconMobileList>

                  <SidebarMenuDashboardAgent
                    anchor="left"
                    open={openSideMenu.left}
                    onOpen={handleDrawerToggle("left", true)}
                    onClose={handleDrawerToggle("left", false)}
                    handleDrawerToggle={handleDrawerToggle("left", false)}
                  />
                </>
              ) : (
                <>
                  <NavbarList>
                    <NavbarUpper>
                      {/* <Container> */}
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "100%",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            marginLeft: "auto",
                            width: "50%",
                          }}
                        >
                          <NavbarLogo>
                            <a href="/">
                              <img src={logo} />
                            </a>
                          </NavbarLogo>
                        </div>

                        <div style={{ display: "flex" }}>
                          <a href="/login" style={{ marginRight: "2rem" }}>
                            <AccountCircleOutlined
                              style={{ color: "#D5AE35" }}
                            />
                          </a>
                          <a href="/cart">
                            <ShoppingBagOutlined style={{ color: "#D5AE35" }} />
                          </a>
                        </div>
                      </div>
                      {/* </Container> */}
                    </NavbarUpper>
                    <NavbarLower>
                      <NavbarLowerList>
                        {/* <Container> */}
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            width: "60%",
                            margin: "0 auto",
                          }}
                        >
                          <NavbarLink color="#D5AE35" href="/">
                            <div>
                              <Paragraph
                                center
                                color="#D5AE35"
                                bold="700"
                                className={classes.theFont}
                              >
                                HOME
                              </Paragraph>
                            </div>
                          </NavbarLink>
                          <NavbarLink color="#000" href="/pillow">
                            <div>
                              <Paragraph
                                center
                                color="#000"
                                // bold="700"
                                className={classes.theFont}
                              >
                                PILLOW
                              </Paragraph>
                            </div>
                          </NavbarLink>
                          <NavbarLink color="#000" href="/mattress">
                            <div>
                              <Paragraph
                                center
                                color="#000"
                                // bold="700"
                                className={classes.theFont}
                              >
                                MATTRESS
                              </Paragraph>
                            </div>
                          </NavbarLink>
                          <NavbarLink color="#000" href="/sale">
                            <div>
                              <Paragraph
                                center
                                color="#000"
                                // bold="700"
                                className={classes.theFont}
                              >
                                SALE
                              </Paragraph>
                            </div>
                          </NavbarLink>
                          <NavbarLink color="#000" href="/about">
                            <div>
                              <Paragraph
                                center
                                color="#000"
                                // bold="700"
                                // size="14px"
                                className={classes.theFont}
                              >
                                WHY WOODPECKER
                              </Paragraph>
                            </div>
                          </NavbarLink>
                          <NavbarLink color="#000" href="/contact">
                            <div>
                              <Paragraph
                                center
                                color="#000"
                                // bold="700"
                                // size="14px"
                                className={classes.theFont}
                              >
                                CONTACT US
                              </Paragraph>
                            </div>
                          </NavbarLink>

                          {/* {!token ? (
                            <>
                              <NavbarIconLink onClick={handleMenu}>
                                <AccountCircleRounded
                                  style={{ color: "#28329B" }}
                                />
                              </NavbarIconLink>
                            </>
                          ) : (
                            <>
                              <a onClick={() => handleOpenLogin()}>
                                <Button
                                  label="Login"
                                  color="#FFB90F"
                                  // bg="#28329B"
                                  box="1px solid #FFB90F"
                                  bold="600"
                                  br="6px"
                                  size="14px"
                                  style={{
                                    // marginRight: 18,
                                    marginLeft: "0rem",
                                  }}
                                />
                              </a>
                              <a onClick={() => handleOpenSignUp()}>
                                <Button
                                  label="JOIN NOW"
                                  color="#fff"
                                  bg="#00b9f0"
                                  bold="600"
                                  br="6px"
                                  size="14px"
                                  style={{
                                    // marginRight: 18,
                                    marginLeft: "0rem",
                                  }}
                                />
                              </a>
                            </>
                          )} */}
                        </div>
                        {/* </Container> */}
                      </NavbarLowerList>
                    </NavbarLower>
                  </NavbarList>
                </>
              )}

              <>
                <Menu
                  id="menu-appbar"
                  elevation={0}
                  // getContentAnchorEl={null}
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                  // anchorPosition={{
                  //   top: 300,
                  // }}
                  keepMounted
                  open={open}
                  onClose={handleClose}
                >
                  <MenuItem>
                    <NavLinking>
                      <div>
                        <Button
                          label="Profile"
                          bg="#28329B"
                          color="#fff"
                          bold="500"
                          br="4px"
                          pd=".2rem 1rem"
                          size="12px"
                          href="/profile"
                          style={{ marginBottom: ".6rem" }}
                        />

                        <Button
                          label="Sign Out"
                          bg="#28329B"
                          color="#fff"
                          bold="500"
                          br="4px"
                          pd=".2rem 1rem"
                          size="12px"
                          href="/profile"
                        />
                      </div>
                    </NavLinking>
                  </MenuItem>
                </Menu>
              </>
            </Toolbar>
          </AppBar>
        </Container>

      </NavbarContainer>

      <div className={classes.theDashboard}>
        <div className={classes.theDNav}>
          <div className={classes.theTag}>
            <Paragraph
              center
              color="#fff"
              size="14px"
              bold="bold"
              margin="0 0 6px"
            >
              Helen Regel
            </Paragraph>
            <Paragraph
              center
              color="#fff"
              size="10px"
              bold="normal"
            >
              Agent
            </Paragraph>
          </div>
          <div className={classes.theNavItem}>
            <Paragraph
              center
              color={location.pathname.replace(/[^A-Za-z]+/g, '') === 'dashboardagent' ? "#402313" : "#000"}
              size="12px"
              bold={location.pathname.replace(/[^A-Za-z]+/g, '') === 'dashboardagent' ? "bold" : "light"}
              style={{ padding: "15px" }}
              onClick={() => history('../dashboard-agent')}
            >
              My Profile
            </Paragraph>
            <Paragraph
              center
              color={location.pathname.replace(/[^A-Za-z]+/g, '') === 'dashboardagentwallet' ? "#402313" : "#000"}
              size="12px"
              bold={location.pathname.replace(/[^A-Za-z]+/g, '') === 'dashboardagentwallet' ? "bold" : "light"}
              style={{ padding: "15px" }}
              onClick={() => history('../dashboard-agent-wallet')}
            >
              My Wallet
            </Paragraph>
            <Paragraph
              center
              color={location.pathname.replace(/[^A-Za-z]+/g, '') === 'dashboardagentorder' ? "#402313" : "#000"}
              size="12px"
              bold={location.pathname.replace(/[^A-Za-z]+/g, '') === 'dashboardagentorder' ? "bold" : "light"}
              style={{ padding: "15px" }}
              onClick={() => history('../dashboard-agent-order')}
            >
              My Orders
            </Paragraph>
            <Paragraph
              center
              color={location.pathname.replace(/[^A-Za-z]+/g, '') === 'dashboardagentreferral' ? "#402313" : "#000"}
              size="12px"
              bold={location.pathname.replace(/[^A-Za-z]+/g, '') === 'dashboardagentreferral' ? "bold" : "light"}
              style={{ padding: "15px" }}
              onClick={() => history('../dashboard-agent-referral')}
            >
              My Referrals
            </Paragraph>
            <Paragraph
              center
              color={location.pathname.replace(/[^A-Za-z]+/g, '') === 'dashboardsetting' ? "#402313" : "#000"}
              size="12px"
              bold={location.pathname.replace(/[^A-Za-z]+/g, '') === 'dashboardsetting' ? "bold" : "light"}
              style={{ padding: "15px" }}
              onClick={() => history('../dashboard-setting')}
            >
              Settings
            </Paragraph>
            <Paragraph
              center
              color="#000"
              size="12px"
              bold="light"
              style={{ padding: "15px" }}
            >
              Logout
            </Paragraph>
          </div>
        </div>
        <div className={classes.theDBody}>
          {isMobile &&
            <div className={classes.theTag}>
              <Paragraph
                center
                color="#fff"
                size="14px"
                bold="bold"
              >
                Helen Regel
              </Paragraph>
              <div className={classes.theTFlex}>
                <Paragraph
                  center
                  color="#fff"
                  size="10px"
                  margin="0 6px 0 0"
                  style={{ borderRight: "1px solid #fff", paddingRight: "6px" }}
                >
                  0000 points
                </Paragraph>
                <Paragraph
                  center
                  color="#fff"
                  size="10px"
                >
                  Member
                </Paragraph>
              </div>
            </div>
          }
          {children}
        </div>
      </div>
    </>
  );
};

export default NavbarDashboardAgent;
