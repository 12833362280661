import styled from "styled-components/macro";
import { makeStyles } from "@mui/styles";
import { md } from "../../styles/responsive";
import bannercontact from "../../assets/images/bannerContact.png";

export const CarouselContainer = styled.div`
  position: relative;
  width: 100%;
`;

export const CarouselIMG = styled.img`
  width: 100%;
`;

const config = {
  theBG_: {
    position: "relative",
    padding: "2rem",
    background: "#402313",
  },

  theLogo: {
    position: "relative",
    width: "50%",
    margin: "0 auto",

    "& img": {
      width: "100%",
    },
  },

  theBox_: {
    position: "relative",
    background: "#F1E2C3",
    height: "130px",
    padding: "1rem",
    boxShadow: "0px 0px 9px 3px rgba(0,0,0,0.1)",
  },


};

export default makeStyles(config);
