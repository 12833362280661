import React from "react";
import { BrowserRouter } from "react-router-dom";
import Routes from "./Routes";
import "./App.css";
import UserContextProvider from "./context/UserContext";
import CartContextProvider from "./context/CartContext";
import { StripeProvider } from 'react-stripe-elements';
function App() {
  return (
    <StripeProvider apiKey="pk_test_51NQOMTF9aa5xCpxT3DKe3zVD91AIqtZKD9G4fcSFxGrFzy28Ih3P5hxsq20ILv9uKIVTVRMniutuW9VesrncyQ8Y000i9a0ZLo">
      <CartContextProvider>
        <UserContextProvider>
          <BrowserRouter>
            <Routes></Routes>
          </BrowserRouter>
        </UserContextProvider>
      </CartContextProvider>
    </StripeProvider>
  );
}

export default App;
