import React from "react";
import Slider from "react-slick";
import useStyles from "./styles";
import NavbarCentered from "../../components/NavbarCentered/NavbarCentered";
import Section from "../../components/Section/Section";
import Footer from "../../components/Footer/Footer";
import FormInputNoLabel from "../../components/FormInputNoLabel/FormInputNoLabel";

import logo from "../../assets/images/logobig.png";

// import prod001 from "../../assets/images/prod001.png";
import icon001 from "../../assets/images/icon001.png";
import icon002 from "../../assets/images/icon002.png";
import icon003 from "../../assets/images/icon003.png";
import icon004 from "../../assets/images/icon004.png";

import cat001 from "../../assets/images/cat001.png";
import cat002 from "../../assets/images/cat002.png";

import bannercontact from "../../assets/images/bannerContact.png";

import paymentmethod from "../../assets/images/payment.png";

import Paragraph from "../../components/Typography/Paragraph";
import Button from "../../components/Button/Button";
import { Container, Grid } from "@mui/material";
import { ArrowForward, LocationOn, Mail, Phone } from "@mui/icons-material";
import { display } from "@mui/system";

export default function About() {
  const classes = useStyles();
  const setting = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <React.Fragment>
      <NavbarCentered bg="#FFF" />
      <div className={classes.theBG_}>
        <Paragraph color="#fff" bold="700" center size="34px">
          WOODPECKER
        </Paragraph>
      </div>

      <Container>
        <Section pd="4rem 0 2rem 0">
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={6}>
              <div className={classes.theLogo}>
                <img src={logo} />
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Paragraph size="30px" margin="0 0 1rem 0" color="#402313">
                ABOUT US
              </Paragraph>
              <Paragraph>
                Welcome to Woodpecker Natural Concept, where comfort, quality,
                and a good night's sleep are our top priorities. At Woodpecker
                Natural Concept, we understand that a restful night's sleep is
                essential for a healthy and fulfilling life. That's why we're
                dedicated to providing you with the finest mattresses designed
                to cradle you in comfort and support your unique sleep needs.
              </Paragraph>
              <br />
              <br />
              <Paragraph size="30px" margin="0 0 1rem 0" color="#402313">
                OUR STORY
              </Paragraph>

              <Paragraph>
                Our journey began in 2010 with a simple yet powerful vision: to
                revolutionize the way people sleep. Frustrated by the lack of
                affordable, high-quality mattresses on the market, our founder
                [Founder's Name] set out on a mission to change the way people
                experience sleep. With a commitment to innovation, meticulous
                craftsmanship, and customer-centric values, Woodpecker was born.
                From our humble beginnings in Malaysia, we've grown into a
                nationally recognized brand known for our dedication to comfort
                and quality.
              </Paragraph>
              <br />
              <br />
              <Paragraph size="30px" margin="0 0 1rem 0" color="#402313">
                QUALITY CRAFTSMANSHIP
              </Paragraph>
              <Paragraph>
                At Woodpecker, we believe that a great night's sleep starts with
                the perfect mattress. That's why we handcraft each mattress
                using only the finest materials and the latest technology. Our
                team of skilled artisans takes pride in creating mattresses that
                are not only exceptionally comfortable but also built to last.
              </Paragraph>
              <br />
              <br />
              <Paragraph size="30px" margin="0 0 1rem 0" color="#402313">
                TAILORED TO YOUR NEEDS
              </Paragraph>
              <Paragraph>
                We understand that everyone's sleep preferences are unique.
                That's why we offer a wide range of mattresses to suit various
                sleep styles, whether you're a side sleeper, back sleeper, or
                prefer a plush or firm mattress. Our commitment to customization
                ensures that you'll find the perfect mattress to match your
                comfort needs.
              </Paragraph>
              <br />
              <br />
              <Paragraph size="30px" margin="0 0 1rem 0" color="#402313">
                SUSTAINABILITY
              </Paragraph>
              <Paragraph>
                We care about the environment as much as we care about your
                sleep. That's why we're committed to eco-friendly practices. Our
                mattresses are designed with sustainability in mind, using
                responsibly sourced materials and minimizing waste in our
                production process.
              </Paragraph>
            </Grid>
          </Grid>
        </Section>
      </Container>
      <div style={{ background: "#F1E2C3" }}>
        <Section pd="4rem 0">
          <Container>
            <Paragraph size="30px" center margin="0 0 3rem 0">
              Why Choose Us ?
            </Paragraph>
            <div>
              <Grid container spacing={5}>
                <Grid item xs={12} sm={6} md={3}>
                  <div className={classes.theBox_}>
                    <div className={classes.theIcon}>
                      <img src={icon001} />
                    </div>
                    <Paragraph color="#B07418" bold="600" margin="0 0 1rem 0">
                      30 Day Guarantee
                    </Paragraph>
                    <Paragraph size="14px">
                      Enjoy peace of mind with our 30-day satisfaction
                      guarantee!
                    </Paragraph>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <div className={classes.theBox_}>
                    <div className={classes.theIcon}>
                      <img src={icon002} />
                    </div>
                    <Paragraph color="#B07418" bold="600" margin="0 0 1rem 0">
                      Premium Quality
                    </Paragraph>
                    <Paragraph size="14px">
                      Crafted with unwavering excellence: Our steadfast
                      commitment to quality.
                    </Paragraph>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <div className={classes.theBox_}>
                    <div className={classes.theIcon}>
                      <img src={icon003} />
                    </div>
                    <Paragraph color="#B07418" bold="600" margin="0 0 1rem 0">
                      Fast Delivery
                    </Paragraph>
                    <Paragraph size="14px">
                      Your Order, Our Priority. Get lightning-fast delivery to
                      your doorstep!
                    </Paragraph>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <div className={classes.theBox_}>
                    <div className={classes.theIcon}>
                      <img src={icon004} />
                    </div>
                    <Paragraph color="#B07418" bold="600" margin="0 0 1rem 0">
                      Buy Now, Pay Later
                    </Paragraph>
                    <Paragraph size="14px">
                      Flexible payment options. Shop now, pay later up to X
                      installments. No catch, just guilt-free spending.
                    </Paragraph>
                  </div>
                </Grid>
              </Grid>
            </div>
          </Container>
        </Section>
      </div>
      <Footer />
    </React.Fragment>
  );
}
