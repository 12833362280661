import React, {
  useState,
  useEffect
} from "react";
import {
  Container,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Grid,
  styled,
} from "@mui/material";
import NavbarDashboard from "../../components/NavbarDashboard/NavbarDashboard";
import Paragraph from "../../components/Typography/Paragraph";
import useStyles from "./styles";
import Button from "../../components/Button/Button";
import CopyIcon from '../../assets/images/copy.svg'
import FacebookIcon from '../../assets/images/facebook.svg'
import WhatsAppIcon from '../../assets/images/whatsapp.svg'
import TwitterIcon from '../../assets/images/twitter.svg'
import ReferralImg from '../../assets/images/referral.svg'
import { WhatsApp } from "@mui/icons-material";
import FormInput from "../../components/FormInput/FormInput";
import Loader from "react-js-loader";
import toast from 'react-simple-toasts';
import {
  change_password,
} from "../../API/API";

export default function DashboardSetting() {
  const classes = useStyles();
  const [userData, setUserData] = useState({
    old_password: "",
    password: "",
    confirm_password: ""
  })

  const handleChange = (event) => {
    setUserData({
      ...userData,
      [event.target.name]: event.target.value,
    });
  }

  const handleSaveProfile = () => {
    if (userData.old_password.length == 0) {
      toast('Please enter old password');
      return
    }
    if (userData.password.length == 0) {
      toast('Please enter new password');
      return
    }
    if (userData.confirm_password.length == 0) {
      toast('Please enter new confirm password');
      return
    }
    change_password(userData).then((resMessage) => {
      if (resMessage.status) {
        toast('Password update successful');
        setUserData({
          old_password: "",
          password: "",
          confirm_password: ""
        });
      } else {
        toast(resMessage.message);
      }
    });
  }
  return (
    <React.Fragment>
      <NavbarDashboard
        children={
          <div>
            <div className={classes.theBGDashboard}>
              <div className={classes.theForm}>
                <Paragraph
                  size='18px'
                  color="#000"
                  bold="bold"
                  margin="0 0 20px"
                >
                  Personal Information
                </Paragraph>
                <hr />
                <div className={classes.theField}>
                  <FormInput
                    label="Old Password"
                    placeholder="*****"
                    border="1px solid #000"
                    br="8px"
                    type="password"
                    name="old_password"
                    value={userData.old_password}
                    onChange={handleChange}
                  />
                </div>
                <div className={classes.theField}>
                  <FormInput
                    label="New Password"
                    placeholder="*****"
                    border="1px solid #000"
                    br="8px"
                    type="password"
                    name="password"
                    value={userData.password}
                    onChange={handleChange}
                  />
                </div>
                <div className={classes.theField}>
                  <FormInput
                    label="Re-enter New Password"
                    placeholder="*****"
                    border="1px solid #000"
                    br="8px"
                    type="password"
                    name="confirm_password"
                    value={userData.confirm_password}
                    onChange={handleChange}
                    mb="30px"
                  />
                </div>
                <hr />
                <div className={classes.theFButton}>
                  <Button label="Save Change"
                    onClick={() => handleSaveProfile()}
                    color="#fff"
                    bg="#D5AE35"
                    pd="10px 25px"
                    br="6px"
                  />
                </div>
              </div>
            </div>
          </div>
        }
      />
    </React.Fragment>
  );
}
