import styled from "styled-components/macro";
import { makeStyles } from "@mui/styles";
import { md } from "../../styles/responsive";
import bannercontact from "../../assets/images/bannerContact.png";

export const CarouselContainer = styled.div`
  position: relative;
  width: 100%;
`;

export const CarouselIMG = styled.img`
  width: 100%;
`;

const config = {
  root: {
    position: "relative",
    "& .slick-track": {
      display: "flex",
      flexDirection: "row",
      alignItems: "inherit",
    },
    "& .slick-slide": {
      height: "inherit",
      "& > div": {
        height: "100%",
      },
      "& > div > div": {
        height: "100%",
      },
    },
    "& .slick-arrow": {
      color: "#000",
      display: "flex!important",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      zIndex: 2,

      "&:before": {
        content: "",
        // content: "\f053",
        display: "block",
        width: 24,
        height: 24,
        // backgroundPosition: "center center",
        // backgroundRepeat: "no-repeat",
        // backgroundSize: "contain",
      },

      "&.slick-next": {
        right: 13,

        ...md("sm", {
          right: 100,
        }),

        ...md("md", {
          right: 100,
        }),

        "&:before": {
          content: "",
          // content: "\f054",
          fontFamily: "Font Awesome 5 Free",
          fontWeight: 900,
          color: "#fff",
          fontSize: "2rem",
        },
      },

      "&.slick-prev": {
        left: 8,

        ...md("sm", {
          left: 100,
        }),

        ...md("md", {
          left: 100,
        }),

        "&:before": {
          content: "",
          //   content: "\f053",
          fontFamily: "Font Awesome 5 Free",
          fontWeight: 900,
          color: "#fff",
          fontSize: "2rem",
        },
      },
      "&.slick-disabled": {
        opacity: 1,
      },
    },
    "& .slick-dots": {
      position: "absolute",
      //   bottom: "-25px",
      bottom: "10px",
      ...md("sm", {
        bottom: "30px",
      }),
      "& li": {
        margin: "0 5px",
        width: "auto",
        height: "auto",

        "& button": {
          width: 10,
          height: 10,
          borderRadius: "50px",
          backgroundColor: "#adadad",
          //   border: "1px solid #c5c5c5",
          padding: 0,
          transition: "all 0.4s",

          "@media screen and (min-width: 768px)": {
            height: 10,
          },

          "&:before": {
            display: "none",
          },
        },

        "&.slick-active": {
          "& button": {
            width: 10,
            backgroundColor: "#fff",
          },
        },
      },
    },
  },

  theBanner: {
    position: "relative",
    width: "100%",

    "& img": {
      width: "100%",
      height: "180px",
      objectFit: "cover",

      ...md("sm", {
        height: "300px",
        // objectFit: "contain"
      }),

      ...md("md", {
        height: "300px",
        // objectFit: "contain"
      }),

      ...md("xmd", {
        height: "500px",
        // objectFit: "contain"
      }),
    },
  },

  theFont: {
    fontSize: "24px",
    color: "#fff",
    ...md("sm", {
      fontSize: "34px",
    }),
    ...md("md", {
      fontSize: "34px",
    }),
  },

  theBox: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
  },

  theBox_: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",

    ...md("md", {
      alignItems: "flex-start",
    }),
  },

  theBox__: {
    position: "absolute",
    width: "80%",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
    // marginTop: "2rem",

    // ...md("md", {
    //   marginTop: "2rem",
    // }),
  },

  thePord: {
    position: "relative",
    width: "100%",

    "& img": {
      width: "100%",
    },
  },

  theIcon: {
    position: "relative",
    width: "20%",

    "& img": {
      width: "100%",
    },
  },

  theCat: {
    position: "relative",
    width: "100%",

    "& img": {
      width: "100%",
    },
  },

  theContactBG: {
    position: "relative",
    width: "100%",
    height: "100vh",
    backgroundImage: "url(" + bannercontact + ")",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",

    "& img": {
      width: "100%",
    },

    ...md("md", {
      height: "580px",
    }),
  },

  theContact: {
    position: "relative",
    // width: "100%",
    background: "rgba(255,255,255, .8)",
    padding: "2rem 2rem 4rem 2rem",
  },

  theHide: {
    display: "block",
    paddingTop: "1.5rem",

    ...md("md", {
      display: "none",
    }),
  },

  thePayment: {
    position: "relative",
    width: "80%",
    margin: "0 auto",

    ...md("md", {
      width: "50%",
    }),

    "& img": {
      width: "100%",
    },
  },

  theIMG: {
    position: "relative",
    width: "100%",
    "& img": {
      width: "100%",
      display: "flex",
    },
  },

  theOverlay: {
    position: "absolute",
    width: "100%",
    height: "100%",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
    // background: "rgba(0,0,0,.1)",
    background: "transparent",
    backgroundImage:
      "linear-gradient(180deg, #ffffff00 0%, rgba(30,30,30,.6) 100%)",
    // opacity: 0.6,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
  },

  theIMGAward: {
    position: "relative",
    textAlign: "center",
    "& img": {
      width: "140px",
    },
  },

  theAvatar: {
    position: "relative",
    "& img": {
      width: "70px",
      height: "70px",
      objectFit: "cover",
      borderRadius: "50px",
    },
  },
};

export default makeStyles(config);
