// import styled from "styled-components/macro";
import { makeStyles } from "@mui/styles";
import { md } from "../../styles/responsive";

const config = {
  theProdIMG: {
    position: "relative",
    width: "20%",
    marginRight: "1rem",

    "& img": {
      width: "100%",
    },
  },

  theFlex: {
    display: "flex",
    alignItems: "center",
    padding: "1rem 0",
    borderBottom: "1px solid #d7d7d7",
  },

  theFlex_: {
    display: "block",
    marginBottom: "1rem",

    ...md("sm", {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginBottom: "0",
      // paddingBottom: "1rem",
      // borderBottom: "1px solid #d7d7d7",
    }),
  },

  theWidth: {
    width: "100%",

    ...md("sm", {
      width: "80%",
    }),

    ...md("md", {
      width: "60%",
    }),
  },

  theListAddress: {
    border: "1px solid #707070",
    borderRadius: "6px",
    padding: "1rem",
    marginBottom: "1rem"
  },
};

export default makeStyles(config);
