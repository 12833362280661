import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import useStyles from "./styles";
import NavbarCentered from "../../components/NavbarCentered/NavbarCentered";
import Section from "../../components/Section/Section";
import Footer from "../../components/Footer/Footer";
import FormInputNoLabel from "../../components/FormInputNoLabel/FormInputNoLabel";

import logo from "../../assets/images/logobig.png";

import prod001 from "../../assets/images/prod001.png";

import Paragraph from "../../components/Typography/Paragraph";
import Button from "../../components/Button/Button";
import {
  Container,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import useCart from "../../hooks/useCart";
import { useNavigate } from "react-router-dom";

export default function About() {
  const classes = useStyles();
  const navigate = useNavigate();

  const { addProduct, getCart, removeProduct } = useCart();

  const getcart = JSON.stringify(getCart());

  const [cart, setCart] = React.useState(getCart);

  const productCartList = getcart.replace(/\\/g, "");

  React.useEffect(() => {
    setCart(getCart());
  }, [getCart]);

  const handleProceed = () => {
    if (cart.length == 0) {
      alert("Please add an item to cart");
    } else {
      navigate("/checkout");
    }
  };

  return (
    <React.Fragment>
      <NavbarCentered bg="#FFF" />

      <Container>
        <Section pd="4rem 0 2rem 0">
          <Paragraph size="34px" color="#402313" center margin="0 0 2rem 0">
            My Cart
          </Paragraph>
          <TableContainer>
            <Table>
              <TableHead className={classes.theHead}>
                <TableRow>
                  <TableCell>Product</TableCell>
                  <TableCell sx={{ textAlign: "center" }}>Price</TableCell>
                  <TableCell sx={{ textAlign: "center" }}>Quatity</TableCell>
                  <TableCell sx={{ textAlign: "center" }}>Total</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {cart.length > 0 &&
                  cart.map((res) => (
                    <TableRow>
                      <TableCell>
                        <div
                          style={{ display: "flex" }}
                          className={classes.theWidth}
                        >
                          <div className={classes.theIMG}>
                            <img src={res.image} />
                          </div>
                          <div style={{ marginLeft: "1rem" }}>
                            <Paragraph
                              margin="0 0 1rem 0"
                              size="18px"
                              className="theEllipsis"
                            >
                              {" "}
                              {res.name}
                            </Paragraph>
                            <Button
                              onClick={() => removeProduct(res)}
                              label="REMOVE"
                              color="#402313"
                              box="1px solid #402313"
                              br="4px"
                              pd=".2rem .1rem"
                              size="12px"
                              style={{ width: "50%" }}
                            />
                          </div>
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className={classes.theWidth2}>
                          <Paragraph center>
                            RM {res.price.toFixed(2)}
                          </Paragraph>
                        </div>
                      </TableCell>
                      <TableCell>
                        <div>
                          <Paragraph center>{res.quantity}</Paragraph>
                        </div>
                      </TableCell>
                      <TableCell>
                        {" "}
                        <div className={classes.theWidth2}>
                          {" "}
                          <Paragraph center>
                            RM {(res.price * res.quantity).toFixed(2)}
                          </Paragraph>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <br />
          <div className={classes.theFlexx}>
            <div className={classes.theRight}>
              <Button
                href="/"
                label="Continue Shopping"
                br="4px"
                color="#D5AE35"
                box="1px solid #D5AE35"
              />
            </div>
            <div>
              <Button
                onClick={() => handleProceed()}
                label="Proceed to Checkout"
                br="4px"
                color="#fff"
                bg="#D5AE35"
              />
            </div>
          </div>
        </Section>
      </Container>

      <Footer />
    </React.Fragment>
  );
}
