import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import useStyles from "./styles";
import Section from "../../components/Section/Section";
import Footer from "../../components/Footer/Footer";
import FormInputNoLabel from "../../components/FormInputNoLabel/FormInputNoLabel";

import banner from "../../assets/images/banner.png";
import prod000 from "../../assets/images/prod000.png";
import icon001 from "../../assets/images/icon001.png";
import icon002 from "../../assets/images/icon002.png";
import icon003 from "../../assets/images/icon003.png";
import icon004 from "../../assets/images/icon004.png";

import cat001 from "../../assets/images/cat001.png";
import cat002 from "../../assets/images/cat002.png";
import img001 from "../../assets/images/img001.jpg";
import img002 from "../../assets/images/img002.jpg";
import img003 from "../../assets/images/img003.jpg";
import img004 from "../../assets/images/img004.jpg";
import img005 from "../../assets/images/img005.jpg";
import img006 from "../../assets/images/img006.jpg";
import img007 from "../../assets/images/img007.jpg";
import img008 from "../../assets/images/img008.jpg";
import img009 from "../../assets/images/img009.jpg";
import img010 from "../../assets/images/img010.jpg";
import img011 from "../../assets/images/img011.jpg";
import img012 from "../../assets/images/img012.jpg";
import img013 from "../../assets/images/img013.jpg";
import img014 from "../../assets/images/img014.jpg";
import img015 from "../../assets/images/img015.jpg";
import img016 from "../../assets/images/img016.jpg";

import award001 from "../../assets/images/award001.png";
import award002 from "../../assets/images/award002.png";
import award003 from "../../assets/images/award003.png";
import award004 from "../../assets/images/award004.png";

import bannercontact from "../../assets/images/bannerContact.png";

import paymentmethod from "../../assets/images/payment.png";

import Paragraph from "../../components/Typography/Paragraph";
import Button from "../../components/Button/Button";
import { Box, Container, Grid, Rating } from "@mui/material";
import { ArrowForward, LocationOn, Mail, Phone } from "@mui/icons-material";
import Loader from "react-js-loader";
import toast from "react-simple-toasts";

import {
  get_banner,
  get_category,
  contact_us,
  get_random_product,
} from "../../API/API";
import NavbarCentered from "../../components/NavbarCentered/NavbarCentered";
import Navbar from "../../components/Navbar/Navbar";
import FormInput from "../../components/FormInput/FormInput";
import { Link } from "react-router-dom";

export default function Home() {
  const classes = useStyles();
  const setting = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
  };

  const [bannerData, setBannerData] = useState(null);
  const [categoryData, setCategoryData] = useState(null);
  const [contactData, setContactData] = useState({
    name: "",
    email: "",
    contact: "",
    message: "",
  });

  const [products, setProducts] = useState([]);
  const handleChange = (event) => {
    setContactData({
      ...contactData,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmitContact = () => {
    if (contactData.name.length == 0) {
      toast("Please enter name");
      return;
    }
    if (contactData.email.length == 0) {
      toast("Please enter email");
      return;
    }
    if (contactData.contact.length == 0) {
      toast("Please enter contact number");
      return;
    }
    if (contactData.message.length == 0) {
      toast("Please enter message");
      return;
    }
    contact_us(contactData).then((resMessage) => {
      if (resMessage.status) {
        toast("We receive your message!");
        setContactData({
          name: "",
          email: "",
          contact: "",
          message: "",
        });
      } else {
        toast(resMessage.message);
      }
    });
  };

  const getBanner = () => {
    get_banner().then((json) => {
      if (json.status) {
        setBannerData(json.data);
      }
    });
  };

  const getCat = () => {
    get_category().then((json) => {
      var temp_data = [];
      if (json.status) {
        for (var i = 0; i < 2; i++) {
          console.log(json.data[i]);
          temp_data.push(json.data[i]);
        }
        setCategoryData(temp_data);
      }
    });
  };

  const get_products = async () => {
    var res = await get_random_product();
    if (res.status) {
      setProducts(res.data);
    }
  };
  useEffect(() => {
    getBanner();
    getCat();
    get_products();
  }, []);
  return (
    <React.Fragment>
      <NavbarCentered bg="#FFF" />
      {/* <Navbar/> */}
      <Slider className={classes.root} {...setting}>
        {bannerData !== null &&
          bannerData.map((res) => (
            <div className={classes.theBanner}>
              <img src={res.image} />
              <div className={classes.theBox}>
                
                <Paragraph
                  bold="700"
                  margin="0 0 0 0"
                  center
                  className={classes.theFont}
                >
                  Experience the Ultimate Comfort
                </Paragraph>
                {/* <Box
                  sx={{ width: { xs: "100%", sm: "50%" }, margin: "0 auto" }}
                >
                  <Button
                    href="/sale"
                    label="SHOP NOW"
                    br="50px"
                    color="#fff"
                    bg="#D5AE35"
                    bold="500"
                  />
                </Box> */}
              </div>
            </div>
          ))}
      </Slider>
      <Box>
        <Container>
          <Section pd="0">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={4} md={4}>
                <Box>
                  <div className={classes.theIMG}>
                    <img src={img001} />
                    <div className={classes.theOverlay}>
                      <Paragraph color="#fff" size="24px" center>
                        Shop Mattresses
                      </Paragraph>
                      <Paragraph color="#fff" margin="1rem" center>
                        Wake Up Refreshed and Rejuvenated Every Morning!
                      </Paragraph>
                      <Box
                        sx={{
                          padding: "0 0 2rem 0",
                          width: "80%",
                          margin: "0 auto",
                        }}
                      >
                        <Button
                          label="Find out more"
                          bd="1px solid #FFF"
                          color="#FFF"
                          bg="transparent"
                          br="50px"
                          size="14px"
                          href="/mattress"
                        />
                      </Box>
                    </div>
                  </div>
                </Box>
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <Box>
                  <div className={classes.theIMG}>
                    <img src={img002} />
                    <div className={classes.theOverlay}>
                      <Paragraph color="#fff" size="24px" center>
                        Shop Pillows
                      </Paragraph>
                      <Paragraph color="#fff" margin="1rem" center>
                        Say Goodbye to Stiff Necks!
                      </Paragraph>
                      <Box
                        sx={{
                          padding: "0 0 2rem 0",
                          width: "80%",
                          margin: "0 auto",
                        }}
                      >
                        <Button
                          label="Find out more"
                          bd="1px solid #FFF"
                          color="#FFF"
                          bg="transparent"
                          br="50px"
                          size="14px"
                          bold="500"
                          href="/pillow"
                        />
                      </Box>
                    </div>
                  </div>
                </Box>
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <Box>
                  <div className={classes.theIMG}>
                    <img src={img003} />
                    <div className={classes.theOverlay}>
                      <Paragraph color="#fff" size="24px" center>
                        Shop Bedding
                      </Paragraph>
                      <Paragraph color="#fff" margin="1rem" center>
                        Complete Your Dream Bed
                      </Paragraph>
                      <Box
                        sx={{
                          padding: "0 0 2rem 0",
                          width: "80%",
                          margin: "0 auto",
                        }}
                      >
                        <Button
                          label="Find out more"
                          bd="1px solid #FFF"
                          color="#FFF"
                          bg="transparent"
                          br="50px"
                          size="14px"
                          href="/pillow"
                        />
                      </Box>
                    </div>
                  </div>
                </Box>
              </Grid>
            </Grid>
          </Section>
        </Container>
      </Box>
      <Container>
        <Section pd="4rem 0 4rem 0">
          <Grid
            container
            spacing={0}
            sx={{ display: "flex", alignItems: "center", background: "#FFF" }}
          >
            <Grid item xs={12} sm={12} md={7}>
              <div className={classes.theIMG}>
                <img src="./images/bed_series.jpg" />
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={5}>
              <Box sx={{ background: "#FFF", padding: "32px" }}>
                <div
                  style={{
                    padding: "0 0 0 8px",
                    borderLeft: "5px solid #D5AE35",
                  }}
                >
                  <Paragraph color="#D5AE35" size="12px" bold="700">
                    INTRODUCING
                  </Paragraph>
                </div>
                <Paragraph size="45px" bold="700" margin="0 0 1rem 0">
                  Introducing our All-New Collection
                </Paragraph>
                <Paragraph color="#878787">
                  Our latest mattress lineups can cater for different kinds of
                  sleeping preferences.
                </Paragraph>
                <br />
                <Box sx={{ width: { xs: "100%", sm: "40%" } }}>
                  <Button
                    href="/mattress"
                    label="See Detail"
                    br="50px"
                    color="#fff"
                    bg="#D5AE35"
                    bold="500"
                  />
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={0}
            sx={{ display: "flex", alignItems: "center", background: "#FFF" }}
          >
            <Grid item xs={12} sm={12} md={5}>
              <Box sx={{ background: "#FFF", padding: "32px" }}>
                <div
                  style={{
                    padding: "0 0 0 8px",
                    borderLeft: "5px solid #D5AE35",
                  }}
                >
                  <Paragraph color="#D5AE35" size="12px" bold="700">
                    QUALITY
                  </Paragraph>
                </div>
                <Paragraph size="45px" bold="700" margin="0 0 1rem 0">
                  Built with science, made for sleep
                </Paragraph>
                <Paragraph color="#878787">
                  Find the most suitable mattress for your sleeping comfort and
                  spinal health.
                </Paragraph>
                <br />
                <Box sx={{ width: { xs: "100%", sm: "40%" } }}>
                  <Button
                    // href="/sale"
                    href="/mattress"
                    label="See Detail"
                    br="50px"
                    color="#fff"
                    bg="#D5AE35"
                    bold="500"
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={7}>
              <div className={classes.theIMG}>
                <img src="./images/interior2.JPG" />
              </div>
            </Grid>
          </Grid>
        </Section>
      </Container>

      <Box sx={{ background: "#FFF" }}>
        <Container>
          <Section pd="4rem 0 4rem 0">
            <Box>
              <div
                style={{
                  padding: "0 0 0 8px",
                  borderLeft: "5px solid #D5AE35",
                }}
              >
                <Paragraph color="#D5AE35" size="12px" bold="700">
                  PRODUCTS
                </Paragraph>
              </div>
              <Paragraph size="45px" bold="700" margin="0 0 1rem 0">
                Meet Our Line-Up of Luxury Products
              </Paragraph>
              <Paragraph color="#878787">
                All our products are made for you to sleep like a king. Find
                everything you need for your night sleep in our product range.
              </Paragraph>
              <br />
            </Box>
            <Grid container spacing={3}>
              {products.map((r) => (
                <Grid item xs={12} sm={6} md={3}>
                  <a href={"/product/" + r.product_id}>
                    <Box
                      sx={{
                        padding: "16px",
                        border: "1px solid #e2e2e2",
                        minHeight: 470,
                        position: "relative",
                      }}
                    >
                      <div className={classes.theIMG}>
                        <img
                          src={r.image}
                          style={{
                            height: 250,
                            objectFit: "cover",
                          }}
                        />
                      </div>
                      <br />
                      <Paragraph bold="700" size="18px" margin="0 0 .4rem 0">
                        {r.name}
                      </Paragraph>
                      <Rating value="5" sx={{ fontSize: "18px" }} />
                      <Box sx={{ display: "flex" }}>
                        <Paragraph
                          color="#d7d7d7"
                          margin="0 10px 0 0"
                          // style={{ textDecoration: "line-through" }}
                        >
                          RM {r.price}
                        </Paragraph>
                        {/* <Paragraph bold="600">RM 399.00</Paragraph> */}
                      </Box>
                      <br />
                      <Box
                        sx={{
                          width: {
                            xs: "100%",
                            sm: "45%",
                            md: "65%",
                            lg: "50%",
                          },
                        }}
                      >
                        <Button
                          style={{
                            position: "absolute",
                            bottom: 20,
                            left: 20,
                          }}
                          label="View More"
                          br="50px"
                          color="#fff"
                          bg="#D5AE35"
                          bold="500"
                          // pd=".6rem 1rem"
                        />
                      </Box>
                    </Box>
                  </a>
                </Grid>
              ))}

              {/* <Grid item xs={12} sm={6} md={3}>
                <Box sx={{ padding: "16px", border: "1px solid #e2e2e2" }}>
                  <div className={classes.theIMG}>
                    <img src={img007} />
                  </div>
                  <br />
                  <Paragraph bold="700" size="18px" margin="0 0 .4rem 0">
                    The Allswell Supreme
                  </Paragraph>
                  <Rating value="5" sx={{ fontSize: "18px" }} />
                  <Box sx={{ display: "flex" }}>
                    <Paragraph
                      color="#d7d7d7"
                      margin="0 10px 0 0"
                      style={{ textDecoration: "line-through" }}
                    >
                      RM 500.00
                    </Paragraph>
                    <Paragraph bold="600">RM 399.00</Paragraph>
                  </Box>
                  <br />
                  <Box
                    sx={{
                      width: { xs: "100%", sm: "45%", md: "65%", lg: "50%" },
                    }}
                  >
                    <Button
                      label="Add to cart"
                      br="50px"
                      color="#fff"
                      bg="#D5AE35"
                      bold="500"
                    // pd=".6rem 1rem"
                    />
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Box sx={{ padding: "16px", border: "1px solid #e2e2e2" }}>
                  <div className={classes.theIMG}>
                    <img src={img008} />
                  </div>
                  <br />
                  <Paragraph bold="700" size="18px" margin="0 0 .4rem 0">
                    The Allswell Supreme
                  </Paragraph>
                  <Rating value="5" sx={{ fontSize: "18px" }} />
                  <Box sx={{ display: "flex" }}>
                    <Paragraph
                      color="#d7d7d7"
                      margin="0 10px 0 0"
                      style={{ textDecoration: "line-through" }}
                    >
                      RM 500.00
                    </Paragraph>
                    <Paragraph bold="600">RM 399.00</Paragraph>
                  </Box>
                  <br />
                  <Box
                    sx={{
                      width: { xs: "100%", sm: "45%", md: "65%", lg: "50%" },
                    }}
                  >
                    <Button
                      label="Add to cart"
                      br="50px"
                      color="#fff"
                      bg="#D5AE35"
                      bold="500"
                    // pd=".6rem 1rem"
                    />
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Box sx={{ padding: "16px", border: "1px solid #e2e2e2" }}>
                  <div className={classes.theIMG}>
                    <img src={img009} />
                  </div>
                  <br />
                  <Paragraph bold="700" size="18px" margin="0 0 .4rem 0">
                    The Allswell Supreme
                  </Paragraph>
                  <Rating value="5" sx={{ fontSize: "18px" }} />
                  <Box sx={{ display: "flex" }}>
                    <Paragraph
                      color="#d7d7d7"
                      margin="0 10px 0 0"
                      style={{ textDecoration: "line-through" }}
                    >
                      RM 500.00
                    </Paragraph>
                    <Paragraph bold="600">RM 399.00</Paragraph>
                  </Box>
                  <br />
                  <Box
                    sx={{
                      width: { xs: "100%", sm: "45%", md: "65%", lg: "50%" },
                    }}
                  >
                    <Button
                      label="Add to cart"
                      br="50px"
                      color="#fff"
                      bg="#D5AE35"
                      bold="500"
                    // pd=".6rem 1rem"
                    />
                  </Box>
                </Box>
              </Grid> */}
            </Grid>
          </Section>
        </Container>
      </Box>

      <Container>
        <Section pd="4rem 0 4rem 0">
          <Grid
            container
            spacing={0}
            sx={{ display: "flex", alignItems: "center", background: "#FFF" }}
          >
            <Grid item xs={12} sm={12} md={7}>
              <div className={classes.theIMG}>
                <img src={img010} />
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={5}>
              <Box sx={{ background: "#FFF", padding: "32px" }}>
                <div
                  style={{
                    padding: "0 0 0 8px",
                    borderLeft: "5px solid #D5AE35",
                  }}
                >
                  <Paragraph color="#D5AE35" size="12px" bold="700">
                    BEST OFFERS
                  </Paragraph>
                </div>
                <Paragraph size="45px" bold="700" margin="0 0 1rem 0">
                  It's Like Sleeping On A Cloud
                </Paragraph>
                <Paragraph
                  color="#878787"
                  bold="600"
                  margin="0 0 .4rem 0"
                  size="20px"
                >
                  Prices starting at{" "}
                  <span style={{ color: "#D5AE35" }}>RM 199</span>
                </Paragraph>
                <Paragraph color="#878787">
                  Find the best pillows for your sleep or neck support, or even
                  the cutest pillow for your kids.
                </Paragraph>
                <br />
                <Box sx={{ width: { xs: "100%", sm: "40%" } }}>
                  <Button
                    // href="/sale"
                    href="/pillow"
                    label="Shop Mattress"
                    br="50px"
                    color="#fff"
                    bg="#D5AE35"
                    bold="500"
                  />
                </Box>
              </Box>
            </Grid>
          </Grid>
          {/* <Grid
            container
            spacing={0}
            sx={{ display: "flex", alignItems: "center", background: "#FFF" }}
          >
            <Grid item xs={12} sm={12} md={5}>
              <Box sx={{ background: "#FFF", padding: "32px" }}>
                <div
                  style={{
                    padding: "0 0 0 8px",
                    borderLeft: "5px solid #D5AE35",
                  }}
                >
                  <Paragraph color="#D5AE35" size="12px" bold="700">
                    BEST OFFERS
                  </Paragraph>
                </div>
                <Paragraph size="45px" bold="700" margin="0 0 1rem 0">
                  New Eco Organic Mattress Edition
                </Paragraph>
                <Paragraph
                  color="#878787"
                  bold="600"
                  margin="0 0 .4rem 0"
                  size="20px"
                >
                  Prices starting at{" "}
                  <span style={{ color: "#D5AE35" }}>RM 299</span>
                </Paragraph>
                <Paragraph color="#878787">
                  Integer ornare ipsum eget ridiculus. Quam id eu tempus, elit
                  est dictum ut eu. Dignissim cursus id et integer purus.
                  Pharetra quis dui dolor
                </Paragraph>
                <br />
                <Box sx={{ width: { xs: "100%", sm: "40%" } }}>
                  <Button
                    // href="/sale"
                    label="Shop Mattress"
                    br="50px"
                    color="#fff"
                    bg="#D5AE35"
                    bold="500"
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={7}>
              <div className={classes.theIMG}>
                <img src={img011} />
              </div>
            </Grid>
          </Grid> */}
        </Section>
      </Container>

      <Box sx={{ background: "#fff" }}>
        <Container>
          <Section pd="4rem 0 2rem 0">
            <Grid
              container
              spacing={0}
              sx={{
                display: "flex",
                alignItems: "center",
                background: "#D5AE35",
              }}
            >
              <Grid item xs={12} sm={12} md={7}>
                <div className={classes.theIMG}>
                  <img src={img012} />
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={5}>
                <Box sx={{ background: "#D5AE35", padding: "32px" }}>
                  <div
                    style={{
                      padding: "0 0 0 8px",
                      borderLeft: "5px solid #fff",
                    }}
                  >
                    <Paragraph color="#fff" size="12px" bold="700">
                      QUALITY
                    </Paragraph>
                  </div>
                  <Paragraph
                    color="#fff"
                    size="45px"
                    bold="700"
                    margin="0 0 1rem 0"
                  >
                    One Of The Most Popular Brand
                  </Paragraph>

                  <Paragraph color="#fff">
                    {/* Integer ornare ipsum eget ridiculus. Quam id eu tempus, elit
                    est dictum ut eu. Dignissim cursus id et integer purus.
                    Pharetra quis dui dolor */}
                  </Paragraph>
                </Box>
              </Grid>
            </Grid>
          </Section>
        </Container>

        {/* <Container>
          <Section pd="2rem 0 4rem 0">
            <Box>
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <div
                  style={{
                    padding: "0 0 0 8px",
                    borderLeft: "5px solid #D5AE35",
                  }}
                >
                  <Paragraph color="#D5AE35" size="12px" bold="700">
                    AWARDS
                  </Paragraph>
                </div>
              </Box>
              <Paragraph size="45px" bold="700" margin="0 0 1rem 0" center>
                Award-Winning Layers Of Comfort
              </Paragraph>
              <Paragraph color="#878787" margin="0 0 1rem 0" center>
                Integer ornare ipsum eget ridiculus. Quam id eu tempus, elit est
                dictum ut eu. Dignissim cursus id et integer purus.
              </Paragraph>
              <br />
            </Box>
            <Grid container spacing={3} sx={{ alignItems: "center" }}>
              <Grid item xs={6} sm={3} md={3}>
                <div className={classes.theIMGAward}>
                  <img src={award001} />
                </div>
              </Grid>
              <Grid item xs={6} sm={3} md={3}>
                <div className={classes.theIMGAward}>
                  <img src={award002} />
                </div>
              </Grid>
              <Grid item xs={6} sm={3} md={3}>
                <div className={classes.theIMGAward}>
                  <img src={award003} />
                </div>
              </Grid>
              <Grid item xs={6} sm={3} md={3}>
                <div className={classes.theIMGAward}>
                  <img src={award004} />
                </div>
              </Grid>
            </Grid>
          </Section>
        </Container> */}
      </Box>
      <Container>
        <Section pd="4rem 0 4rem 0">
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={6}>
              <Box>
                <div
                  style={{
                    padding: "0 0 0 8px",
                    borderLeft: "5px solid #D5AE35",
                  }}
                >
                  <Paragraph color="#D5AE35" size="12px" bold="700">
                    CONTACT
                  </Paragraph>
                </div>
                <Paragraph size="45px" bold="700" margin="0 0 1rem 0">
                  Get In Touch With Us For More Information
                </Paragraph>
                <Paragraph color="#878787">
                  Can't select a bed for your needs? Contact us or schedule an
                  appointment in our showroom!
                </Paragraph>
                <br />
                <br />
                <br />
                <Paragraph color="#D5AE35" bold="700" margin="0 0 1rem 0">
                  Contact Us
                </Paragraph>
                <Paragraph margin="0 0 .4rem 0" bold="500">
                  12, Jalan Seri Austin 3, Taman Seri Austin 81000, Johor Bahru,
                  Johor.
                </Paragraph>
                <Paragraph margin="0 0 .4rem 0" bold="500">
                  woodpeckerjb@email.com
                </Paragraph>
                <Paragraph margin="0 0 .4rem 0" bold="500">
                  +6 012 3556789
                </Paragraph>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Box sx={{ padding: "2rem", background: "#FFF" }}>
                <Paragraph size="24px" bold="700" margin="0 0 1rem 0">
                  Send Us A Message
                </Paragraph>
                <Paragraph color="#878787">
                  Our team will contact you as soon as possible.
                </Paragraph>
                <FormInput
                  textLabelBold="500"
                  bg="#FFF"
                  placeholder="Name"
                  border="1px solid #D5AE35"
                  name="name"
                />
                <FormInput
                  bg="#FFF"
                  placeholder="Contact Number"
                  border="1px solid #D5AE35"
                  name="contact"
                />
                <FormInput
                  bg="#FFF"
                  placeholder="Email"
                  border="1px solid #D5AE35"
                  name="email"
                />

                <FormInput
                  bg="#FFF"
                  placeholder="Message"
                  border="1px solid #D5AE35"
                  name="message"
                  multiline
                  rows="4"
                />
                <Box
                  sx={{ width: { xs: "100%", sm: "40%" }, margin: "0 auto" }}
                >
                  <Button
                    // href="/sale"
                    label="SEND MESSAGE"
                    br="50px"
                    color="#fff"
                    bg="#D5AE35"
                    bold="500"
                    pd=".575rem 1rem"
                    bd="1px solid #FFF"
                    onClick={() => handleSubmitContact()}
                  />
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Section>
      </Container>

      <Box sx={{ background: "#FFF" }}>
        <Container>
          <Section pd="4rem 0 4rem 0">
            <Box>
              <div
                style={{
                  padding: "0 0 0 8px",
                  borderLeft: "5px solid #D5AE35",
                }}
              >
                <Paragraph color="#D5AE35" size="12px" bold="700">
                  TESTIMONIALS
                </Paragraph>
              </div>
              <Paragraph size="45px" bold="700" margin="0 0 1rem 0">
                What People Says
              </Paragraph>
              <Paragraph color="#878787">
                {/* Integer ornare ipsum eget ridiculus. Quam id eu tempus, elit est
                dictum ut eu. Dignissim cursus id et integer purus. Pharetra
                quis dui dolor */}
              </Paragraph>
              <br />
            </Box>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} md={4}>
                <Box sx={{ padding: "32px", background: "#F7F7F7" }}>
                  <Rating value="5" sx={{ fontSize: "18px" }} />
                  <Paragraph bold="700" size="20px" margin="0 0 1rem 0">
                    "Amazing Customer Service"
                  </Paragraph>
                  <Paragraph color="#878787" size="14px" margin="0 0 1rem 0">
                    Woodpecker gives the most professional advice to what kind
                    of beds or pillows I need for my sleep.
                  </Paragraph>
                  {/* <Box sx={{ display: "flex" }}>
                    <div className={classes.theAvatar}>
                      <img src={img001} />
                    </div>
                    <Box sx={{ margin: "0 0 0 14px" }}>
                      <Paragraph bold="700" margin=".4rem 0 0 0">
                        David
                      </Paragraph>
                      <Paragraph size="12px" color="#D5AE35">
                        Content Creator
                      </Paragraph>
                    </Box>
                  </Box> */}
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Box sx={{ padding: "32px", background: "#F7F7F7" }}>
                  <Rating value="5" sx={{ fontSize: "18px" }} />
                  <Paragraph bold="700" size="20px" margin="0 0 1rem 0">
                    "First time I learnt so much about sleeping."
                  </Paragraph>
                  <Paragraph color="#878787" size="14px" margin="0 0 1rem 0">
                    The owner is very patient in showing me what kind of
                    mattress and sleeping positions for me.
                  </Paragraph>
                  {/* <Box sx={{ display: "flex" }}>
                    <div className={classes.theAvatar}>
                      <img src={img001} />
                    </div>
                    <Box sx={{ margin: "0 0 0 14px" }}>
                      <Paragraph bold="700" margin=".4rem 0 0 0">
                        Samantha William
                      </Paragraph>
                      <Paragraph size="12px" color="#D5AE35">
                        Content Creator
                      </Paragraph>
                    </Box>
                  </Box> */}
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Box sx={{ padding: "32px", background: "#F7F7F7" }}>
                  <Rating value="5" sx={{ fontSize: "18px" }} />
                  <Paragraph bold="700" size="20px" margin="0 0 1rem 0">
                    "Great Products!"
                  </Paragraph>
                  <Paragraph color="#878787" size="14px" margin="0 0 1rem 0">
                    Purchased 2 pillows, my kids love it, would purchase again
                    next time.
                  </Paragraph>
                  {/* <Box sx={{ display: "flex" }}>
                    <div className={classes.theAvatar}>
                      <img src={img001} />
                    </div>
                    <Box sx={{ margin: "0 0 0 14px" }}>
                      <Paragraph bold="700" margin=".4rem 0 0 0">
                        Samantha William
                      </Paragraph>
                      <Paragraph size="12px" color="#D5AE35">
                        Content Creator
                      </Paragraph>
                    </Box>
                  </Box> */}
                </Box>
              </Grid>
            </Grid>
          </Section>
        </Container>
      </Box>

      <Box
        sx={{
          position: "relative",
          // backgroundColor: "rgba(213,174,53,.5)",
          backgroundColor: "rgba(64,35,19,.6)",
          backgroundImage: "url(" + img013 + ")",
          backgroundBlendMode: "overlay",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
          width: "100%",
          height: "400px",
          objectFit: "cover",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            width: "100%",
            top: "50%",
            left: "50%",
            transform: "translate(-50%,-50%)",
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <div
              style={{
                padding: "0 0 0 8px",
                borderLeft: "5px solid #FFF",
              }}
            >
              <Paragraph color="#FFF" size="12px" bold="700">
                COOL SUMMER SAVING
              </Paragraph>
            </div>
          </Box>
          <Paragraph
            color="#FFF"
            size="45px"
            bold="700"
            margin="0 0 1rem 0"
            center
          >
            Don't Miss Out On These Deals.
          </Paragraph>
          <Paragraph color="#FFF" margin="0 0 1rem 0" center>
            Up to RM 500 towwards a mattress*
          </Paragraph>
          <br />
          <Box sx={{ width: { xs: "100%", sm: "15%" }, margin: "0 auto" }}>
            <Button
              href="/sale"
              label="SHOP NOW"
              br="50px"
              color="#fff"
              bg="#D5AE35"
              bold="500"
              pd="1rem"
            />
          </Box>
        </Box>
      </Box>

      <Container>
        <Section pd="4rem 0 4rem 0">
          <Box>
            <div
              style={{
                padding: "0 0 0 8px",
                borderLeft: "5px solid #D5AE35",
              }}
            >
              <Paragraph color="#D5AE35" size="12px" bold="700">
                NEWS
              </Paragraph>
            </div>
            <Paragraph size="45px" bold="700" margin="0 0 1rem 0">
              Latest Blog & News
            </Paragraph>
            <Paragraph color="#878787">
              Get all you need to know for choosing your new bed.
            </Paragraph>
            <br />
          </Box>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={4}>
              <Link to="/articles/ChooseMattress">
                <Box>
                  <div className={classes.theIMG}>
                    {/* <img src={img014} /> */}
                    <img
                      src="/images/article_1.jpg"
                      style={{
                        width: "100%",
                        height: "197px",
                        objectFit: "cover",
                      }}
                    />
                  </div>
                  <Paragraph bold="700" size="18px" margin=".4rem 0">
                    How to choose the right mattress for you ?
                  </Paragraph>
                  <Paragraph
                    color="#D5AE35"
                    size="12px"
                    bold="500"
                    margin="0 0 1rem 0"
                  >
                    August 9, 2022 No Comment{" "}
                  </Paragraph>
                </Box>
              </Link>
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <Link to="/articles/BadMattress">
                <Box>
                  <div className={classes.theIMG}>
                    <img
                      src={"/images/article_2.jpg"}
                      style={{
                        width: "100%",
                        height: "197px",
                        objectFit: "cover",
                      }}
                    />
                  </div>
                  <Paragraph bold="700" size="18px" margin=".4rem 0">
                    Signs and Effects of a Bad Mattress
                  </Paragraph>
                  <Paragraph
                    color="#D5AE35"
                    size="12px"
                    bold="500"
                    margin="0 0 1rem 0"
                  >
                    August 9, 2022 No Comment{" "}
                  </Paragraph>
                </Box>
              </Link>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Link to="/articles/MemoryFoam">
                <Box>
                  <div className={classes.theIMG}>
                    <img
                      src={"/images/article_3.jpg"}
                      style={{
                        width: "100%",
                        height: "197px",
                        objectFit: "cover",
                      }}
                    />
                  </div>
                  <Paragraph bold="700" size="18px" margin=".4rem 0">
                    What is Memory Foam?
                  </Paragraph>
                  <Paragraph
                    color="#D5AE35"
                    size="12px"
                    bold="500"
                    margin="0 0 1rem 0"
                  >
                    August 9, 2022 No Comment{" "}
                  </Paragraph>
                </Box>
              </Link>
            </Grid>
          </Grid>
        </Section>
      </Container>

      <Box sx={{ position: "relative", marginTop: "8rem" }}>
        <Box
          sx={{
            top: { xs: "-150px", sm: "-160px", md: "-140px" },
            zIndex: 2,
            position: "absolute",
            width: "100%",
          }}
        >
          <Container>
            <Box
              sx={{
                background: "#402313",
                padding: { xs: "32px 16px", sm: "32px", md: "64px" },
                // margin: "0 auto",
                // width: { xs: "80%", sm: "80%", md: "60%" },
              }}
            >
              <Grid container spacing={8} sx={{ alignItems: "center" }}>
                <Grid item xs={12} sm={7} md={7}>
                  <Box>
                    <div
                      style={{
                        padding: "0 0 0 8px",
                        borderLeft: "5px solid #FFF",
                      }}
                    >
                      <Paragraph color="#FFF" size="12px" bold="700">
                        QUALITY
                      </Paragraph>
                    </div>
                    <Paragraph
                      color="#FFF"
                      size="45px"
                      bold="700"
                      margin="0 0 1rem 0"
                    >
                      Newsletter Sign Up
                    </Paragraph>
                    <Paragraph color="#FFF">
                      Sign up to get the latest news from us
                    </Paragraph>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={5} md={5}>
                  <Box>
                    <Paragraph color="#fff" bold="600" margin="0 0 1rem 0">
                      Register now for discount offer
                    </Paragraph>
                    <FormInputNoLabel
                      placeholder="Enter your email"
                      bg="#FFF"
                      br="50px"
                      mb=".2rem"
                      textcolor="#000"
                    />
                    <Button
                      label="Subscribe Now"
                      br="50px"
                      color="#fff"
                      bg="#D5AE35"
                      bold="500"
                      pd=".8rem 1rem"
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Container>
        </Box>
      </Box>
      <br />
      <br />
      <Footer />
    </React.Fragment>
  );
}
