import React from "react";
import {
  Container,
  Checkbox,
  Grid,
  Select,
  FormControl,
  MenuItem,
  Modal,
  TextField,
  CircularProgress,
} from "@mui/material";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import NavbarCentered from "../../components/NavbarCentered/NavbarCentered";
import Section from "../../components/Section/Section";
import stripecardasset from "../../assets/images/stripe-badge-transparent.png";
import fpxcardasset from "../../assets/images/fpx_badge.png";
import "./custom.css";

import { StripeProvider } from "react-stripe-elements";
import {
  CardElement,
  FpxBankElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";

import StatusMessages, { useMessages } from "./StatusMessages";

import { Button } from "../../components/Button/Button";
import { stripe_payment, get_order_by_intent } from "../../API/API";

import useStyles from "./styles";

import useCart from "../../hooks/useCart";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

const stripePromise = loadStripe(
  "pk_test_51NQOMTF9aa5xCpxT3DKe3zVD91AIqtZKD9G4fcSFxGrFzy28Ih3P5hxsq20ILv9uKIVTVRMniutuW9VesrncyQ8Y000i9a0ZLo"
);

export default function PaymentPage(props) {
  const classes = useStyles();

  //   let { Payment } = useLocation();
  const [clientSecret, setClientSecret] = React.useState("");
  const [paymentId, setPaymentId] = React.useState(0);
  //   const history = useHistory();
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  const [checkoutType, setCheckoutType] = React.useState("card");
  const { clearCart } = useCart();

  console.log(location.state.secret);

  React.useEffect(() => {
    if (params.type != undefined) {
      setCheckoutType(params.type);
    }
    if (location.state) {
      if (location.state.secret != undefined) {
        setClientSecret(location.state.secret);
        setPaymentId(location.state.payment_id);
      } else {
        alert("FAIL");
      }
    } else {
      alert("FAIL");
    }

    window.scrollTo(0, 0);

    // stripe_payment().then((res) => {
    //   if (res.status) {
    //     setClientSecret(res.data.client_secret);
    //   } else {
    //     alert("FAIL, STOP TRANSACTION");
    //   }
    // });
  }, []);

  const [tabs, setTab] = React.useState(1);

  const handleChangeTab = (text) => {
    setTab(text);
  };

  return (
    <React.Fragment>
      <NavbarCentered bg="#FFF" />
      <Container>
        <Section>
          <div className={classes.theContainer}>
            {/* <div className={classes.theTabList}>
                <h1>CARD TYPE</h1>
                <div className={classes.theFlex}>
                    <Link
                        className={
                            tabs == "1" ? classes.theBoxActive : classes.theBox
                        }
                        onClick={() => handleChangeTab(1)}
                    >
                        Credit Card
                    </Link>
                    <Link
                        className={tabs == 2 ? classes.theBoxActive : classes.theBox}
                        onClick={() => handleChangeTab(2)}
                    >
                        FPX Card
                    </Link>
                </div>
            </div> */}
            <div className={classes.theTable}>
              {checkoutType == "card" && (
                <Elements stripe={stripePromise}>
                  <CardForm clientSecret={clientSecret}></CardForm>
                </Elements>
              )}
              {checkoutType == "fpx" && (
                <Elements stripe={stripePromise}>
                  <FpxForm
                    clientSecret={clientSecret}
                    payment_id={paymentId}
                  ></FpxForm>
                </Elements>
              )}
            </div>
          </div>
        </Section>
      </Container>
    </React.Fragment>
  );
}

const CardForm = ({ clientSecret }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [messages, addMessage] = useMessages();
  const classes = useStyles();
  const navigate = useNavigate();
  const params = useParams();
  //   const history = useHistory();
  const { clearCart } = useCart();
  const [isLoading, setIsLoading] = React.useState(false);

  const handleSubmit = () => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);
    stripe
      .confirmCardPayment(clientSecret, {
        payment_method: {
          card: elements.getElement(CardElement),
        },
      })
      .then((payload) => {
        if (payload.error) {
          alert("Payment failed");
          navigate("/checkout");
        } else {
          console.log(payload);
          clearCart();
          //   get_order_by_intent({
          //     id: history.location.state.payment_id,
          //   }).then((res) => {
          //    navigate("/orderdetail/" + res.data.orders_id);
          //   });
        }
        navigate("/dashboard-order");
        setIsLoading(false);
      })
      .catch((e) => {
        setIsLoading(false);
      });
  };
  return (
    <>
      <h3>CARD</h3>
      <CardElement
        options={{
          style: {
            base: {
              padding: "10px 12px",
              color: "#32325d",
              fontFamily:
                '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
              fontSmoothing: "antialiased",
              fontSize: "16px",
              "::placeholder": {
                color: "#aab7c4",
              },
            },
            invalid: {
              color: "#fa755a",
            },
          },
        }}
      />
      <br />
      {!isLoading ? (
        <button className={classes.theButton} onClick={handleSubmit}>
          SUBMIT
        </button>
      ) : (
        <div
          style={{
            textAlign: "center",
          }}
        >
          <CircularProgress></CircularProgress>
        </div>
      )}
      <img
        style={{
          width: "100%",
        }}
        src={stripecardasset}
      ></img>
    </>
  );
};

const FpxForm = ({ clientSecret, payment_id }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [messages, addMessage] = useMessages();
  const classes = useStyles();
  const navigate = useNavigate();
  //   const history = useHistory();
  const [isLoading, setIsLoading] = React.useState(false);
  const { clearCart } = useCart();

  const handleSubmit = async (e) => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    e.preventDefault();

    // get_order_by_intent({
    //   id: payment_id,
    // }).then((res) => {
    //   stripe
    //     .confirmFpxPayment(clientSecret, {
    //       payment_method: {
    //         fpx: elements.getElement(FpxBankElement),
    //       },
    //       return_url:
    //         "https://" +
    //         window.location.host +
    //         "/dashboard-order/"
    //     })
    //     .then((payload) => {
    //       if (payload.error) {
    //         alert("Payment failed");
    //        navigate("/checkout");
    //       } else {
    //         console.log(payload);
    //         clearCart();
    //         // get_order_by_intent({
    //         //   id: history.location.state.payment_id,
    //         // }).then((res) => {
    //         //  navigate("/orderdetail/" + res.data.orders_id);
    //         // });
    //         navigate("/dashboard-order")
    //       }
    //       setIsLoading(false);
    //     })
    //     .catch((e) => {
    //       setIsLoading(false);
    //     });
    // });
    stripe
      .confirmFpxPayment(clientSecret, {
        payment_method: {
          fpx: elements.getElement(FpxBankElement),
        },
        return_url: "http://" + window.location.host + "/dashboard-order/",
      })
      .then((payload) => {
        if (payload.error) {
          alert("Payment failed");
          navigate("/checkout");
        //   console.log(payload);
        } else {
          console.log(payload);
          clearCart();
          // get_order_by_intent({
          //   id: history.location.state.payment_id,
          // }).then((res) => {
          //  navigate("/orderdetail/" + res.data.orders_id);
          // });
          navigate("/dashboard-order");
        }
        setIsLoading(false);
      })
      .catch((e) => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <h3>FPX</h3>

      <form id="payment-form" onSubmit={handleSubmit}>
        <FpxBankElement options={{ accountHolderType: "individual" }} />
        <br />
        {!isLoading ? (
          <button className={classes.theButton} onClick={handleSubmit}>
            SUBMIT
          </button>
        ) : (
          <div
            style={{
              textAlign: "center",
            }}
          >
            <CircularProgress></CircularProgress>
          </div>
        )}
      </form>

      <StatusMessages messages={messages} />
      <div
        style={{
          width: "100%",
          textAlign: "center",
        }}
      >
        <img
          src={fpxcardasset}
          style={{
            marginTop: 10,

            width: "30%",
          }}
        />
      </div>
    </>
  );
};
