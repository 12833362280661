import React, { useEffect, useState } from "react";
import {
  AppBar,
  Box,
  Container,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Link, useNavigate, useLocation } from "react-router-dom";
import SidebarMenu from "../SidebarMenu/SidebarMenu";
import Paragraph from "../Typography/Paragraph";
import Button from "../Button/Button";
import logo from "../../assets/images/logo.png";

import useStyles, {
  MenuIcon,
  NavbarContainer,
  NavbarList,
  NavbarLogo,
  NavbarMobileLogo,
  NavbarIconMobileList,
  NavbarMobieLinkList,
  NavbarLower,
  NavbarLowerList,
  NavbarLink,
  NavbarIconLink,
  NavbarUpper,
  NavbarLogoCentered,
  NavbarUpperList,
  BadgeCount,
} from "./styles";

import {
  AccountCircleOutlined,
  AccountCircleRounded,
  Mail,
  Phone,
  ShoppingBagOutlined,
  ShoppingCartOutlined,
} from "@mui/icons-material";
import useCart from "../../hooks/useCart";
import { get_profile } from "../../API/API";
import useUser from "../../hooks/useUser";

const NavbarCentered = ({ position, bg }) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const theme = useTheme();
  const location = useLocation();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  // CART

  const { getCart, clearCart } = useCart();

  const getcart = JSON.stringify(getCart());

  const [cart, setCart] = React.useState(getCart);

  React.useEffect(() => {
    setCart(getCart());
  }, [getCart]);
  // GET USER

  const { set_user, get_user } = useUser();

  // SIDE MENU & SIDE CART

  const [openSideMenu, setOpenSideMenu] = useState({ left: false });

  const [openSideCart, setOpenSideCart] = useState({ right: false });

  const handleDrawerToggle = (anchor, open) => (e) => {
    if (e.type == "keydown" && (e.key == "Tab" || e.key == "Shift")) {
      return;
    }
    setOpenSideMenu({ [anchor]: open });
    setOpenSideCart({ [anchor]: open });
  };

  //

  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // TOKEN

  const [token, setToken] = useState([]);

  useEffect(() => {
    let token = window.localStorage.getItem("token");
    setToken(token || "");
    console.log("CURRENT TOKEN", token);
  }, []);

  // API

  const [profileData, setProfileData] = useState({});

  const getProfile = () => {
    // get_profile({ token: token }).then((json) => {
    //   if (json.status) {
    //     setProfileData(json.data);
    //   } else {
    //     alert(json.message);
    //     window.localStorage.removeItem("token");
    //     navigate("/login");
    //   }
    // });
  };

  useEffect(() => {
    getProfile();
  }, []);

  // HANDLE LOGOUT

  const handleLogout = () => {
    window.localStorage.removeItem("token");
    window.localStorage.removeItem("cart");
    navigate("/login");
  };

  // NAVBAR ITEM

  const NAVBARLIST = [
    {
      title: "HOME",
      link: "",
    },
    {
      title: "PILLOW",
      link: "pillow",
    },
    {
      title: "MATTRESS",
      link: "mattress",
    },
    {
      title: "SALE",
      link: "sale",
    },
    {
      title: "WHY WOODPECKER",
      link: "about",
    },
    {
      title: "CONTACT US",
      link: "contact",
    },
  ];

  return (
    <>
      <Box
        sx={{
          background: "#D5AE35",
          position: "relative",
          display: "flex",
          justifyContent: "center",
          padding: "8px 0",
        }}
      >
        <Paragraph bold="500" color="#fff">
          20& off everything Use code SUMMERSALES
        </Paragraph>
      </Box>
      <NavbarContainer position={position} bg={bg}>
        <Container maxWidth="xxl">
          <AppBar position="static" sx={{ boxShadow: "unset" }}>
            <Toolbar>
              {isMobile ? (
                <>
                  <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    onClick={handleDrawerToggle("left", true)}
                  >
                    <MenuIcon color="#D5AE35" />
                  </IconButton>
                  <NavbarMobileLogo>
                    <img src={logo} />
                  </NavbarMobileLogo>
                  <NavbarIconMobileList>
                    {token ? (
                      <>
                        <NavbarIconLink
                          href="/cart"
                          style={{ marginRight: "1.5rem" }}
                        >
                          <ShoppingBagOutlined sx={{ color: "#D5AE35" }} />
                          <BadgeCount bg="#D5AE35" color="#fff">
                            {cart.length}
                          </BadgeCount>
                        </NavbarIconLink>

                        <NavbarIconLink
                          // onClick={handleMenu}
                          onClick={() =>
                            get_user() !== null
                              ? navigate("../dashboard")
                              : navigate("../login")
                          }
                        >
                          <AccountCircleOutlined sx={{ color: "#D5AE35" }} />
                        </NavbarIconLink>
                      </>
                    ) : (
                      <>
                        <NavbarIconLink
                          href="/cart"
                          style={{ marginRight: "1.5rem" }}
                        >
                          <ShoppingBagOutlined sx={{ color: "#D5AE35" }} />
                          <BadgeCount bg="#D5AE35" color="#fff">
                            {cart.length}
                          </BadgeCount>
                        </NavbarIconLink>
                        <NavbarIconLink
                          //  onClick={handleMenu}
                          onClick={() =>
                            get_user() !== null
                              ? navigate("../dashboard")
                              : navigate("../login")
                          }
                        >
                          <AccountCircleOutlined sx={{ color: "#D5AE35" }} />
                        </NavbarIconLink>
                      </>
                    )}
                  </NavbarIconMobileList>

                  <SidebarMenu
                    anchor="left"
                    open={openSideMenu.left}
                    onOpen={handleDrawerToggle("left", true)}
                    onClose={handleDrawerToggle("left", false)}
                    handleDrawerToggle={handleDrawerToggle("left", false)}
                  />
                </>
              ) : (
                <>
                  <NavbarList>
                    <Container>
                      <NavbarUpper>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            width: "100%",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              // width: "70%",
                              alignItems: "center",
                            }}
                          >
                            <Box sx={{ display: "flex" }}>
                              <a
                                style={{
                                  display: "flex",
                                  margin: "0 calc(32px/2)",
                                }}
                              >
                                <Phone
                                  sx={{ marginRight: "8px", color: "#D5AE35" }}
                                />
                                <Paragraph bold="500">
                                  (+60)12-783 8355
                                </Paragraph>
                              </a>
                            </Box>
                            <Box sx={{ display: "flex" }}>
                              <a
                                style={{
                                  display: "flex",
                                  margin: "0 calc(32px/2)",
                                }}
                              >
                                <Mail
                                  sx={{ marginRight: "8px", color: "#D5AE35" }}
                                />
                                <Paragraph bold="500">
                                  info@woodpecker.com
                                </Paragraph>
                              </a>
                            </Box>
                          </div>
                        </div>
                        <NavbarLogoCentered>
                          <NavbarLogo>
                            <img src={logo} />
                          </NavbarLogo>
                        </NavbarLogoCentered>
                        <NavbarUpperList>
                          {token ? (
                            <>
                              <div style={{ display: "flex" }}>
                                <NavbarIconLink
                                  href="/cart"
                                  style={{ marginRight: "1.5rem" }}
                                >
                                  <ShoppingBagOutlined
                                    sx={{ color: "#D5AE35" }}
                                  />
                                  <BadgeCount bg="#D5AE35" color="#fff">
                                    {cart.length}
                                  </BadgeCount>
                                </NavbarIconLink>
                              </div>

                              <NavbarIconLink onClick={handleMenu}>
                                <AccountCircleOutlined
                                  sx={{ color: "#D5AE35" }}
                                />
                              </NavbarIconLink>
                            </>
                          ) : (
                            <>
                              <div style={{ display: "flex" }}>
                                <NavbarIconLink
                                  href="/cart"
                                  style={{ marginRight: "1.5rem" }}
                                >
                                  <ShoppingBagOutlined
                                    sx={{ color: "#D5AE35" }}
                                  />
                                  <BadgeCount bg="#D5AE35" color="#fff">
                                    {cart.length}
                                  </BadgeCount>
                                </NavbarIconLink>
                                <a
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    marginRight: "2rem",
                                  }}
                                  onClick={() =>
                                    get_user() !== null
                                      ? navigate("../dashboard")
                                      : navigate("../login")
                                  }
                                >
                                  <AccountCircleOutlined
                                    style={{ color: "#D5AE35" }}
                                  />
                                </a>
                              </div>
                            </>
                          )}
                        </NavbarUpperList>
                      </NavbarUpper>
                      <NavbarLower>
                        <NavbarLowerList>
                          {NAVBARLIST.map((item) => (
                            <NavbarLink
                              color={
                                location.pathname.replace(/[^A-Za-z]+/g, "") ===
                                  item.link
                                  ? "#D5AE35"
                                  : "#000"
                              }
                              bold={
                                location.pathname.replace(/[^A-Za-z]+/g, "") ===
                                  item.link
                                  ? "700"
                                  : "normal"
                              }
                              href={"/" + item.link}
                            >
                              {item.title}
                            </NavbarLink>
                          ))}
                        </NavbarLowerList>
                      </NavbarLower>
                    </Container>
                  </NavbarList>
                </>
              )}

              <>
                <Menu
                  id="menu-appbar"
                  elevation={0}
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                  // anchorPosition={{
                  //   top: 300,
                  // }}
                  keepMounted
                  open={open}
                  onClose={handleClose}
                >
                  {token ? (
                    <MenuItem>
                      <NavbarMobieLinkList>
                        <Button
                          color="#FFF"
                          bg="#125396"
                          br="2px"
                          pd=".2rem 1rem"
                          label="MY ACCOUNT"
                          size="12px"
                          margin="0 0 .4rem 0"
                          onClick={() =>
                            get_user() !== null
                              ? navigate("../dashboard")
                              : navigate("../login")
                          }
                        />
                        <Button
                          color="#fff"
                          bg="#125396"
                          br="2px"
                          pd=".2rem 1rem"
                          label="LOGOUT"
                          size="12px"
                          onClick={() => handleLogout()}
                        />
                      </NavbarMobieLinkList>
                    </MenuItem>
                  ) : (
                    <MenuItem>
                      <NavbarMobieLinkList>
                        <Button
                          color="#FFF"
                          bg="#125396"
                          br="2px"
                          pd=".2rem 1rem"
                          label="REGISTER"
                          size="12px"
                          margin="0 0 .4rem 0"
                          href="/register"
                        // onClick={() => handleOpenRegister()}
                        />
                        <Button
                          color="#FFF"
                          bg="#125396"
                          br="2px"
                          pd=".2rem 1rem"
                          label="LOGIN"
                          size="12px"
                          href="/login"
                        // onClick={() => handleOpenLogin()}
                        />
                      </NavbarMobieLinkList>
                    </MenuItem>
                  )}
                </Menu>
              </>
            </Toolbar>
          </AppBar>
        </Container>
      </NavbarContainer>
    </>
  );
};

export default NavbarCentered;
