import React, {
  useState,
  useEffect
} from "react";
import Slider from "react-slick";
import useStyles from "./styles";
import NavbarCentered from "../../components/NavbarCentered/NavbarCentered";
import Section from "../../components/Section/Section";
import Footer from "../../components/Footer/Footer";
import FormInputNoLabel from "../../components/FormInputNoLabel/FormInputNoLabel";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";

import banner from "../../assets/images/banner.png";
import prod001 from "../../assets/images/prod001.png";
import icon001 from "../../assets/images/icon001.png";
import icon002 from "../../assets/images/icon002.png";
import icon003 from "../../assets/images/icon003.png";
import icon004 from "../../assets/images/icon004.png";

import cat001 from "../../assets/images/cat001.png";
import cat002 from "../../assets/images/cat002.png";

import bannercontact from "../../assets/images/bannerContact.png";

import paymentmethod from "../../assets/images/payment.png";

import Paragraph from "../../components/Typography/Paragraph";
import Loader from "react-js-loader";
import toast from 'react-simple-toasts';
import Button from "../../components/Button/Button";
import { Container, Grid } from "@mui/material";
import { ArrowForward, LocationOn, Mail, Phone } from "@mui/icons-material";
import { display } from "@mui/system";

import {
  register,
} from "../../API/API"
import useUser from "../../hooks/useUser";

export default function Register() {
  const classes = useStyles();
  const navigate = useNavigate();
  const {
    set_user,
    get_user
  } = useUser();

  const [loginData, setLoginData] = useState({
    name: "",
    email: "",
    password: "",
    referral_code: "",
  })

  const handleChange = (event) => {
    setLoginData({
      ...loginData,
      [event.target.name]: event.target.value,
    });
  }

  const handleLogin = () => {
    if (loginData.email.length == 0) {
      toast('Please enter email');
      return
    }
    register(loginData).then((resMessage) => {
      if (resMessage.status) {
        toast('Register Successful');
        set_user(resMessage.data);
        window.localStorage.setItem("token", resMessage.data.token);
        navigate('../')
      } else {
        toast(resMessage.message);
      }
    });
  }
  return (
    <React.Fragment>
      <NavbarCentered bg="#FFF" />
      <div className={classes.theBG}>
        <Container>
          <Section pd="4rem 0 2rem 0">
            <div className={classes.theBoxWhite}>
              <Paragraph
                center
                color="#402313"
                bold="700"
                margin="0 0 1rem 0"
                size="34px"
              >
                Create Account
              </Paragraph>
              <FormInputNoLabel
                placeholder="Referral Code"
                name="referral_code"
                onChange={handleChange}
                style={{
                  borderBottom: "1px solid #707070",
                  marginBottom: "3rem",
                }}
              />
              <FormInputNoLabel
                placeholder="Name"
                name="name"
                onChange={handleChange}
                style={{
                  borderBottom: "1px solid #707070",
                  marginBottom: "3rem",
                }}
              />
              <FormInputNoLabel
                placeholder="Email"
                name="email"
                onChange={handleChange}
                style={{
                  borderBottom: "1px solid #707070",
                  marginBottom: "3rem",
                }}
              />
              <FormInputNoLabel
                placeholder="Password"
                name="password"
                onChange={handleChange}
                type="password"
                style={{
                  borderBottom: "1px solid #707070",
                  marginBottom: "3rem",
                }}
              />
              <br />
              <Button label="SIGN UP" br="6px" color="#fff" bg="#D5AE35"
                onClick={() => handleLogin()}
              />
              <br />
              <a
                href="/login"
                style={{
                  textDecoration: "underline",
                  textAlign: "center",
                  display: "block",
                }}
              >
                Sign In
              </a>
            </div>
          </Section>
        </Container>
      </div>

      <Footer />
    </React.Fragment>
  );
}
