import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import useStyles from "./styles";
import NavbarCentered from "../../components/NavbarCentered/NavbarCentered";
import Section from "../../components/Section/Section";
import Footer from "../../components/Footer/Footer";
import FormInputNoLabel from "../../components/FormInputNoLabel/FormInputNoLabel";
import { useNavigate, useLocation, useParams } from "react-router-dom";

import func001 from "../../assets/images/func001.png";
import func002 from "../../assets/images/func002.png";
import func003 from "../../assets/images/func003.png";

import func004 from "../../assets/images/func004.png";
import func005 from "../../assets/images/func005.png";
import func006 from "../../assets/images/func006.png";
import func007 from "../../assets/images/func007.png";

import funcc001 from "../../assets/images/funcc001.png";
import funcc002 from "../../assets/images/funcc002.png";

import prod001 from "../../assets/images/prod001.png";
import prod002 from "../../assets/images/prod002.png";
import prod003 from "../../assets/images/prod003.png";

import Paragraph from "../../components/Typography/Paragraph";
import Loader from "react-js-loader";
import toast from "react-simple-toasts";
import Button from "../../components/Button/Button";
import { Box, Container, Grid } from "@mui/material";
import { ArrowForward, LocationOn, Mail, Phone } from "@mui/icons-material";
import { display } from "@mui/system";
import FormInput from "../../components/FormInput/FormInput";
import { get_product_detail, get_random_product } from "../../API/API";
import useCart from "../../hooks/useCart";

export default function ProductPillowDetail() {
  const classes = useStyles();
  const navigate = useNavigate();
  const params = useParams();

  const { addProduct, getCart } = useCart();

  console.log(getCart());

  const [loading, setLoading] = useState(false);

  const [chooseSelection, setChooseSelection] = React.useState(0);
  const [productData, setProductData] = useState(null);
  const [productREData, setProductREData] = useState(null);
  const [quantity, setQuantity] = useState(1);

  const [tabPhoto, setTabPhoto] = React.useState(1);

  const handleChangeTabPhoto = (text) => {
    setTabPhoto(text);
  };

  const setting = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const getData = async () => {
    setLoading(true);
    var resREProduct = await get_random_product({});
    if (resREProduct.status) {
      setProductREData(resREProduct.data);
     
    }
    var resProduct = await get_product_detail({
      product_id: params.product_id,
    });
    if (resProduct.status) {
      setProductData(resProduct.data);
      setTabPhoto(resProduct.data.product_images[0].image)
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, [params]);

  const handleAddProduct = () => {
    var addData = {
      product_id: params.product_id,
      quantity: quantity,
      price: parseInt(productData.price_member),
      image: productData.image,
      name: productData.name,
    };
    addProduct(addData);
    toast("Product added");
  };

  return (
    <React.Fragment>
      {loading ? (
        <div
          style={{
            display: "flex",
            height: "100vh",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Loader type="bubble-loop" bgColor="#402313" size={50} />
        </div>
      ) : (
        <React.Fragment>
          <NavbarCentered bg="#FFF" />
          <Container>
            <Section pd="4rem 0 2rem 0">
              <div style={{ display: "flex" }}>
                <Paragraph>Home &gt; </Paragraph>
                <a style={{ marginLeft: "10px" }}>
                  {" "}
                  {productData !== null && productData.name}
                </a>
              </div>
              <br />
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={6}>
                  <div className={classes.theProd}>
                    {/* <img src={productData !== null && productData.image} /> */}
                    <img src={tabPhoto} />
                  </div>

                  {productData && productData.product_images && (
                    <Box className={classes.theGalleryList}>
                      {productData.product_images.map((item) => (
                        <div
                          className={
                            tabPhoto == item.image
                              ? classes.theGalleryActive
                              : classes.theGallery
                          }
                          onClick={() => {
                            handleChangeTabPhoto(item.image);
                          }}
                        >
                          <img src={item.image} />
                        </div>
                      ))}
                    </Box>
                  )}
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <Paragraph
                    bold="700"
                    color="#D5AE35"
                    size="34px"
                    margin="0 0 2rem 0"
                  >
                    {productData !== null && productData.name}
                  </Paragraph>
                  {/* <Paragraph bold="600" margin="0 0 1rem 0">
                      Made for design and sleep.
                    </Paragraph> */}
                  <Paragraph
                    size="14px"
                    dangerouslySetInnerHTML={{
                      __html: productData !== null && productData.description,
                    }}
                  ></Paragraph>
                  <br />
                  {/* <Paragraph margin="0 0 1rem 0">Type:</Paragraph>
                    <div>
                      <div style={{ display: "flex", flexWrap: "wrap" }}>
                        <label className={classes.theTypeBox}>
                          <input
                            className={classes.theHiddenRadioButton}
                            type="radio"
                            // value={item.product_option_selection_id}
                            name="product_option_selection_id"
                            checked={chooseSelection == 0}
                          // onChange={handleChangeChooseSelection}
                          // onClick={() =>
                          //   handleChooseSelection(
                          //     item.product_option_selection_id
                          //   )
                          // }
                          />
                          <div
                            className={
                              chooseSelection == 0
                                ? classes.theActive
                                : classes.theDefault
                            }
                          >
                            <Paragraph>Iceland Gel Pillow (Classic)</Paragraph>
                          </div>
                        </label>

                        <label className={classes.theTypeBox}>
                          <input
                            className={classes.theHiddenRadioButton}
                            type="radio"
                            // value={item.product_option_selection_id}
                            name="product_option_selection_id"
                            checked={chooseSelection == 1}
                          // onChange={handleChangeChooseSelection}
                          // onClick={() =>
                          //   handleChooseSelection(
                          //     item.product_option_selection_id
                          //   )
                          // }
                          />
                          <div
                            className={
                              chooseSelection == 1
                                ? classes.theActive
                                : classes.theDefault
                            }
                          >
                            <Paragraph>Iceland Gel Pillow (Curve)</Paragraph>
                          </div>
                        </label>
                        <label className={classes.theTypeBox}>
                          <input
                            className={classes.theHiddenRadioButton}
                            type="radio"
                            // value={item.product_option_selection_id}
                            name="product_option_selection_id"
                            checked={chooseSelection == 2}
                          // onChange={handleChangeChooseSelection}
                          // onClick={() =>
                          //   handleChooseSelection(
                          //     item.product_option_selection_id
                          //   )
                          // }
                          />
                          <div
                            className={
                              chooseSelection == 2
                                ? classes.theActive
                                : classes.theDefault
                            }
                          >
                            <Paragraph>Iceland Gel Pillow (Butterfly)</Paragraph>
                          </div>
                        </label>
                      </div>
                    </div> */}

                  {/* <Paragraph
                    size="18px"
                    style={{ textDecoration: "line-through" }}
                  >
                    RM 1000.00
                  </Paragraph>
                  <Paragraph color="#d5ae35" size="34px" bold="600">
                    RM 1000.00
                  </Paragraph> */}

                  <br />
                  <FormInput
                    value={quantity}
                    onChange={(e) => setQuantity(e.target.value)}
                    label="Quantity"
                    type="number"
                    border="1p solid #8C8074"
                    style={{ width: "30%" }}
                  />
                  <br />
                  <div style={{ width: "50%" }}>
                    <Button
                      onClick={() => handleAddProduct()}
                      label="Add to Cart"
                      br="4px"
                      color="#fff"
                      bg="#D5AE35"
                      pd=".6rem 1rem"
                      bold="600"
                    />
                  </div>
                </Grid>
              </Grid>
              <br />
            </Section>
          </Container>
          {productData !== null && productData.category_id == 1 && (
            <div className={classes.theRoll}>
              {/* <img src={pillowbanner} /> */}
              <div className={classes.theAb}>
                <Grid container spacing={5}>
                  <Grid item xs={12} sm={12} md={3}>
                    <div className={classes.theFunc}>
                      <img src={func004} />
                    </div>
                    <Paragraph color="#fff" bold="700" center>
                      Non-Toxic
                    </Paragraph>
                  </Grid>
                  <Grid item xs={12} sm={12} md={3}>
                    <div className={classes.theFunc}>
                      <img src={func005} />
                    </div>
                    <Paragraph color="#fff" bold="700" center>
                      High Resilience
                    </Paragraph>
                  </Grid>
                  <Grid item xs={12} sm={12} md={3}>
                    <div className={classes.theFunc}>
                      <img src={func006} />
                    </div>
                    <Paragraph color="#fff" bold="700" center>
                      Premium Quality Stitching
                    </Paragraph>
                  </Grid>
                  <Grid item xs={12} sm={12} md={3}>
                    <div className={classes.theFunc}>
                      <img src={func007} />
                    </div>
                    <Paragraph color="#fff" bold="700" center>
                      To Improve Blood Circulation
                    </Paragraph>
                  </Grid>
                </Grid>
              </div>
            </div>
          )}
          {productData !== null && productData.category_id == 2 && (
            <div className={classes.theRoll}>
              {/* <img src={pillowbanner} /> */}
              <div className={classes.theAb}>
                <Grid container spacing={5}>
                  <Grid item xs={12} sm={12} md={4}>
                    <div className={classes.theFunc}>
                      <img src={func001} />
                    </div>
                    <Paragraph color="#0F4D72" bold="700" center>
                      Slow Rebound Memory Foam
                    </Paragraph>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4}>
                    <div className={classes.theFunc}>
                      <img src={func002} />
                    </div>
                    <Paragraph color="#0F4D72" bold="700" center>
                      Iceland Gel
                    </Paragraph>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4}>
                    <div className={classes.theFunc}>
                      <img src={func003} />
                    </div>
                    <Paragraph color="#0F4D72" bold="700" center>
                      Breathable Heat Dissipation
                    </Paragraph>
                  </Grid>
                </Grid>
              </div>
            </div>
          )}
          <br />
          <Section>
            <Container>
              {productData !== null && (
                <div className={classes.theSpec}>
                  <img src={productData.specification} />
                </div>
              )}
              {/* <div className={classes.theFuncc}>
                  <img src={funcc001} />
                </div>
                <div className={classes.theFuncc}>
                  <img src={funcc002} />
                </div>
                <br />
                <br />
                <br />
                <Paragraph
                  bold="700"
                  center
                  size="34px"
                  color="#8C8074"
                  margin="0 0 2rem 0"
                >
                  Product Specification
                </Paragraph>
                <div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      borderBottom: "2px solid #8C8074",
                      padding: "2rem 1rem",
                    }}
                  >
                    <Paragraph bold="700" color="#8C8074" style={{ width: "50%" }}>
                      Brand
                    </Paragraph>
                    <Paragraph style={{ width: "50%" }}>
                      Contour Memory Pillow
                    </Paragraph>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      borderBottom: "2px solid #8C8074",
                      padding: "2rem 1rem",
                    }}
                  >
                    <Paragraph bold="700" color="#8C8074" style={{ width: "50%" }}>
                      Model
                    </Paragraph>
                    <Paragraph style={{ width: "50%" }}>
                      Contour Memory Pillow
                    </Paragraph>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      borderBottom: "2px solid #8C8074",
                      padding: "2rem 1rem",
                    }}
                  >
                    <Paragraph bold="700" color="#8C8074" style={{ width: "50%" }}>
                      Product Type
                    </Paragraph>
                    <Paragraph style={{ width: "50%" }}>Pillow</Paragraph>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      // borderBottom: "2px solid #8C8074",
                      padding: "2rem 1rem",
                    }}
                  >
                    <Paragraph bold="700" color="#8C8074" style={{ width: "50%" }}>
                      Delivery
                    </Paragraph>
                    <Paragraph style={{ width: "50%" }}>
                      Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean
                      commodo ligula eget dolor. Aenean massa. Cum sociis natoque
                      penatibus et magnis dis parturient montes, nascetur ridiculus
                      mus.{" "}
                    </Paragraph>
                  </div>
                </div> */}
            </Container>
          </Section>
          <div className={classes.theBGReview}>
            <div className={classes.theReview}>
              <Slider className={classes.root} {...setting}>
                <div className={classes.theBox}>
                  <Paragraph center>
                    "Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                    Aenean commodo ligula eget dolor. Aenean massa. Cum sociis
                    natoque penatibus et magnis dis parturient montes, nascetur
                    ridiculus mus. Donec quam felis"
                  </Paragraph>
                </div>
                <div className={classes.theBox}>
                  <Paragraph center>
                    "Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                    Aenean commodo ligula eget dolor. Aenean massa. Cum sociis
                    natoque penatibus et magnis dis parturient montes, nascetur
                    ridiculus mus. Donec quam felis"
                  </Paragraph>
                </div>
              </Slider>
            </div>
          </div>
          <Section pd="3rem 0">
            <Container>
              <Paragraph center bold="700" color="#402313" size="34px">
                You might also need
              </Paragraph>
              <br />
              <Grid container spacing={3}>
                {productREData !== null &&
                  productREData.map((res) => (
                    <Grid item xs={12} sm={6} md={4}>
                      <a
                        onClick={() => navigate("../product/" + res.product_id)}
                      >
                        <div className={classes.theProdRE}>
                          <img src={res.image} alt={res.image} />
                        </div>
                        <Paragraph margin="0 0 .4rem 0">{res.name}</Paragraph>
                        <Paragraph>RM {res.price}</Paragraph>
                      </a>
                    </Grid>
                  ))}
              </Grid>
            </Container>
          </Section>

          <Footer />
        </React.Fragment>
      )}
    </React.Fragment>
  );
}
