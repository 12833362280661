import React, {
  useState,
  useEffect
} from "react";
import {
  Container,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Grid,
  styled,
} from "@mui/material";
import NavbarDashboard from "../../components/NavbarDashboard/NavbarDashboard";
import Paragraph from "../../components/Typography/Paragraph";
import useStyles from "./styles";
import Button from "../../components/Button/Button";
import CopyIcon from '../../assets/images/copy.svg'
import FacebookIcon from '../../assets/images/facebook.svg'
import WhatsAppIcon from '../../assets/images/whatsapp.svg'
import TwitterIcon from '../../assets/images/twitter.svg'
import ReferralImg from '../../assets/images/referral.svg'
import DeleteIcon from '../../assets/images/bin.svg'
import { WhatsApp } from "@mui/icons-material";
import FormInput from "../../components/FormInput/FormInput";
import Loader from "react-js-loader";
import toast from 'react-simple-toasts';
import {
  get_member,
} from "../../API/API";

export default function DashboardAllReferral() {
  const classes = useStyles();
  const [memberData, setMemberData] = useState(null)

  const getData = async () => {
    var resMember = await get_member({})
    if (resMember.status) {
      setMemberData(resMember.data)
    }
  }

  useEffect(() => {
    getData();
  }, [])

  return (
    <React.Fragment>
      <NavbarDashboard
        children={
          <div>
            <div className={classes.theBGDashboard}>
              <div className={classes.theTableCard}>
                <div className={classes.theTFlex}>

                  <Paragraph
                    color="#402313"
                    size="14px"
                    bold="500"
                    margin="0px 0 15px"
                  >
                    My Referrals
                  </Paragraph>
                </div>

                <div className={classes.theTable}>
                  <div className={classes.theTableHeader}>
                    <div style={{ width: "10%" }}>
                      <Paragraph
                        color="#000"
                        size="12px"
                        bold="normal"
                        center
                      >
                        No.
                      </Paragraph>
                    </div>
                    <div style={{ width: "40%" }}>
                      <Paragraph
                        color="#000"
                        size="12px"
                        bold="normal"
                        center
                      >
                        Name
                      </Paragraph>
                    </div>
                    <div style={{ width: "40%" }}>
                      <Paragraph
                        color="#000"
                        size="12px"
                        bold="normal"
                        center
                      >
                        Email
                      </Paragraph>
                    </div>
                    {/* <div style={{ width: "25%" }}>
      <Paragraph
        color="#000"
        size="12px"
        bold="normal"
        center
      >
        Contact Number
      </Paragraph>
    </div> */}
                    {/* <div style={{ width: "10%" }}>
    </div> */}
                  </div>
                  {memberData != null &&
                    <React.Fragment>
                      {memberData.length == 0 ?
                        <Paragraph
                          color="#402313"
                          size="16px"
                          bold="bold"
                          margin="0px 0 15px"
                          center
                        >
                          No Member
                        </Paragraph>
                        :
                        memberData.map((res, index) =>

                          <div className={classes.theTableBodyCard}>
                            <div style={{ width: "10%", wordBreak: "break-all" }}>
                              <Paragraph
                                color="#000"
                                size="12px"
                                bold="normal"
                                center
                              >
                                {index + 1}
                              </Paragraph>
                            </div>
                            <div style={{ width: "40%", wordBreak: "break-all" }}>
                              <Paragraph
                                color="#000"
                                size="12px"
                                bold="normal"
                                center
                              >
                                {res.name}
                              </Paragraph>
                            </div>
                            <div style={{ width: "40%", wordBreak: "break-all" }}>
                              <Paragraph
                                color="#000"
                                size="12px"
                                bold="normal"
                                center
                              >
                                {res.username}
                              </Paragraph>
                            </div>
                            {/* <div style={{ width: "25%", wordBreak: "break-all" }}>
              <Paragraph
                color="#000"
                size="12px"
                bold="normal"
                center
              >
                {res.contact}
              </Paragraph>
            </div> */}
                            {/* <div style={{ width: "10%", wordBreak: "break-all" }}>
              <div className={classes.theDeleteIcon}>
                <img src={DeleteIcon} />
              </div>
            </div> */}
                          </div>
                        )}
                    </React.Fragment>
                  }
                </div>

              </div>
            </div>
          </div>
        }
      />
    </React.Fragment >
  );
}
