import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import { ArrowBackIos, Close } from "@mui/icons-material";
import { Container, Grid } from "@mui/material";
import Footer from "../../components/Footer/Footer";
import FormInput from "../../components/FormInput/FormInput";
import NavbarCentered from "../../components/NavbarCentered/NavbarCentered";
import Section from "../../components/Section/Section";
import Paragraph from "../../components/Typography/Paragraph";
import useStyles from "./styles";

import prodimg001 from "../../assets/images/prodimg001.png";
import Button from "../../components/Button/Button";
import useCart from "../../hooks/useCart";
import Loader from "react-js-loader";
import toast from "react-simple-toasts";

import { get_profile, submit_order } from "../../API/API";

export default function Checkout(props) {
  const classes = useStyles();
  const navigate = useNavigate();

  const { addProduct, getCart, removeProduct, getTotalCart, clearCart } =
    useCart();

  const [token, setToken] = React.useState(null);
  const [profileData, setProfileData] = useState({
    name: "",
    email: "",
    contact: "",
    address: "",
    postcode: "",
  });

  React.useEffect(() => {
    getToken();
    getData();
  }, []);

  const getToken = () => {
    let tokenres = window.localStorage.getItem("token");
    if (tokenres) {
      setToken(tokenres);
    }
  };

  const getData = async () => {
    var resProfile = await get_profile({});
    if (resProfile.status) {
      console.log(resProfile.data.name);
      setProfileData({
        name: resProfile.data.name,
        email: resProfile.data.username,
        contact: "",
        address: "",
        postcode: "",
      });
    }
  };

  const handleChange = (event) => {
    setProfileData({
      ...profileData,
      [event.target.name]: event.target.value,
    });
  };

  const [chooseAddress, setChooseAddress] = React.useState("");

  const handleChooseAddresss = (e) => {
    setChooseAddress(e);
  };

  const [isLoading, setIsLoading] = React.useState(false);
  const [isLoading2, setIsLoading2] = React.useState(false);
  const handlePurchase = (checkoutType) => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);

    // let token = window.localStorage.getItem("token");

    // if (token == "" || token == undefined || token == "undefined") {
    //   navigate("/login");
    //   setIsLoading(false);
    //   return;
    // }

    var cartLength = getCart().length;
    if (cartLength == 0) {
      alert("Your cart is empty.");
      setIsLoading(false);
      return false;
    }

    if (profileData.name.length == 0) {
      toast("Please enter name");
      setIsLoading(false);
      return;
    }
    if (profileData.email.length == 0) {
      toast("Please enter email");
      setIsLoading(false);
      return;
    }
    if (profileData.contact.length == 0) {
      toast("Please enter contact");
      setIsLoading(false);
      return;
    }
    if (profileData.address.length == 0) {
      toast("Please enter address");
      setIsLoading(false);
      return;
    }
    if (profileData.postcode.length == 0) {
      toast("Please enter postcode");
      setIsLoading(false);
      return;
    }
    var data = {
      ...profileData,
      cart: JSON.stringify(getCart()),
      payment_type: checkoutType,
    };
    submit_order(data).then((res) => {
      if (res.status) {
        // clearCart();
        // toast("Order Submitted");
        // navigate("../order-detail/" + res.orders_id);
        navigate("/payment/" + checkoutType, {
          state: {
            secret: res.data.client_secret,
            payment_id: res.data.id,
          },
        });
        setIsLoading(false);
      } else {
        toast(res.data);
        setIsLoading(false);
      }
    });
  };



  return (
    <React.Fragment>
      <NavbarCentered bg="#FFF" />
      <Container>
        <Section pd="3rem 0">
          {token ? (
            <>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={7}>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Paragraph bold="600">Contact Infomation</Paragraph>
                  </div>
                  <FormInput
                    placeholder="Name"
                    value={profileData.name}
                    name="name"
                    onChange={handleChange}
                    border="1px solid #707070"
                  />
                  <FormInput
                    placeholder="Email"
                    value={profileData.email}
                    name="email"
                    onChange={handleChange}
                    border="1px solid #707070"
                  />
                  <FormInput
                    placeholder="Contact Number"
                    value={profileData.contact}
                    name="contact"
                    onChange={handleChange}
                    border="1px solid #707070"
                  />

                  <Paragraph bold="600" margin="0 0 1rem 0">
                    Shipping Address
                  </Paragraph>

                  <FormInput
                    placeholder="Address"
                    name="address"
                    onChange={handleChange}
                    value={profileData.address}
                    border="1px solid #707070"
                    multiline
                    rows={4}
                  />

                  <FormInput
                    placeholder="Postcode"
                    name="postcode"
                    onChange={handleChange}
                    value={profileData.postcode}
                    border="1px solid #707070"
                  />
                  {/* <div className={classes.theListAddress}>
                    <div>
                      <Paragraph margin=" 0 0 .6rem 0">Manuel Cooper</Paragraph>
                    </div>
                    <Paragraph>123 Jalan ABC 81100</Paragraph>
                    <Paragraph size="14px" color="#637381">
                      123456789
                    </Paragraph>
                  </div> */}
                </Grid>
                <Grid item xs={12} sm={12} md={5}>
                  {/* {cart !== undefined && cart.length > 0
                    ? cart.map((item) => (
                        <div className={classes.theFlex}>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              width: "100%",
                            }}
                          >
                            <div className={classes.theProdIMG}>
                              <img src={item.images} />
                            </div>
                            <Paragraph>{item.name}</Paragraph>
                          </div>
                          <Paragraph
                            style={{ textAlign: "right", width: "25%" }}
                          >
                            RM {item.price}
                          </Paragraph>
                          <a style={{ display: "block", marginLeft: "1rem" }}>
                            <Close />
                          </a>
                        </div>
                      ))
                    : null} */}
                  {getCart().length > 0 &&
                    getCart().map((res) => (
                      <div className={classes.theFlex}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            width: "100%",
                          }}
                        >
                          <div className={classes.theProdIMG}>
                            <img src={res.image} />
                          </div>
                          <Paragraph>{res.name}</Paragraph>
                        </div>
                        <Paragraph style={{ textAlign: "right", width: "25%" }}>
                          RM {(res.price * res.quantity).toFixed(2)}
                        </Paragraph>
                        <a
                          onClick={() => removeProduct(res)}
                          style={{ display: "block", marginLeft: "1rem" }}
                        >
                          <Close />
                        </a>
                      </div>
                    ))}
                  {/* <div className={classes.theFlex_}>
                    <div className={classes.theWidth}>
                      <FormInput
                        placeholder="Promo Code"
                        border="1px solid #707070"
                        style={{ width: "100%" }}
                      />
                    </div>

                    <Button label="APPLY" color="#fff" bg="#D5AE35" br="6px" />
                  </div> */}

                  {/* <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: "1rem",
                      paddingTop: "1rem",
                      borderTop: "1px solid #d7d7d7",
                    }}
                  >
                    <Paragraph size="1.2rem">Subtotal</Paragraph>
                    <Paragraph bold="500" size="1.2rem">
                      RM {getTotalCart()}
                    </Paragraph>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: "1rem",
                    }}
                  >
                    <Paragraph size="1.2rem">Shipping Fee</Paragraph>
                    <Paragraph bold="500" size="1.2rem">
                      RM 0.00
                    </Paragraph>
                  </div> */}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: "1rem",
                      paddingTop: "1rem",
                      borderTop: "1px solid #d7d7d7",
                    }}
                  >
                    <Paragraph size="1.2rem">Total</Paragraph>
                    <Paragraph color="#D5AE35" bold="700" size="1.2rem">
                      RM {getTotalCart()}
                    </Paragraph>
                  </div>
                  <br />

                

                  {isLoading ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        // height: "600px",
                      }}
                    >
                      <Loader
                        type="snipper-default"
                        bgColor={"#878787"}
                        size={30}
                      />
                    </div>
                  ) : (
                    <>
                    <Button
                      label="PAY WITH CARD"
                      color="#fff"
                      bg="#D5AE35"
                      br="6px"
                      onClick={() => handlePurchase("card")}
                    />
                    <br/>
                    <Button
                      label="PAY WITH FPX"
                      color="#fff"
                      bg="#D5AE35"
                      br="6px"
                      onClick={() => handlePurchase("fpx")}
                    />
                    </>
                  )}
                </Grid>
              </Grid>
            </>
          ) : (
            <>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={7}>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Paragraph bold="600">Contact Infomation</Paragraph>
                    <Paragraph>
                      Already have account?{" "}
                      <a
                        style={{
                          color: "#D5AE35",
                          textDecoration: "underline",
                        }}
                      >
                        Log in
                      </a>
                    </Paragraph>
                  </div>
                  <FormInput placeholder="Email" border="1px solid #707070" />
                  <br />
                  <Paragraph bold="600">Shipping Address</Paragraph>
                  <FormInput placeholder="Country" border="1px solid #707070" />
                  <FormInput
                    placeholder="First Name"
                    border="1px solid #707070"
                    name="firstname"
                  />
                  <FormInput
                    placeholder="Last Name"
                    border="1px solid #707070"
                    name="lastname"
                  />
                  <FormInput
                    placeholder="Company (optional)"
                    border="1px solid #707070"
                    name="company"
                  />

                  <FormInput placeholder="Address" border="1px solid #707070" />
                  <FormInput
                    placeholder="Apartment, Suite, ETC (optional)"
                    border="1px solid #707070"
                    name="apartment"
                  />
                  <FormInput
                    placeholder="Postcode"
                    border="1px solid #707070"
                    name="postcode"
                    typr="number"
                  />
                  <FormInput placeholder="City" border="1px solid #707070" />
                  <FormInput
                    placeholder="State/Territoty"
                    border="1px solid #707070"
                    name="state"
                  />
                  <FormInput placeholder="Phone" border="1px solid #707070" />
                  <br />
                  <div style={{ display: "flex" }}>
                    <ArrowBackIos style={{ color: "#707070" }} />
                    <a href="/" style={{ color: "#707070" }}>
                      Return Home
                    </a>
                  </div>
                  <br />
                  <br />
                </Grid>
                <Grid item xs={12} sm={12} md={5}>
                  {getCart() !== undefined && getCart().length > 0
                    ? getCart().map((item) => (
                        <div className={classes.theFlex}>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              width: "100%",
                            }}
                          >
                            <div className={classes.theProdIMG}>
                              <img src={item.image} />
                            </div>
                            <Paragraph>{item.name}</Paragraph>
                          </div>
                          <Paragraph
                            style={{ textAlign: "right", width: "25%" }}
                          >
                            RM {item.price.toFixed(2)}
                          </Paragraph>
                          {/* <a style={{ display: "block", marginLeft: "1rem" }}>
                            <Close />
                          </a> */}
                        </div>
                      ))
                    : null}
                  {/* <div className={classes.theFlex}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <div className={classes.theProdIMG}>
                        <img src={prodimg001} />
                      </div>
                      <div>
                        <Paragraph>Blue Dream Mattress</Paragraph>
                        <Paragraph size="12px">Size L x W x H</Paragraph>
                      </div>
                    </div>
                    <Paragraph style={{ textAlign: "right", width: "25%" }}>
                      RM 100.00
                    </Paragraph>
                    <a style={{ display: "block", marginLeft: "1rem" }}>
                      <Close />
                    </a>
                  </div> */}
                  {/* <div className={classes.theFlex_}>
                    <div className={classes.theWidth}>
                      <FormInput
                        placeholder="Promo Code"
                        border="1px solid #707070"
                        style={{ width: "100%" }}
                      />
                    </div>

                    <Button label="APPLY" color="#fff" bg="#D5AE35" br="6px" />
                  </div> */}

                  {/* <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: "1rem",
                      paddingTop: "1rem",
                      borderTop: "1px solid #d7d7d7",
                    }}
                  >
                    <Paragraph size="1.2rem">Subtotal</Paragraph>
                    <Paragraph bold="500" size="1.2rem">
                      RM {getTotalCart()}
                    </Paragraph>
                  </div> */}

                  {/* <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: "1rem",
                    }}
                  >
                    <Paragraph size="1.2rem">Shipping Fee</Paragraph>
                    <Paragraph bold="500" size="1.2rem">
                      RM 10.00
                    </Paragraph>
                  </div> */}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: "1rem",
                      paddingTop: "1rem",
                      borderTop: "1px solid #d7d7d7",
                    }}
                  >
                    <Paragraph size="1.2rem">Total</Paragraph>
                    <Paragraph color="#D5AE35" bold="700" size="1.2rem">
                      {/* RM 100.00 */}
                      RM {getTotalCart()}
                    </Paragraph>
                  </div>
                  <br />

                  {isLoading ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        // height: "600px",
                      }}
                    >
                      <Loader
                        type="snipper-default"
                        bgColor={"#878787"}
                        size={30}
                      />
                    </div>
                  ) : (
                    <>
                    <Button
                      label="PAY WITH CARD"
                      color="#fff"
                      bg="#D5AE35"
                      br="6px"
                      onClick={() => handlePurchase("card")}
                    />
                    <br/>
                    <Button
                      label="PAY WITH FPX"
                      color="#fff"
                      bg="#D5AE35"
                      br="6px"
                      onClick={() => handlePurchase("fpx")}
                    />
                    </>
                  )}
                </Grid>
              </Grid>
            </>
          )}
        </Section>
      </Container>
      <Footer />
    </React.Fragment>
  );
}
