import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import useStyles, { FormInputSelect } from "./styles";
import { useNavigate, useLocation, useParams } from "react-router-dom";

import NavbarCentered from "../../components/NavbarCentered/NavbarCentered";
import Section from "../../components/Section/Section";
import Footer from "../../components/Footer/Footer";
import FormInputNoLabel from "../../components/FormInputNoLabel/FormInputNoLabel";

import pillow from "../../assets/images/pillow.png";

import prod001 from "../../assets/images/prod001.png";
import prod002 from "../../assets/images/prod002.png";
import prod003 from "../../assets/images/prod003.png";

import bannercontact from "../../assets/images/bannerContact.png";

import paymentmethod from "../../assets/images/payment.png";

import Paragraph from "../../components/Typography/Paragraph";
import Loader from "react-js-loader";
import toast from "react-simple-toasts";
import Button from "../../components/Button/Button";
import { Box, Container, Grid, MenuItem, Select } from "@mui/material";
import { get_product_by_category } from "../../API/API";

export default function ProductPillow() {
  const classes = useStyles();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [productData, setProductData] = useState(null);

  const [sortList, setSortList] = React.useState(0);
  const handleChangeSort = (event) => {
    setSortList(event.target.value);
    console.log(sortList);
  };

  const getData = async () => {
    setLoading(true);
    var resProduct = await get_product_by_category({ category_id: 2 });
    if (resProduct.status) {
      setProductData(resProduct.data);
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <React.Fragment>
      {loading ? (
        <div
          style={{
            display: "flex",
            height: "100vh",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Loader type="bubble-loop" bgColor="#402313" size={50} />
        </div>
      ) : (
        <React.Fragment>
          <NavbarCentered bg="#FFF" />
          <div className={classes.theBG}>
            <img src={pillow} />
            {/* <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "20%",
                transform: "translate(-50%,-50%)",
              }}
            >
              <Paragraph bold="700" color="#FFF" size="50px">
                PILLOW
              </Paragraph>
              <Paragraph color="#FFF" size="18px">
                Discovering the Ideal Pillow for Your Sleep Style
              </Paragraph>
            </Box> */}
          </div>

          <Container>
            <Section pd="4rem 0 2rem 0">
              <div style={{ display: "flex", alignItems: "center" }}>
                <Paragraph margin="0 1rem 0 0">Sort By:</Paragraph>
                <FormInputSelect>
                  <Select
                    onChange={handleChangeSort}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    value={sortList}
                  >
                    <MenuItem value="0">Latest to Oldest</MenuItem>
                    <MenuItem value="1">Oldest to Latest</MenuItem>
                  </Select>
                </FormInputSelect>
              </div>
              <br />
              <Grid container spacing={3}>
                {productData !== null &&
                  productData.map((res) => (
                    <Grid item xs={12} sm={6} md={4}>
                      <a
                        onClick={() => navigate("../product/" + res.product_id)}
                      >
                        <div className={classes.theProd}>
                          <img src={res.image} alt={res.image} />
                        </div>
                        <Paragraph margin="0 0 .4rem 0">{res.name}</Paragraph>
                        <Paragraph>RM {res.price}</Paragraph>
                      </a>
                    </Grid>
                  ))}
                {/* <Grid item xs={12} sm={6} md={4}>
              <a href="/pillowdetail">
                <div className={classes.theProd}>
                  <img src={prod002} />
                </div>
                <Paragraph margin="0 0 .4rem 0">
                  Contour Memory Pillow
                </Paragraph>
                <Paragraph>RM 100.00</Paragraph>
              </a>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <a href="/pillowdetail">
                <div className={classes.theProd}>
                  <img src={prod003} />
                </div>
                <Paragraph margin="0 0 .4rem 0">
                  Premium Clound Memory Pillow
                </Paragraph>
                <Paragraph>RM 100.00</Paragraph>
              </a>
            </Grid> */}
              </Grid>
            </Section>
          </Container>

          <Footer />
        </React.Fragment>
      )}
    </React.Fragment>
  );
}
