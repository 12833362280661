import styled from "styled-components/macro";
import { makeStyles } from "@mui/styles";
import { md } from "../../styles/responsive";
import bannercontact from "../../assets/images/bannerContact.png";

export const CarouselContainer = styled.div`
  position: relative;
  width: 100%;
`;

export const CarouselIMG = styled.img`
  width: 100%;
`;

const config = {
  theBG_: {
    position: "relative",
    padding: "2rem",
    background: "#402313",
  },

  theLogo: {
    position: "relative",
    width: "50%",
    margin: "0 auto",

    "& img": {
      width: "100%",
    },
  },

  theBox_: {
    position: "relative",
    background: "#fff",
    padding: "1rem",
    boxShadow: "0px 0px 9px 3px rgba(0,0,0,0.1)",
  },

  theIMG: {
    position: "relative",
    width: "10%",
    "& img": {
      width: "100%",
    },

    ...md("sm", {
      width: "20%",
    }),

    ...md("md", {
      width: "10%",
    }),
  },

  theWidth: {
    width: "100vw",

    ...md("sm", {
      width: "50vw",
    }),

    ...md("md", {
      width: "100%",
    }),
  },

  theWidth2: {
    width: "100px",

    ...md("sm", {
      width: "100%",
    }),
  },

  theFlexx: {
    display: "block",

    ...md("sm", {
      display: "flex",
      justifyContent: "flex-end",
    }),
  },

  theRight: {
    marginBottom: "1rem",
    ...md("sm", {
      marginRight: "1rem",
      marginBottom: 0,
    }),
  },
};

export default makeStyles(config);
