import { makeStyles } from "@mui/styles";
import styled from "styled-components";

export const config = {
  root: {},
};

export const Text = styled.p`
  color: ${(props) => (props.textLabelColor ? props.textLabelColor : "#000")};
  font-size: ${(props) => (props.textLabelSize ? props.textLabelSize : "1rem")};
  font-weight: ${(props) => props.textLabelBold};
  margin: ${(props) => (props.textLabelMargin ? props.textLabelMargin : "0 0 .5rem 0")};
`;

export default makeStyles(config);
