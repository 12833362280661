import React from "react";
import {
  Container,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Grid,
  styled,
} from "@mui/material";
import NavbarDashboard from "../../components/NavbarDashboard/NavbarDashboard";
import Paragraph from "../../components/Typography/Paragraph";
import useStyles from "./styles";
import Button from "../../components/Button/Button";
import CopyIcon from '../../assets/images/copy.svg'
import FacebookIcon from '../../assets/images/facebook.svg'
import WhatsAppIcon from '../../assets/images/whatsapp.svg'
import TwitterIcon from '../../assets/images/twitter.svg'
import ReferralImg from '../../assets/images/referral.svg'
import { WhatsApp } from "@mui/icons-material";
import FormInput from "../../components/FormInput/FormInput";

export default function DashboardAgent() {
  const classes = useStyles();
  return (
    <React.Fragment>
      <NavbarDashboard
        children={
          <div>
            <div className={classes.theBGDashboard}>
              <div className={classes.theLevelCard}>
                <div className={classes.theLTFlex}>
                  <Paragraph
                    size='14px'
                    color="#000"
                    bold="normal"
                  >
                    Level<span style={{ margin: "0 30px" }}>:</span><span style={{ fontWeight: "bold" }}>Agent</span>
                  </Paragraph>
                </div>
              </div>
              <div className={classes.theShareCard}>
                <div className={classes.theSCLeft}>
                  <Paragraph
                    size='14px'
                    color="#402313"
                    bold="bold"
                    margin="0 0 4px"
                  >
                    SHARE YOUR UNIQUE CODE
                  </Paragraph>
                  <Paragraph
                    size='12px'
                    color="#000"
                    bold="normal"
                    margin="0 0 20px"
                  >
                    Get points when every friend signs up
                  </Paragraph>
                  <div className={classes.theSCLFlex}>
                    <div className={classes.theReferal}>
                      <Paragraph
                        size='12px'
                        color="#707070"
                        bold="bold"
                        center
                      >
                        ACF1510QW
                      </Paragraph>
                    </div>
                    <div className={classes.theCopy}>
                      <img src={CopyIcon} />
                    </div>
                  </div>
                  <div className={classes.theSocialFlex}>
                    <div className={classes.theSocialIcon}>
                      <img src={WhatsAppIcon} />
                    </div>
                    <div className={classes.theSocialIcon}>
                      <img src={FacebookIcon} />
                    </div>
                    <div className={classes.theSocialIcon}>
                      <img src={TwitterIcon} />
                    </div>
                  </div>
                </div>
                <div className={classes.theSCRight}>
                  <div className={classes.theSCRImg}>
                    <img src={ReferralImg} />
                  </div>
                </div>
              </div>
              <div className={classes.theForm}>

                <Paragraph
                  size='18px'
                  color="#000"
                  bold="bold"
                  margin="0 0 20px"
                >
                  Personal Information
                </Paragraph>
                <hr />
                <div className={classes.theField}>
                  <FormInput
                    label="Name"
                    placeholder="Helen Regel"
                    border="1px solid #000"
                    br="8px"
                  />
                </div>
                <div className={classes.theField}>
                  <FormInput
                    label="Email"
                    placeholder="helenregel@email.com"
                    border="1px solid #000"
                    bg="#ECECEC"
                    mb="30px"
                    br="8px"
                    disabled
                  />
                </div>
                <hr />
                <div className={classes.theFButton}>
                  <Button label="Save Change"
                    color="#fff"
                    bg="#D5AE35"
                    pd="10px 25px"
                    br="6px"
                  />
                </div>
              </div>
            </div>
          </div>
        }
      />
    </React.Fragment>
  );
}
