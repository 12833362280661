import React, { useContext } from "react";
import { CartContext } from "../context/CartContext";

const Storage = (cartItems) => {
  // console.log('cartItems', cartItems, Array.isArray(cartItems))
  localStorage.setItem(
    "cart",
    JSON.stringify(Array.isArray(cartItems) == false ? cartItems : [])
  );
};

const useCart = () => {
  const [cart, setCart] = useContext(CartContext);
  const getVoucher = () => {
    // console.log(cart.voucher, 'cart.voucher')
    if (cart.voucher !== '') {
      return cart.voucher;
    } else {
      return false;
    }
    // console.log(cart.voucher, 'cart.voucher')
    // if (cart.voucher) {
    // 	return cart.voucher;
    // }
    // return false;
  }
  const getVoucherValue = () => {

    // alert(cart.voucher_value)
    if (cart.voucher_value) {
      return cart.voucher_value;
    }
    return false;
  }

  const setVoucher = (voucher) => {
    if (cart.cart !== undefined) {
      var cartTemp = [...cart.cart];
    } else {
      var cartTemp = [];
    }
    // console.log('setvoucher usecart', cart)
    var existed = 0;
    var index = 0;
    // var filtercartTemp = cartTemp.filter();
    for (var i = 0; i < cartTemp.length; i++) {
      if (cartTemp[i].quantity < 1) {
        existed = 1;
        index = i;
        cartTemp.splice(i, 1);
      }
    }
    setCart({
      ...cart,
      cart: cartTemp,
      voucher: voucher.voucher,
      voucher_value: voucher.voucher_value
    });
    Storage({
      ...cart,
      cart: cartTemp,
      voucher: voucher.voucher,
      voucher_value: voucher.voucher_value
    })
  }

  function getCart() {

    // console.log('getCart usecart', cart)
    if (cart.cart) {
      // console.log('cart.cart', cart.cart)
      return cart.cart;
    }
    return [];
  }

  function clearCart() {
    if (cart.cart !== undefined) {
      var cartTemp = [...cart.cart];
    } else {
      var cartTemp = [];
    }
    var newCart = []
    // console.log('before clearCart cartTemp', cartTemp)
    for (var i = 0; i < cartTemp.length; i++) {
      if (cartTemp[i].check == false) {
        // cartTemp.splice(i, 1);
        newCart.push(cartTemp[i])
      }
    }
    // console.log('after clearCart cartTemp', newCart)
    setCart({
      ...cart,
      cart: newCart,
      voucher: [],
      voucher_value: []
    });
    Storage({
      ...cart,
      cart: newCart,
      voucher: [],
      voucher_value: []
    });
  }

  function setCartFunction(cart = []) {
    setCart(cart);
  }

  function addProduct(item) {
    // var cartTemp = [...cart];
    if (cart.cart !== undefined) {
      var cartTemp = [...cart.cart];
    } else {
      var cartTemp = [];
    }
    var newItem = { ...item };

    // newItem.price = getPriceByQuantity(newItem, newItem.quantity);


    if (cartTemp.length === 0) {
      newItem['index'] = 0;
      cartTemp.push(newItem);
    } else {
      var existed = 0;
      var index = 0;
      for (var i = 0; i < cartTemp.length; i++) {
        if (
          cartTemp[i].product_id == newItem.product_id
        ) {
          existed = 1;
          index = i;
        }
      }

      if (existed == 1) {
        var new_quantity =
          parseFloat(cartTemp[index].quantity) + parseFloat(newItem.quantity);

        cartTemp[index]['quantity'] = new_quantity;
        // cartTemp[index]['price'] = getPriceByQuantity(
        //   cartTemp[index],
        //   new_quantity,
        // );
      } else {
        newItem['index'] = cartTemp.length;
        cartTemp.push(newItem);
      }
    }
    for (var i = 0; i < cartTemp.length; i++) {
      if (cartTemp[i].quantity < 1) {
        // console.log('splice out')
        cartTemp.splice(i, 1);
      }
    }
    // console.log('cartTemp.splice', cartTemp)
    setCart({
      ...cart,
      cart: cartTemp
    });

    // alert(JSON.stringify(cartTemp))
    // console.log('addproduct cart', cart)
    Storage({
      ...cart,
      cart: cartTemp
    });

  }

  function increaseProduct(index) {
    var cartTemp = [...cart.cart];
    var quantity = parseFloat(cartTemp[index].quantity) + 1;
    cartTemp[index]['quantity'] = parseFloat(quantity);
    cartTemp[index]['price'] = parseFloat(
      getPriceByQuantity(cartTemp[index], quantity),
    );
    setCart({
      ...cart,

      cart: cartTemp
    });
    Storage({
      ...cart,

      cart: cartTemp
    });

  }

  function getPriceByQuantity(cartRowItem, qty) {
    console.log(cartRowItem, qty, "cartRowItem")
    return cartRowItem.price * qty;
  }

  function decreaseProduct(index) {
    var cartTemp = [...cart.cart];
    var quantity = parseFloat(cartTemp[index].quantity) - 1;

    if (quantity > 0) {
      cartTemp[index]['quantity'] = quantity;
      cartTemp[index]['price'] = getPriceByQuantity(cartTemp[index], quantity);
    } else {
      cartTemp.splice(index, 1);
      // window.localStorage.setItem('length', cartTemp.length - 1)
      for (var i = 0; i < cartTemp.length; i++) {
        cartTemp[i]['index'] = i;
      }
    }

    setCart({
      ...cart,
      cart: cartTemp
    });
    Storage({
      ...cart,
      cart: cartTemp
    });

  }


  function getTotalCart() {

    // console.log('getTotalCart usecart', cart)
    if (cart.cart !== undefined) {
      var cartTemp = [...cart.cart];
    } else {
      var cartTemp = [];
    }
    var total_cart_price = 0;

    for (var i = 0; i < cartTemp.length; i++) {

      total_cart_price += parseFloat(
        cartTemp[i].price * cartTemp[i].quantity
      );
    }
    return total_cart_price.toFixed(2);
  }


  function getTotalCartArray() {
    if (cart.cart !== undefined) {
      var cartTemp = [...cart.cart];
    } else {
      var cartTemp = [];
    }
    var total_cart = [];

    for (var i = 0; i < cartTemp.length; i++) {

      if (cartTemp[i].check == false) {
        continue;
      }
      total_cart.push(cartTemp[i]);
    }
    // console.log('getTotalCartArray', total_cart)
    return total_cart;
  }

  function removeProduct(item) {
    if (cart.cart !== undefined) {
      var cartTemp = [...cart.cart];
    } else {
      var cartTemp = [];
    }
    var index = -1;
    for (var i = 0; i < cartTemp.length; i++) {

      if (item.product_id == cartTemp[i].product_id
      ) {
        index = i;
      }
    }

    if (index < 0) {
      return;
    }
    cartTemp.splice(index, 1);

    for (var i = 0; i < cartTemp.length; i++) {
      cartTemp[i]['index'] = i;
    }

    setCart({
      ...cart,
      cart: cartTemp
    });
    Storage({
      ...cart,
      cart: cartTemp
    });
  }

  return {
    addProduct,
    clearCart,
    getTotalCart,
    getTotalCartArray,
    removeProduct,
    getCart,
    increaseProduct,
    cart,
    decreaseProduct,
    setCartFunction,
    setVoucher,
    getVoucher,
    getVoucherValue,
  };
};

export default useCart;

/*
{
        cart: {},
        shop: shop_id
}

*/