import styled from "styled-components/macro";
import { makeStyles } from "@mui/styles";
import { md } from "../../styles/responsive";
import pillowbanner from "../../assets/images/pillowbanner.png";

export const CarouselContainer = styled.div`
  position: relative;
  width: 100%;
`;

export const CarouselIMG = styled.img`
  width: 100%;
`;

const config = {
  root: {
    position: "relative",
    "& .slick-track": {
      display: "flex",
      flexDirection: "row",
      alignItems: "inherit",
    },
    "& .slick-slide": {
      height: "inherit",
      "& > div": {
        height: "100%",
      },
      "& > div > div": {
        height: "100%",
      },
    },
    "& .slick-arrow": {
      color: "#000",
      display: "flex!important",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      zIndex: 2,

      "&:before": {
        content: "",
        // content: "\f053",
        display: "block",
        width: 24,
        height: 24,
        // backgroundPosition: "center center",
        // backgroundRepeat: "no-repeat",
        // backgroundSize: "contain",
      },

      "&.slick-next": {
        right: -80,

        ...md("sm", {
          right: -100,
        }),

        ...md("md", {
          right: -100,
        }),

        "&:before": {
          content: "",
          // content: "\f054",
          fontFamily: "Font Awesome 5 Free",
          fontWeight: 900,
          color: "#fff",
          fontSize: "2rem",
        },
      },

      "&.slick-prev": {
        left: -80,

        ...md("sm", {
          left: -100,
        }),

        ...md("md", {
          left: -100,
        }),

        "&:before": {
          content: "",
          //   content: "\f053",
          fontFamily: "Font Awesome 5 Free",
          fontWeight: 900,
          color: "#fff",
          fontSize: "2rem",
        },
      },
      "&.slick-disabled": {
        opacity: 1,
      },
    },
    "& .slick-dots": {
      position: "absolute",
      //   bottom: "-25px",
      bottom: "-70px",
      ...md("sm", {
        bottom: "-80px",
      }),
      "& li": {
        margin: "0 5px",
        width: "auto",
        height: "auto",

        "& button": {
          width: 10,
          height: 10,
          borderRadius: "50px",
          backgroundColor: "#adadad",
          //   border: "1px solid #c5c5c5",
          padding: 0,
          transition: "all 0.4s",

          "@media screen and (min-width: 768px)": {
            height: 10,
          },

          "&:before": {
            display: "none",
          },
        },

        "&.slick-active": {
          "& button": {
            width: 10,
            backgroundColor: "#fff",
          },
        },
      },
    },
  },

  theFont: {
    fontSize: "14px",
    ...md("md", {
      fontSize: "34px",
    }),
  },

  theProd: {
    position: "relative",
    width: "100%",

    "& img": {
      width: "100%",
    },

    // ...md("md", {
    //   width: "60%",
    // }),
  },
  theProdRE: {
    position: "relative",
    width: "100%",
    height: "309px",

    "& img": {
      width: "100%",
      height: "309px",  
    },

    // ...md("md", {
    //   width: "60%",
    // }),
  },

  theBG: {
    position: "relative",
    background: "#F1E2C3",
  },

  theGalleryList: {
    position: "relative",
    display: "flex",
    // background: "#333",
    overflow: "auto",
    whiteSpace: "nowrap",
  },

  theGallery: {
    position: "relative",
    marginRight: "1rem",
    cursor: "pointer",
    "& img": {
      width: "150px",
      display: "flex"
    },
  },

  theGalleryActive: {
    position: "relative",
    marginRight: "1rem",
    border: "1px solid #000",
    cursor: "pointer",
    "& img": {
      width: "150px",
      display: "flex"
    },
  },


  theHiddenRadioButton: {
    display: "none",
  },

  theTypeBox: {
    margin: "0 .7rem 1rem 0",
    cursor: "pointer",
  },

  theDefault: {
    // background: '#D5AE35',
    border: "1px solid #8C8074",
    color: "#8C8074",
    padding: ".375rem 1rem",
    borderRadius: "4px",
  },

  theActive: {
    background: "#D5AE35",
    border: "unset",
    color: "#fff",
    padding: ".375rem 1rem",
    borderRadius: "4px",
  },

  theRoll: {
    position: "relative",
    width: "100%",
    height: "70vh",
    backgroundImage: "url(" + pillowbanner + ")",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",

    "& img": {
      width: "100%",
    },

    ...md("md", {
      height: "300px",
    }),
  },

  theAb: {
    position: "absolute",
    // width: '100%',
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
  },

  theFunc: {
    position: "relative",
    width: "40%",
    margin: "0 auto",
    paddingBottom: ".6rem",

    "& img": {
      width: "100%",
    },

    ...md("sm", {
      width: "20%",
    }),

    ...md("md", {
      width: "70%",
    }),
  },

  theFuncc: {
    position: "relative",
    width: "80%",
    margin: "0 auto",
    marginBottom: "1.5rem",

    "& img": {
      width: "100%",
    },
  },

  theBGReview: {
    position: "relative",
    background: "#F1E2C3",
    height: "350px",
    padding: "1rem",
  },

  theReview: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
    margin: "0 auto",
    width: "50%",
  },

  theBox: {
    height: "350px",
  },
  theSpec: {
    width: "100%",

    "& img": {
      width: "100%",
    },
  }
};

export default makeStyles(config);
