import React, {
  useState,
  useEffect
} from "react";
import {
  Container,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Grid,
  styled,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import NavbarDashboard from "../../components/NavbarDashboard/NavbarDashboard";
import Paragraph from "../../components/Typography/Paragraph";
import useStyles from "./styles";
import Button from "../../components/Button/Button";
import CopyIcon from '../../assets/images/copy.svg'
import FacebookIcon from '../../assets/images/facebook.svg'
import WhatsAppIcon from '../../assets/images/whatsapp.svg'
import TwitterIcon from '../../assets/images/twitter.svg'
import ReferralImg from '../../assets/images/referral.svg'
import { WhatsApp } from "@mui/icons-material";
import FormInput from "../../components/FormInput/FormInput";
import Loader from "react-js-loader";
import toast from 'react-simple-toasts';
import {
  get_profile,
  get_wallet,
  edit_name,
} from "../../API/API";

export default function DashboardMemberOrder() {
  const classes = useStyles();
  const navigate = useNavigate();
  const [profileData, setProfileData] = useState(null)
  const [walletData, setWalletData] = useState(null)
  const [userData, setUserData] = useState({
    name: "",
    email: ""
  })

  const getData = async () => {
    var resProfile = await get_profile({})
    if (resProfile.status) {
      setProfileData(resProfile.data)
      setUserData({
        name: resProfile.data.name,
        email: resProfile.data.username
      })
    }
    var resWallet = await get_wallet({})
    if (resWallet.status) {
      setWalletData(resWallet)
    }
  }

  useEffect(() => {
    getData();
  }, [])

  const handleUpgrade = () => {
    window.location.href = "https://api.whatsapp.com/send?phone=https://wa.me/60123456789&text=Hi%20,%20i%20want%20upgrade%20to%20agent"
  }

  const handleChange = (event) => {
    setUserData({
      ...userData,
      [event.target.name]: event.target.value,
    });
  }

  const handleSaveProfile = () => {
    if (userData.name.length == 0) {
      toast('Please enter name');
      return
    }
    edit_name(userData).then((resMessage) => {
      if (resMessage.status) {
        toast('Profile update successful');
      } else {
        toast(resMessage.message);
      }
    });
  }

  return (
    <React.Fragment>
      {profileData !== null &&
        <NavbarDashboard
          props={profileData}
          children={
            <div>
              <div className={classes.theBGDashboard}>
                <div className={classes.theLevelCard}>
                  <div className={classes.theLTFlex}>
                    <Paragraph
                      size='14px'
                      color="#000"
                      bold="normal"
                      margin="18px 0"
                    >
                      Level<span style={{ margin: "0 30px" }}>:</span><span style={{ fontWeight: "bold" }}>{profileData.role}</span>
                    </Paragraph>

                    {profileData.role === 'Member' &&
                      <div className={classes.theFlexText}>
                        <Paragraph
                          size='14px'
                          color="#000"
                          bold="normal"
                          margin="18px 0"
                        >
                          Total Points<span style={{ margin: "0 30px" }}>:</span><span style={{ fontWeight: "bold" }}>{walletData != null && walletData.balance} points</span>
                        </Paragraph>
                      </div>
                    }
                  </div>

                  {profileData.role === 'Member' &&
                    <Button label="Become an Agent" color="#fff" bg="#402313" pd="10px 35px" br="6px"
                      onClick={() => handleUpgrade()} />
                  }
                </div>
                <div className={classes.theShareCard}>
                  <div className={classes.theSCLeft}>
                    <Paragraph
                      size='14px'
                      color="#402313"
                      bold="bold"
                      margin="0 0 4px"
                    >
                      SHARE YOUR UNIQUE CODE
                    </Paragraph>
                    <Paragraph
                      size='12px'
                      color="#000"
                      bold="normal"
                      margin="0 0 20px"
                    >
                      Get points when every friend signs up
                    </Paragraph>
                    <div className={classes.theSCLFlex}>
                      <div className={classes.theReferal}>
                        <Paragraph
                          size='12px'
                          color="#707070"
                          bold="bold"
                          center
                        >
                          {profileData.referral_code}
                        </Paragraph>
                      </div>
                      <div className={classes.theCopy}
                        onClick={() => {
                          navigator.clipboard.writeText(profileData.referral_code)
                          toast("Copy succesfully")
                        }}
                      >
                        <img src={CopyIcon} />
                      </div>
                    </div>
                    {/* <div className={classes.theSocialFlex}>
                      <div className={classes.theSocialIcon}>
                        <img src={WhatsAppIcon} />
                      </div>
                      <div className={classes.theSocialIcon}>
                        <img src={FacebookIcon} />
                      </div>
                      <div className={classes.theSocialIcon}>
                        <img src={TwitterIcon} />
                      </div>
                    </div> */}
                  </div>
                  <div className={classes.theSCRight}>
                    <div className={classes.theSCRImg}>
                      <img src={ReferralImg} />
                    </div>
                  </div>
                </div>
                <div className={classes.theForm}>

                  <Paragraph
                    size='18px'
                    color="#000"
                    bold="bold"
                    margin="0 0 20px"
                  >
                    Personal Information
                  </Paragraph>
                  <hr />
                  <div className={classes.theField}>
                    <FormInput
                      label="Name"
                      value={userData.name}
                      name="name"
                      onChange={handleChange}
                      placeholder="Name"
                      border="1px solid #000"
                      br="8px"
                    />
                  </div>
                  <div className={classes.theField}>
                    <FormInput
                      label="Email"
                      placeholder="helenregel@email.com"
                      border="1px solid #000"
                      bg="#ECECEC"
                      mb="30px"
                      br="8px"
                      disabled
                    />
                  </div>
                  <hr />
                  <div className={classes.theFButton}>
                    <Button label="Save Change"
                      onClick={() => handleSaveProfile()}
                      color="#fff"
                      bg="#D5AE35"
                      pd="10px 25px"
                      br="6px"
                    />
                  </div>
                </div>
              </div>
            </div>
          }
        />
      }
    </React.Fragment>
  );
}
