import React, {
  useState,
  useEffect
} from "react";
import {
  Container,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Grid,
  styled,
} from "@mui/material";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import NavbarDashboard from "../../components/NavbarDashboard/NavbarDashboard";
import Paragraph from "../../components/Typography/Paragraph";
import useStyles from "./styles";
import Button from "../../components/Button/Button";
import CopyIcon from '../../assets/images/copy.svg'
import FacebookIcon from '../../assets/images/facebook.svg'
import WhatsAppIcon from '../../assets/images/whatsapp.svg'
import TwitterIcon from '../../assets/images/twitter.svg'
import ReferralImg from '../../assets/images/referral.svg'
import DeleteIcon from '../../assets/images/bin.svg'
import TopUpIcon from "../../assets/images/topup.svg";
import WithdrawIcon from "../../assets/images/withdraw.svg";
import TransferIcon from "../../assets/images/transfer.svg";
import FormInput from "../../components/FormInput/FormInput";
import Loader from "react-js-loader";
import toast from 'react-simple-toasts';
import {
  get_wallet
} from "../../API/API";

export default function DashboardAgentWallet() {
  const classes = useStyles();
  const navigate = useNavigate();
  const [walletData, setWalletData] = useState(null)

  const getData = async () => {
    var resWallet = await get_wallet({})
    if (resWallet.status) {
      setWalletData(resWallet)
    }
  }

  useEffect(() => {
    getData();
  }, [])
  return (
    <React.Fragment>
      <NavbarDashboard
        children={
          <div>
            <div className={classes.theBGDashboard}>
              <div className={classes.theLevelCard}>
                <div className={classes.theLTFlex}>
                  <Paragraph
                    size='14px'
                    color="#000"
                    bold="normal"
                  >
                    Available Balance<span style={{ margin: "0 30px" }}>:</span><span style={{ fontWeight: "bold", fontSize: '24px' }}>RM {walletData != null && walletData.balance}</span>
                  </Paragraph>
                </div>

                <div className={classes.theLTFlex}>
                  <div className={classes.theHButton}>
                    <div className={classes.theHBIcon}>
                      <img src={TopUpIcon} />
                    </div>
                    <Paragraph
                      size='12px'
                      color="#fff"
                      bold="normal"
                      margin="0 0 0 10px"
                    >
                      Top Up
                    </Paragraph>
                  </div>
                  <div className={classes.theHButton}>
                    <div className={classes.theHBIcon}>
                      <img src={WithdrawIcon} />
                    </div>
                    <Paragraph
                      size='12px'
                      color="#fff"
                      bold="normal"
                      margin="0 0 0 10px"
                    >
                      Withdraw
                    </Paragraph>
                  </div>
                  <div className={classes.theHButton}>
                    <div className={classes.theHBIcon}>
                      <img src={TransferIcon} />
                    </div>
                    <Paragraph
                      size='12px'
                      color="#fff"
                      bold="normal"
                      margin="0 0 0 10px"
                    >
                      Transfer
                    </Paragraph>
                  </div>
                </div>
              </div>

              <div className={classes.theTableCard}>
                <div className={classes.theTFlex}>

                  <Paragraph
                    color="#402313"
                    size="14px"
                    bold="500"
                    margin="0px 0 15px"
                  >
                    Transaction History
                  </Paragraph>

                  {/* <Paragraph
                    color="#402313"
                    size="14px"
                    bold="500"
                    margin="0px 0 15px"
                    style={{ textDecoration: 'underline' }}
                    onClick={() => navigate('../dashboard-all-referral')}
                  >
                    view all &gt;&gt;
                  </Paragraph> */}
                </div>

                <div className={classes.theTable}>
                  <div className={classes.theTableHeader}>
                    <div style={{ width: "5%", wordBreak: "break-all" }}>
                      <Paragraph
                        color="#000"
                        size="12px"
                        bold="normal"
                        center
                      >
                        No.
                      </Paragraph>
                    </div>
                    <div style={{ width: "28%", wordBreak: "break-all" }}>
                      <Paragraph
                        color="#000"
                        size="12px"
                        bold="normal"
                        center
                      >
                        Date / Time
                      </Paragraph>
                    </div>
                    <div style={{ width: "28%", wordBreak: "break-all" }}>
                      <Paragraph
                        color="#000"
                        size="12px"
                        bold="normal"
                        center
                      >
                        Description
                      </Paragraph>
                    </div>
                    <div style={{ width: "12%", wordBreak: "break-all" }}>
                      <Paragraph
                        color="#000"
                        size="12px"
                        bold="normal"
                        center
                      >
                        Amount
                      </Paragraph>
                    </div>
                    <div style={{ width: "25%", wordBreak: "break-all" }}>
                      <Paragraph
                        color="#000"
                        size="12px"
                        bold="normal"
                        center
                      >
                        Status
                      </Paragraph>
                    </div>
                  </div>
                  {walletData != null &&
                    <React.Fragment>
                      {walletData.data.length == 0 ?
                        <Paragraph
                          color="#000"
                          size="12px"
                          bold="normal"
                          center
                        >
                          Status
                        </Paragraph>
                        :
                        walletData.data.map((res, index) =>
                          <div className={classes.theTableBodyCard}>
                            <div style={{ width: "5%", wordBreak: "break-all" }}>
                              <Paragraph
                                color="#000"
                                size="12px"
                                bold="normal"
                                center
                              >
                                {index + 1}
                              </Paragraph>
                            </div>
                            <div style={{ width: "28%", wordBreak: "break-all" }}>
                              <Paragraph
                                color="#000"
                                size="12px"
                                bold="normal"
                                center
                              >
                                {res.created_date}
                              </Paragraph>
                            </div>
                            <div style={{ width: "28%", wordBreak: "break-all" }}>
                              <Paragraph
                                color="#000"
                                size="12px"
                                bold="normal"
                                center
                              >
                                {res.remarks}
                              </Paragraph>
                            </div>
                            <div style={{ width: "12%", wordBreak: "break-all" }}>

                              {res.wallet_in > 0 ?
                                <Paragraph
                                  color="#000"
                                  size="12px"
                                  bold="normal"
                                  center
                                >
                                  + {res.wallet_in}
                                </Paragraph> :
                                <Paragraph
                                  color="#000"
                                  size="12px"
                                  bold="normal"
                                  center
                                >
                                  - {res.wallet_out}
                                </Paragraph>
                              }
                            </div>
                            <div style={{ width: "25%", wordBreak: "break-all" }}>
                              <Paragraph
                                color="#00BE00"
                                size="12px"
                                bold="normal"
                                center
                              >
                                Successful
                              </Paragraph>
                            </div>
                          </div>
                        )
                      }
                    </React.Fragment>
                  }
                  {/* <div className={classes.theTableBodyCard}>
                    <div style={{ width: "5%", wordBreak: "break-all" }}>
                      <Paragraph
                        color="#000"
                        size="12px"
                        bold="normal"
                        center
                      >
                        2
                      </Paragraph>
                    </div>
                    <div style={{ width: "28%", wordBreak: "break-all" }}>
                      <Paragraph
                        color="#000"
                        size="12px"
                        bold="normal"
                        center
                      >
                        03 Nov 2022 13:06:41
                      </Paragraph>
                    </div>
                    <div style={{ width: "28%", wordBreak: "break-all" }}>
                      <Paragraph
                        color="#000"
                        size="12px"
                        bold="normal"
                        center
                      >
                        transfer money
                      </Paragraph>
                    </div>
                    <div style={{ width: "12%", wordBreak: "break-all" }}>
                      <Paragraph
                        color="#000"
                        size="12px"
                        bold="normal"
                        center
                      >
                        - 000
                      </Paragraph>
                    </div>
                    <div style={{ width: "25%", wordBreak: "break-all" }}>
                      <Paragraph
                        color="#E4AD04"
                        size="12px"
                        bold="normal"
                        center
                      >
                        Pending
                      </Paragraph>
                    </div>
                  </div>
                  <div className={classes.theTableBodyCard}>
                    <div style={{ width: "5%", wordBreak: "break-all" }}>
                      <Paragraph
                        color="#000"
                        size="12px"
                        bold="normal"
                        center
                      >
                        3
                      </Paragraph>
                    </div>
                    <div style={{ width: "28%", wordBreak: "break-all" }}>
                      <Paragraph
                        color="#000"
                        size="12px"
                        bold="normal"
                        center
                      >
                        03 Nov 2022 13:06:41
                      </Paragraph>
                    </div>
                    <div style={{ width: "28%", wordBreak: "break-all" }}>
                      <Paragraph
                        color="#000"
                        size="12px"
                        bold="normal"
                        center
                      >
                        new member added
                      </Paragraph>
                    </div>
                    <div style={{ width: "12%", wordBreak: "break-all" }}>
                      <Paragraph
                        color="#000"
                        size="12px"
                        bold="normal"
                        center
                      >
                        + 000
                      </Paragraph>
                    </div>
                    <div style={{ width: "25%", wordBreak: "break-all" }}>
                      <Paragraph
                        color="#BE0000"
                        size="12px"
                        bold="normal"
                        center
                      >
                        Failed
                      </Paragraph>
                    </div>
                  </div>
                  <div className={classes.theTableBodyCard}>
                    <div style={{ width: "5%", wordBreak: "break-all" }}>
                      <Paragraph
                        color="#000"
                        size="12px"
                        bold="normal"
                        center
                      >
                        4
                      </Paragraph>
                    </div>
                    <div style={{ width: "28%", wordBreak: "break-all" }}>
                      <Paragraph
                        color="#000"
                        size="12px"
                        bold="normal"
                        center
                      >
                        03 Nov 2022 13:06:41
                      </Paragraph>
                    </div>
                    <div style={{ width: "28%", wordBreak: "break-all" }}>
                      <Paragraph
                        color="#000"
                        size="12px"
                        bold="normal"
                        center
                      >
                        top up
                      </Paragraph>
                    </div>
                    <div style={{ width: "12%", wordBreak: "break-all" }}>
                      <Paragraph
                        color="#000"
                        size="12px"
                        bold="normal"
                        center
                      >
                        + 000
                      </Paragraph>
                    </div>
                    <div style={{ width: "25%", wordBreak: "break-all" }}>
                      <Paragraph
                        color="#00BE00"
                        size="12px"
                        bold="normal"
                        center
                      >
                        Successful
                      </Paragraph>
                    </div>
                  </div>
                  <div className={classes.theTableBodyCard}>
                    <div style={{ width: "5%", wordBreak: "break-all" }}>
                      <Paragraph
                        color="#000"
                        size="12px"
                        bold="normal"
                        center
                      >
                        5
                      </Paragraph>
                    </div>
                    <div style={{ width: "28%", wordBreak: "break-all" }}>
                      <Paragraph
                        color="#000"
                        size="12px"
                        bold="normal"
                        center
                      >
                        03 Nov 2022 13:06:41
                      </Paragraph>
                    </div>
                    <div style={{ width: "28%", wordBreak: "break-all" }}>
                      <Paragraph
                        color="#000"
                        size="12px"
                        bold="normal"
                        center
                      >
                        withdrawal
                      </Paragraph>
                    </div>
                    <div style={{ width: "12%", wordBreak: "break-all" }}>
                      <Paragraph
                        color="#000"
                        size="12px"
                        bold="normal"
                        center
                      >
                        - 000
                      </Paragraph>
                    </div>
                    <div style={{ width: "25%", wordBreak: "break-all" }}>
                      <Paragraph
                        color="#E4AD04"
                        size="12px"
                        bold="normal"
                        center
                      >
                        Pending
                      </Paragraph>
                    </div>
                  </div> */}
                </div>

              </div>
            </div>
          </div>
        }
      />
    </React.Fragment >
  );
}
