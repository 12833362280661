import React from "react";
import Slider from "react-slick";
import useStyles from "./styles";
import NavbarCentered from "../../components/NavbarCentered/NavbarCentered";
import Section from "../../components/Section/Section";
import Footer from "../../components/Footer/Footer";
import FormInputNoLabel from "../../components/FormInputNoLabel/FormInputNoLabel";

import logo from "../../assets/images/logobig.png";

import prod001 from "../../assets/images/prod001.png";
import icon001 from "../../assets/images/icon001.png";
import icon002 from "../../assets/images/icon002.png";
import icon003 from "../../assets/images/icon003.png";
import icon004 from "../../assets/images/icon004.png";

import cat001 from "../../assets/images/cat001.png";
import cat002 from "../../assets/images/cat002.png";

import bannercontact from "../../assets/images/bannerContact.png";

import paymentmethod from "../../assets/images/payment.png";

import Paragraph from "../../components/Typography/Paragraph";
import Button from "../../components/Button/Button";
import {
  Container,
  Grid,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@mui/material";
import { ArrowDropDown } from "@mui/icons-material";

export default function FAQ() {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState("panel1");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  return (
    <React.Fragment>
      <NavbarCentered bg="#FFF" />
      <div className={classes.theBG_}>
        <Paragraph color="#fff" bold="700" center size="34px">
          WOODPECKER
        </Paragraph>
      </div>

      <Container>
        <Section pd="4rem 0 2rem 0">
          <div>
            <Paragraph bold="700" margin="0 0 1rem 0" size="24px">
              Products
            </Paragraph>
            <Accordion
              expanded={expanded === "panel1"}
              onChange={handleChange("panel1")}
            >
              <AccordionSummary
                expandIcon={<ArrowDropDown style={{ color: "#402313" }} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Paragraph size="16px" color="">
                  Li Europan lingues es membres del sam familie.
                </Paragraph>
              </AccordionSummary>
              <AccordionDetails>
                <Paragraph margin="0 0 1rem 0" size="14px">
                  Li Europan lingues es membres del sam familie. Lor separat
                  existentie es un myth. Por scientie, musica, sport etc, litot
                  Europa usa li sam vocabular. Li lingues differe solmen in li
                  grammatica, li pronunciation e li plu commun vocabules.
                  Omnicos
                </Paragraph>
              </AccordionDetails>
            </Accordion>
          </div>
          <br />
          <br />
          <div>
            <Paragraph bold="700" margin="0 0 1rem 0" size="24px">
              Order & Payment
            </Paragraph>
            <Accordion
              expanded={expanded === "panel2"}
              onChange={handleChange("panel2")}
            >
              <AccordionSummary
                expandIcon={<ArrowDropDown style={{ color: "#402313" }} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Paragraph size="16px" color="">
                  Li Europan lingues es membres del sam familie.
                </Paragraph>
              </AccordionSummary>
              <AccordionDetails>
                <Paragraph margin="0 0 1rem 0" size="14px">
                  Li Europan lingues es membres del sam familie. Lor separat
                  existentie es un myth. Por scientie, musica, sport etc, litot
                  Europa usa li sam vocabular. Li lingues differe solmen in li
                  grammatica, li pronunciation e li plu commun vocabules.
                  Omnicos
                </Paragraph>
              </AccordionDetails>
            </Accordion>
          </div>
          <br />
          <br />
          <div>
            <Paragraph bold="700" margin="0 0 1rem 0" size="24px">
              Shipping & Delivery
            </Paragraph>
            <Accordion
              expanded={expanded === "panel3"}
              onChange={handleChange("panel3")}
            >
              <AccordionSummary
                expandIcon={<ArrowDropDown style={{ color: "#402313" }} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Paragraph size="16px" color="">
                  Li Europan lingues es membres del sam familie.
                </Paragraph>
              </AccordionSummary>
              <AccordionDetails>
                <Paragraph margin="0 0 1rem 0" size="14px">
                  Li Europan lingues es membres del sam familie. Lor separat
                  existentie es un myth. Por scientie, musica, sport etc, litot
                  Europa usa li sam vocabular. Li lingues differe solmen in li
                  grammatica, li pronunciation e li plu commun vocabules.
                  Omnicos
                </Paragraph>
              </AccordionDetails>
            </Accordion>
          </div>
          <br />
          <br />
        </Section>
      </Container>

      <Footer />
    </React.Fragment>
  );
}
