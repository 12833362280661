// import axios from "axios";

// const host = 'http://178.128.120.86/woodpecker/admin/';>
const host = "https://woodpeckernaturalconcept.com.my/admin/";
const access_endpoint_link = host + "API/api_function/";

const format_request = (postparam) => {
    //   if (postparam) {
    //     let token = window.localStorage.getItem("token");
    //     if (token) {
    //       postparam["token"] = token;
    //     }
    //   }
    let token = 0;
    let token_res = window.localStorage.getItem("token");
    if (token_res) {
        token = token_res;
    }
    if (!postparam['token']) {
        postparam['token'] = token;
    }

    let body = "";
    for (let k in postparam) {
        body += encodeURI(k) + "=" + encodeURI(postparam[k]) + "&";
    }
    var postOptions = {
        method: "POST",
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            // 'authorization': `Bearer ${token}`
        },
        body: body,
    };
    return postOptions;
};

const api_post = async (postparam = {}, end_point) => {
    var response = await fetch(
        access_endpoint_link + end_point,
        format_request(postparam)
    );

    var json = await response.json();
    if (json.status) {
        return json;
    } else {
        // if (json.message == "Please login again") {
        //     window.localStorage.clear();
        //     //alert(json.message);
        //     window.location.href = "/login";
        //     return json;
        // } else {
        //     //   alert(json.message);
        // }
        return json;
    }
}

export const formatNewJson = (filter, origininalState) => {
    filter.map((row, index) => (
        delete origininalState[row]
    ));
    return origininalState;
}

export const get_banner = async (postparam) => {
    return api_post(postparam, 'get_banner');
};

export const get_category = async (postparam) => {
    return api_post(postparam, 'get_category');
};

export const contact_us = async (postparam) => {
    return api_post(postparam, 'contact_us');
};

export const get_random_product = async (postparam) => {
    return api_post(postparam, 'get_random_product');
};

export const newsletter = async (postparam) => {
    return api_post(postparam, 'newsletter');
};

export const get_product_by_category = async (postparam) => {
    return api_post(postparam, 'get_product_by_category');
};

export const get_product_detail = async (postparam) => {
    return api_post(postparam, 'get_product_detail');
};

export const login = async (postparam) => {
    return api_post(postparam, 'login');
};

export const register = async (postparam) => {
    return api_post(postparam, 'register');
};

export const get_profile = async (postparam) => {
    return api_post(postparam, 'get_profile');
};

export const submit_order = async (postparam) => {
    return api_post(postparam, 'submit_order');
};

export const get_wallet = async (postparam) => {
    return api_post(postparam, 'get_wallet');
};

export const edit_name = async (postparam) => {
    return api_post(postparam, 'edit_name');
};

export const get_orders_history = async (postparam) => {
    return api_post(postparam, 'get_orders_history');
};

export const get_orders_detail = async (postparam) => {
    return api_post(postparam, 'get_orders_detail');
};

export const change_password = async (postparam) => {
    return api_post(postparam, 'change_password');
};

export const get_member = async (postparam) => {
    return api_post(postparam, 'get_member');
};

export const get_footer_product = async (postparam) => {
    return api_post(postparam, 'get_footer_product');
};

// export const get_upload_path = async (postparam) => {
//     let res = await axios.post(access_endpoint_link + "get_upload_path", postparam);
//     if (res) {
//         return res.data;
//     } else {
//         alert(res.message);
//         // return false;
//     }
// };