import styled from "styled-components/macro";
import { makeStyles } from "@mui/styles";
import { md } from "../../styles/responsive";
import { FormControl, TextField } from "@mui/material";

// import backgrounddash from "../../assets/images/backgrounddash.png";

export const FormInputSelect = styled(FormControl)`
  display: block;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #28329b !important;
  border-radius: 6px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  margin-bottom: 1rem;
  .MuiInput-underline:before {
    border-bottom: none !important;
  }
  .MuiInput-underline:after {
    border-bottom: none !important;
  }
  .MuiInputBase-root {
    width: 100%;
  }
  .MuiInputBase-root {
    font-weight: 500 !important;
  }
  .MuiInput-underline {
    &:before {
      position: relative !important;
    }
  }
`;

const config = {
  theCardChildren: {
    position: "relative",
    background: "#FFB90F",
    padding: "1rem 2rem",
    borderRadius: "10px",
    marginBottom: "2rem",
    overflow: "hidden",
  },
  theFilter: {
    display: 'inline-flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    width: '100%',

    ...md("md", {
      justifyContent: 'flex-start',
    }),
  },
  theFCardActive: {
    borderBottom: "3px solid #D5AE35",
    margin: "0 15px 20px 0",

    ...md("sm", {
      margin: "0 35px 20px 0",
    }),

    ...md("md", {
      margin: "0 70px 20px 0",
    }),
  },
  theFCard: {
    borderBottom: "3px solid transparent",
    margin: "0 15px 20px 0",

    ...md("sm", {
      margin: "0 35px 20px 0",
    }),

    ...md("md", {
      margin: "0 70px 20px 0",
    }),
  },

  theBGDashboard: {
    position: "relative",
    width: "100%",
    height: "auto",
    // backgroundImage: "url(" + backgrounddash + ")",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    padding: "0 0 46px",

    ...md("sm", {
      height: "auto",
    }),

    ...md("md", {
      // height: "100vh",
    }),
  },

  theLTFlex: {
    display: 'flex',
    flexDirection: 'column',

    ...md("sm", {
      flexDirection: 'row',
    }),
  },
  theOrderCard: {
    width: "100%",
    backgroundColor: "#fff",
    boxShadow: "0px 3px 6px #00000029",
    padding: "12px 0",
    // display: 'flex',
    // justifyContent: "space-between",
    // alignItems: 'flex-start',
    margin: "0 0 20px",
    // flexDirection: 'column',

    ...md("md", {
      width: "85%",
      // flexDirection: 'row',
      padding: "12px 0",
    }),
  },
  theOCFlex: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    width: "100%",
    margin: "0 0 25px"
  },
  theOCFlex2: {
    display: 'flex',
    justifyContent: 'space-between',
    width: "100%",
    flexDirection: "column",
    alignItems: 'center',

    ...md("sm", {
      padding: "12px 0",
      flexDirection: "row",
      alignItems: 'flex-end',
    }),
  },
  theSCLeft: {

  },
  theSCLFlex: {
    display: 'flex',
    alignItems: 'center',
  },

  theField: {
    width: "100%",

    ...md("md", {
      width: "50%",
    }),
  },
  theFButton: {
    width: "100%",
    margin: "30px auto 0",

    ...md("md", {
      width: "max-content",
    }),
  }
};

export default makeStyles(config);
