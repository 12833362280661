import React from "react";
import {
  Container,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Grid,
  styled,
} from "@mui/material";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import NavbarDashboard from "../../components/NavbarDashboard/NavbarDashboard";
import Paragraph from "../../components/Typography/Paragraph";
import useStyles from "./styles";
import Button from "../../components/Button/Button";
import CopyIcon from '../../assets/images/copy.svg'
import FacebookIcon from '../../assets/images/facebook.svg'
import WhatsAppIcon from '../../assets/images/whatsapp.svg'
import TwitterIcon from '../../assets/images/twitter.svg'
import ReferralImg from '../../assets/images/referral.svg'
import DeleteIcon from '../../assets/images/bin.svg'
import { WhatsApp } from "@mui/icons-material";
import FormInput from "../../components/FormInput/FormInput";
import NavbarDashboardAgent from "../../components/NavbarDashboardAgent/NavbarDashboardAgent";

export default function DashboardAgentReferral() {
  const classes = useStyles();
  const navigate = useNavigate();
  return (
    <React.Fragment>
      <NavbarDashboardAgent
        children={
          <div>
            <div className={classes.theBGDashboard}>
              <div className={classes.theLevelCard}>
                <div className={classes.theLTFlex}>
                  <Paragraph
                    size='14px'
                    color="#000"
                    bold="normal"
                  >
                    Level<span style={{ margin: "0 30px" }}>:</span><span style={{ fontWeight: "bold" }}>Agent</span>
                  </Paragraph>
                </div>

              </div>
              <div className={classes.theShareCard}>
                <div className={classes.theSCLeft}>
                  <Paragraph
                    size='14px'
                    color="#402313"
                    bold="bold"
                    margin="0 0 4px"
                  >
                    SHARE YOUR UNIQUE CODE
                  </Paragraph>
                  <Paragraph
                    size='12px'
                    color="#000"
                    bold="normal"
                    margin="0 0 20px"
                  >
                    Get points when every friend signs up
                  </Paragraph>
                  <div className={classes.theSCLFlex}>
                    <div className={classes.theReferal}>
                      <Paragraph
                        size='12px'
                        color="#707070"
                        bold="bold"
                        center
                      >
                        ACF1510QW
                      </Paragraph>
                    </div>
                    <div className={classes.theCopy}>
                      <img src={CopyIcon} />
                    </div>
                  </div>
                  <div className={classes.theSocialFlex}>
                    <div className={classes.theSocialIcon}>
                      <img src={WhatsAppIcon} />
                    </div>
                    <div className={classes.theSocialIcon}>
                      <img src={FacebookIcon} />
                    </div>
                    <div className={classes.theSocialIcon}>
                      <img src={TwitterIcon} />
                    </div>
                  </div>
                </div>
                <div className={classes.theSCRight}>
                  <div className={classes.theSCRImg}>
                    <img src={ReferralImg} />
                  </div>
                </div>
              </div>
              <div className={classes.theTableCard}>
                <div className={classes.theTFlex}>

                  <Paragraph
                    color="#402313"
                    size="14px"
                    bold="500"
                    margin="0px 0 15px"
                  >
                    My Referrals
                  </Paragraph>

                  <Paragraph
                    color="#402313"
                    size="14px"
                    bold="500"
                    margin="0px 0 15px"
                    style={{ textDecoration: 'underline' }}
                    onClick={() => navigate('../dashboard-all-referral')}
                  >
                    view all &gt;&gt;
                  </Paragraph>
                </div>

                <div className={classes.theTable}>
                  <div className={classes.theTableHeader}>
                    <div style={{ width: "5%" }}>
                      <Paragraph
                        color="#000"
                        size="12px"
                        bold="normal"
                        center
                      >
                        No.
                      </Paragraph>
                    </div>
                    <div style={{ width: "28%" }}>
                      <Paragraph
                        color="#000"
                        size="12px"
                        bold="normal"
                        center
                      >
                        Name
                      </Paragraph>
                    </div>
                    <div style={{ width: "28%" }}>
                      <Paragraph
                        color="#000"
                        size="12px"
                        bold="normal"
                        center
                      >
                        Email
                      </Paragraph>
                    </div>
                    <div style={{ width: "25%" }}>
                      <Paragraph
                        color="#000"
                        size="12px"
                        bold="normal"
                        center
                      >
                        Contact Number
                      </Paragraph>
                    </div>
                    <div style={{ width: "10%" }}>
                    </div>
                  </div>
                  <div className={classes.theTableBodyCard}>
                    <div style={{ width: "5%", wordBreak: "break-all" }}>
                      <Paragraph
                        color="#000"
                        size="12px"
                        bold="normal"
                        center
                      >
                        1
                      </Paragraph>
                    </div>
                    <div style={{ width: "28%", wordBreak: "break-all" }}>
                      <Paragraph
                        color="#000"
                        size="12px"
                        bold="normal"
                        center
                      >
                        Roger Derrick
                      </Paragraph>
                    </div>
                    <div style={{ width: "28%", wordBreak: "break-all" }}>
                      <Paragraph
                        color="#000"
                        size="12px"
                        bold="normal"
                        center
                      >
                        username@email.com
                      </Paragraph>
                    </div>
                    <div style={{ width: "25%", wordBreak: "break-all" }}>
                      <Paragraph
                        color="#000"
                        size="12px"
                        bold="normal"
                        center
                      >
                        +60 0000000
                      </Paragraph>
                    </div>
                    <div style={{ width: "10%", wordBreak: "break-all" }}>
                      <div className={classes.theDeleteIcon}>
                        <img src={DeleteIcon} />
                      </div>
                    </div>
                  </div>
                  <div className={classes.theTableBodyCard}>
                    <div style={{ width: "5%", wordBreak: "break-all" }}>
                      <Paragraph
                        color="#000"
                        size="12px"
                        bold="normal"
                        center
                      >
                        2
                      </Paragraph>
                    </div>
                    <div style={{ width: "28%", wordBreak: "break-all" }}>
                      <Paragraph
                        color="#000"
                        size="12px"
                        bold="normal"
                        center
                      >
                        Roger Derrick
                      </Paragraph>
                    </div>
                    <div style={{ width: "28%", wordBreak: "break-all" }}>
                      <Paragraph
                        color="#000"
                        size="12px"
                        bold="normal"
                        center
                      >
                        username@email.com
                      </Paragraph>
                    </div>
                    <div style={{ width: "25%", wordBreak: "break-all" }}>
                      <Paragraph
                        color="#000"
                        size="12px"
                        bold="normal"
                        center
                      >
                        +60 0000000
                      </Paragraph>
                    </div>
                    <div style={{ width: "10%" }}>
                      <div className={classes.theDeleteIcon}>
                        <img src={DeleteIcon} />
                      </div>
                    </div>
                  </div>
                  <div className={classes.theTableBodyCard}>
                    <div style={{ width: "5%", wordBreak: "break-all" }}>
                      <Paragraph
                        color="#000"
                        size="12px"
                        bold="normal"
                        center
                      >
                        3
                      </Paragraph>
                    </div>
                    <div style={{ width: "28%", wordBreak: "break-all" }}>
                      <Paragraph
                        color="#000"
                        size="12px"
                        bold="normal"
                        center
                      >
                        Roger Derrick
                      </Paragraph>
                    </div>
                    <div style={{ width: "28%", wordBreak: "break-all" }}>
                      <Paragraph
                        color="#000"
                        size="12px"
                        bold="normal"
                        center
                      >
                        username@email.com
                      </Paragraph>
                    </div>
                    <div style={{ width: "25%", wordBreak: "break-all" }}>
                      <Paragraph
                        color="#000"
                        size="12px"
                        bold="normal"
                        center
                      >
                        +60 0000000
                      </Paragraph>
                    </div>
                    <div style={{ width: "10%" }}>
                      <div className={classes.theDeleteIcon}>
                        <img src={DeleteIcon} />
                      </div>
                    </div>
                  </div>
                  <div className={classes.theTableBodyCard}>
                    <div style={{ width: "5%", wordBreak: "break-all" }}>
                      <Paragraph
                        color="#000"
                        size="12px"
                        bold="normal"
                        center
                      >
                        4
                      </Paragraph>
                    </div>
                    <div style={{ width: "28%", wordBreak: "break-all" }}>
                      <Paragraph
                        color="#000"
                        size="12px"
                        bold="normal"
                        center
                      >
                        Roger Derrick
                      </Paragraph>
                    </div>
                    <div style={{ width: "28%", wordBreak: "break-all" }}>
                      <Paragraph
                        color="#000"
                        size="12px"
                        bold="normal"
                        center
                      >
                        username@email.com
                      </Paragraph>
                    </div>
                    <div style={{ width: "25%", wordBreak: "break-all" }}>
                      <Paragraph
                        color="#000"
                        size="12px"
                        bold="normal"
                        center
                      >
                        +60 0000000
                      </Paragraph>
                    </div>
                    <div style={{ width: "10%" }}>
                      <div className={classes.theDeleteIcon}>
                        <img src={DeleteIcon} />
                      </div>
                    </div>
                  </div>
                  <div className={classes.theTableBodyCard}>
                    <div style={{ width: "5%", wordBreak: "break-all" }}>
                      <Paragraph
                        color="#000"
                        size="12px"
                        bold="normal"
                        center
                      >
                        5
                      </Paragraph>
                    </div>
                    <div style={{ width: "28%", wordBreak: "break-all" }}>
                      <Paragraph
                        color="#000"
                        size="12px"
                        bold="normal"
                        center
                      >
                        Roger Derrick
                      </Paragraph>
                    </div>
                    <div style={{ width: "28%", wordBreak: "break-all" }}>
                      <Paragraph
                        color="#000"
                        size="12px"
                        bold="normal"
                        center
                      >
                        username@email.com
                      </Paragraph>
                    </div>
                    <div style={{ width: "25%", wordBreak: "break-all" }}>
                      <Paragraph
                        color="#000"
                        size="12px"
                        bold="normal"
                        center
                      >
                        +60 0000000
                      </Paragraph>
                    </div>
                    <div style={{ width: "10%" }}>
                      <div className={classes.theDeleteIcon}>
                        <img src={DeleteIcon} />
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        }
      />
    </React.Fragment >
  );
}
