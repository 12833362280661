import styled from "styled-components";
import { makeStyles } from "@mui/styles";
import { TextField } from "@mui/material";

export const FormGroup = styled.div`
  margin-bottom: 1rem;
`;

export const TextInput = styled(TextField)`
  background: ${(props) => props.bg};
  //   font-size: ${(props) => (props.size ? props.size : "1rem")};

  .MuiInputBase-input::placeholder {
    color: ${(props) => props.placeholdercolor};
  }

  .MuiOutlinedInput-root {
    border-radius: ${(props) => (props.br ? props.br : ".25rem")};
    font-weight: ${(props) => props.bold};
    // font-size: ${(props) => props.size};
  }

  .MuiOutlinedInput-notchedOutline {
    border: ${(props) => (props.border ? props.border : "1px solid #CED4DA")};
  }

  .MuiOutlinedInput-input {
    color: ${(props) => props.textcolor};
    // padding: ${(props) => (props.pd ? props.pd : "16.5px 14px")};
    text-align: ${(props) => (props.center ? "center" : "left")};
    // padding: ${(props) => (props.pd ? props.pd : ".375rem .75rem !important")};
    padding: ${(props) => (props.pd ? props.pd : "10px 12px")};
  }
`;

export const Span = styled.span`
  visibility: ${(props) => (props.required ? "visible" : "hidden")};
`;

export const config = {
  root: {
    display: "flex",
    alignItems: "center",
  },
};

export default makeStyles(config);
