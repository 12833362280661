import React, { useEffect, useState } from "react";
import { Drawer, List, ListItem, ListItemText } from "@mui/material";
import useStyles, {
  NavbarSidebarList,
  NavbarSidebarListLogo,
  CloseButtonBox,
} from "./styles";
import { useNavigate, useLocation } from "react-router-dom";
import { Close } from "@mui/icons-material";

import logo from "../../assets/images/logo.png";

const SidebarMenu = ({ open, onClose, handleDrawerToggle }) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const location = useLocation();
  const sideDrawerList = (anchor) => (
    <div
      className={classes.theSideBar}
      role="presentation"
      onClick={() => handleDrawerToggle(anchor, false)}
      onKeyDown={() => handleDrawerToggle(anchor, false)}
    ></div>
  );

  // TOKEN

  const [token, setToken] = useState([]);

  useEffect(() => {
    let token = window.localStorage.getItem("token");
    setToken(token || "");
    console.log("CURRENT TOKEN", token);
  }, []);

  // HANDLE LLOGOUT

  const handleLogout = () => {
    window.localStorage.removeItem("token");
    navigate("/");
    window.location.reload();
  };

  // LANGUAGE

  // const [languageE1, setLanguageE1] = useState(null);
  // const languageopen = Boolean(languageE1);
  // const handleLanguage = (event) => {
  //   setLanguageE1(event.currentTarget);
  // };
  // const handleLanguageClose = () => {
  //   setLanguageE1(null);
  // };

  // const [langJson, setLangJson, removeLangJson] = useLocalStorage(
  //   "langJson",
  //   window.localStorage.getItem("language")
  // );

  // const [lang, setLang] = useState(
  //   window.localStorage.getItem("language")
  // );

  // const changeLang = (value) => {
  //   setLang(value.id);
  //   window.localStorage.setItem("language", value.id);
  //   window.location.reload();
  // };

  // NAVBAR ITEM

  const NAVBARLIST = [
    {
      title: "HOME",
      link: "",
    },
    {
      title: "PILLOW",
      link: "pillow",
    },
    {
      title: "MATTRESS",
      link: "mattress",
    },
    {
      title: "SALE",
      link: "sale",
    },
    {
      title: "WHY WOODPECKER",
      link: "about",
    },
    {
      title: "CONTACT US",
      link: "contact",
    },
  ];


  return (
    <>
      <Drawer anchor="left" open={open} onClose={onClose}>
        {sideDrawerList("left")}
        <List component="nav">
          <NavbarSidebarList>
            <NavbarSidebarListLogo href="/">
              <img src={logo} />
            </NavbarSidebarListLogo>
            <CloseButtonBox>
              <a onClick={handleDrawerToggle}>
                <Close sx={{ color: "#D5AE35" }} />
              </a>
            </CloseButtonBox>

            <br />
            {NAVBARLIST.map((item) => (
              <ListItem>
                <a
                  href={"/" + item.link}
                  style={{
                    fontSize: "18px",
                    color:
                      location.pathname.replace(/[^A-Za-z]+/g, "") === item.link
                        ? "#D5AE35"
                        : "#000",
                    fontWeight:
                      location.pathname.replace(/[^A-Za-z]+/g, "") === item.link
                        ? "700"
                        : "normal",
                  }}
                >
                  {item.title}
                </a>
              </ListItem>
            ))}
          </NavbarSidebarList>
        </List>
      </Drawer>
    </>
  );
};

export default SidebarMenu;
