import { ArrowBackIos, Close } from "@mui/icons-material";
import {
  Container,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Grid
} from "@mui/material";
import React, {
  useState,
  useEffect
} from "react";
import Footer from "../../components/Footer/Footer";
import { Link, useParams } from "react-router-dom";
import FormInput from "../../components/FormInput/FormInput";
import NavbarCentered from "../../components/NavbarCentered/NavbarCentered";
import Section from "../../components/Section/Section";
import Paragraph from "../../components/Typography/Paragraph";
import useStyles from "./styles";

import prod001 from "../../assets/images/prod001.png";
import Button from "../../components/Button/Button";
import { width } from "@mui/system";
// import useCart from "../../hooks/useCart";
import Loader from "react-js-loader";
import toast from 'react-simple-toasts';
import {
  get_orders_detail
} from "../../API/API";

export default function OrderDetail() {
  const classes = useStyles();
  const params = useParams();

  const [orderData, setOrderData] = useState(null)

  const getData = async () => {
    var resOrder = await get_orders_detail({ orders_id: params.orders_id })
    if (resOrder.status) {
      setOrderData(resOrder.data)
    }
  }

  useEffect(() => {
    getData();
  }, [])

  return (
    <React.Fragment>
      <NavbarCentered bg="#FFF" />
      {orderData != null &&
        <Container>
          <Section pd="3rem 0">
            <Paragraph
              size="12px"
              color="#000"
              margin="0 0 16px"
            >
              User Profile &gt;My Orders &gt; Order Detail
            </Paragraph>
            {/* <div className={classes.theHeadCard}>
            <div style={{ width: "24%", wordBreak: "break-all" }}>
              <Paragraph
                size="16px"
                color="#fff"
                bold="400"
                center
              >
                Sold & Shipped by
              </Paragraph>
            </div>
            <div style={{ width: "24%", wordBreak: "break-all" }}>
              <Paragraph
                size="16px"
                color="#fff"
                bold="400"
                center
              >
                Tracking No
              </Paragraph>
            </div>
            <div style={{ width: "24%", wordBreak: "break-all" }}>
              <Paragraph
                size="16px"
                color="#fff"
                bold="400"
                center
              >
                Courier
              </Paragraph>
            </div>
            <div style={{ width: "24%", wordBreak: "break-all" }}>
              <Paragraph
                size="16px"
                color="#fff"
                bold="400"
                center
              >
                Order Status
              </Paragraph>
            </div>
          </div>
          <div className={classes.theHeadBody}>
            <div style={{ width: "24%", wordBreak: "break-all" }}>
              <Paragraph
                size="16px"
                color="#000"
                bold="400"
                center
              >
                Woodpecker
              </Paragraph>
            </div>
            <div style={{ width: "24%", wordBreak: "break-all" }}>
              <Paragraph
                size="16px"
                color="#000"
                bold="400"
                center
              >
                20221103123456789
              </Paragraph>
            </div>
            <div style={{ width: "24%", wordBreak: "break-all" }}>
              <Paragraph
                size="16px"
                color="#000"
                bold="400"
                center
              >
                POS LAJU
              </Paragraph>
            </div>
            <div style={{ width: "24%", wordBreak: "break-all" }}>
              <Paragraph
                size="16px"
                color="#0F4D72"
                bold="400"
                center
              >
                SHIPPED
              </Paragraph>
            </div>
          </div> */}

            <div className={classes.theDetailCard}>
              <div className={classes.theDCHead}>
                <Paragraph
                  size="16px"
                  color="#fff"
                >
                  Order Details
                </Paragraph>
              </div>
              <div>
                <TableContainer>
                  <Table>
                    <TableHead className={classes.theHead}>
                      <TableRow>
                        <TableCell>Product</TableCell>
                        <TableCell sx={{ textAlign: "center" }}>Price</TableCell>
                        <TableCell sx={{ textAlign: "center" }}>Quatity</TableCell>
                        <TableCell sx={{ textAlign: "center" }}>Total</TableCell>
                      </TableRow>
                    </TableHead>

                    {orderData.product.map((res) =>
                      <TableBody>
                        <TableRow>
                          <TableCell>
                            <div
                              style={{ display: "flex" }}
                              className={classes.theWidth}
                            >
                              <div className={classes.theIMG}>
                                <img src={res.image} />
                              </div>
                              <div style={{ marginLeft: "1rem" }}>
                                <Paragraph
                                  margin="0 0 1rem 0"
                                  size="18px"
                                  className="theEllipsis"
                                >
                                  {" "}
                                  {res.name}
                                </Paragraph>
                              </div>
                            </div>
                          </TableCell>
                          <TableCell>
                            <div className={classes.theWidth2}>
                              <Paragraph center>RM {res.price}</Paragraph>
                            </div>
                          </TableCell>
                          <TableCell>
                            <div>
                              <Paragraph center>{res.quantity}</Paragraph>
                            </div>
                          </TableCell>
                          <TableCell>
                            {" "}
                            <div className={classes.theWidth2}>
                              {" "}
                              <Paragraph center>RM {res.total_price}</Paragraph>
                            </div>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>


                {/* <div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: 'flex-end',
                    padding: "10px 16px 0",
                    margin: "0 0 0 auto",
                    width:"25%"
                  }}
                >
                  <Paragraph
                    margin="0 0 1rem 0"
                    size="18px"
                    className="theEllipsis"
                  >
                    Sub Total
                  </Paragraph>
                  <Paragraph
                    margin="0 0 1rem 0"
                    size="18px"
                    className="theEllipsis"
                  >
                    RM 0000.00
                  </Paragraph>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: 'flex-end',
                    padding: "0 16px",
                    margin: "0 0 0 auto",
                    width:"25%"
                  }}
                >
                  <Paragraph
                    margin="0 0 1rem 0"
                    size="18px"
                    className="theEllipsis"
                  >
                    Shipping Fee
                  </Paragraph>
                  <Paragraph
                    margin="0 0 1rem 0"
                    size="18px"
                    className="theEllipsis"
                  >
                    RM 0000.00
                  </Paragraph>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: 'flex-end',
                    padding: "0 16px",
                    margin: "0 0 0 auto",
                    width:"25%"
                  }}
                >
                  <Paragraph
                    margin="0 0 1rem 0"
                    size="18px"
                    className="theEllipsis"
                  >
                    Total
                  </Paragraph>
                  <Paragraph
                    margin="0 0 1rem 0"
                    size="18px"
                    className="theEllipsis"
                  >
                    RM 0000.00
                  </Paragraph>
                </div>
              </div> */}
              </div>
            </div>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={6}>

                <div className={classes.theDetailCard}>
                  <div className={classes.theDCHead}>
                    <Paragraph
                      size="16px"
                      color="#fff"
                    >
                      Payment Information
                    </Paragraph>
                  </div>
                  <div style={{ padding: "17px 22px" }}>
                    <Paragraph
                      size="14px"
                      color="#000000"
                    >
                      Sub Total
                    </Paragraph>
                    <hr style={{ borderColor: "#707070" }} />
                    <div style={{ display: "flex", justifyContent: "space-between" }}>

                      <Paragraph
                        size="14px"
                        color="#707070"
                        margin="10px 0 18px"
                      >
                        Woodpecker
                      </Paragraph>

                      <Paragraph
                        size="14px"
                        color="#707070"
                        margin="10px 0 18px"
                      >
                        RM {orderData[0].total_amount}
                      </Paragraph>
                    </div>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>

                      <Paragraph
                        size="14px"
                        color="#707070"
                        margin="10px 0 18px"
                      >
                        Shipping Fee
                      </Paragraph>

                      <Paragraph
                        size="14px"
                        color="#707070"
                        margin="10px 0 18px"
                      >
                        RM {orderData[0].shipping_fee}
                      </Paragraph>
                    </div>
                    {/* <div style={{ display: "flex", justifyContent: "space-between" }}>

                      <Paragraph
                        size="14px"
                        color="#0F4D72"
                        margin="0 0 18px"
                      >
                        Promotion Code Discount
                      </Paragraph>
                      <Paragraph
                        size="14px"
                        color="#0F4D72"
                        margin="0 0 18px"
                      >
                        - RM 0000.00
                      </Paragraph>
                    </div> */}

                    <hr style={{ borderColor: "#707070" }} />
                    <div style={{ display: "flex", justifyContent: "space-between" }}>

                      <Paragraph
                        size="14px"
                        color="#707070"
                        margin="10px 0 18px"
                      >
                        Total
                      </Paragraph>

                      <Paragraph
                        size="14px"
                        color="#707070"
                        margin="10px 0 18px"
                      >
                        RM {(parseInt(orderData[0].total_amount) + parseInt(orderData[0].shipping_fee)).toFixed(2)}
                      </Paragraph>
                    </div>
                    {/* <div style={{ display: "flex", justifyContent: "space-between" }}>

                      <Paragraph
                        size="14px"
                        color="#000"
                        margin="0 0 18px"
                      >
                        Total Point Earned
                      </Paragraph>
                      <Paragraph
                        size="14px"
                        color="#000"
                        margin="0 0 18px"
                      >
                        0000
                      </Paragraph>
                    </div> */}
                    <div className={classes.theEbutton}>
                      <Button
                        label="Download E-Invoice"
                        color="#000000"
                        // bg="#28329B"
                        box="1px solid #707070"
                        bold="normal"
                        size="14px"
                        padding="8px 18px"
                        style={{
                          // marginRight: 18,
                          marginLeft: "0rem",
                        }}
                      />
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>

                <div className={classes.theDetailCard}>
                  <div className={classes.theDCHead}>
                    <Paragraph
                      size="16px"
                      color="#fff"
                    >
                      Shipping Information
                    </Paragraph>
                  </div>
                  <div style={{ padding: "17px 22px" }}>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>

                      <Paragraph
                        size="14px"
                        color="#000"
                        margin="0 0 22px"
                        style={{ width: "28%" }}
                      >
                        Order No.
                      </Paragraph>

                      <Paragraph
                        size="14px"
                        color="#707070"
                        margin="0 0 22px"
                        style={{ textAlign: "right", width: "70%" }}
                      >
                        {orderData[0].orders_id}
                      </Paragraph>
                    </div>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>

                      <Paragraph
                        size="14px"
                        color="#000"
                        margin="0 0 22px"
                        style={{ width: "28%" }}
                      >
                        Order Created At
                      </Paragraph>

                      <Paragraph
                        size="14px"
                        color="#707070"
                        margin="0 0 22px"
                        style={{ textAlign: "right", width: "70%" }}
                      >
                        {orderData[0].created_date}
                      </Paragraph>
                    </div>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>

                      <Paragraph
                        size="14px"
                        color="#000"
                        margin="0 0 22px"
                        style={{ width: "28%" }}
                      >
                        Receiver Name
                      </Paragraph>

                      <Paragraph
                        size="14px"
                        color="#707070"
                        margin="0 0 22px"
                        style={{ textAlign: "right", width: "70%" }}
                      >
                        {orderData[0].name}
                      </Paragraph>
                    </div>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>

                      <Paragraph
                        size="14px"
                        color="#000"
                        margin="0 0 22px"
                        style={{ width: "28%" }}
                      >
                        Shipping Address
                      </Paragraph>

                      <Paragraph
                        size="14px"
                        color="#707070"
                        margin="0 0 22px"
                        style={{ textAlign: "right", width: "70%" }}
                      >
                        {orderData[0].address}, {orderData[0].postcode}
                      </Paragraph>
                    </div>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>

                      <Paragraph
                        size="14px"
                        color="#000"
                        margin="0 0 22px"
                        style={{ width: "28%" }}
                      >
                        Contact Number
                      </Paragraph>

                      <Paragraph
                        size="14px"
                        color="#707070"
                        margin="0 0 22px"
                        style={{ textAlign: "right", width: "70%" }}
                      >
                        {orderData[0].contact}
                      </Paragraph>
                    </div>
                    {/* <div style={{ display: "flex", justifyContent: "space-between" }}>

                      <Paragraph
                        size="14px"
                        color="#000"
                        margin="0 0 22px"
                        style={{ width: "28%" }}
                      >
                        Payment Method
                      </Paragraph>

                      <Paragraph
                        size="14px"
                        color="#707070"
                        margin="0 0 22px"
                        style={{ textAlign: "right", width: "70%" }}
                      >
                        Credit / Debit Card
                      </Paragraph>
                    </div> */}
                  </div>
                </div>
              </Grid>
            </Grid>
          </Section>
        </Container>
      }
      <Footer />
    </React.Fragment>
  );
}
