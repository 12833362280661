import React, {
  useState,
  useEffect
} from "react";
import {
  Container,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Grid,
  styled,
} from "@mui/material";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import NavbarDashboard from "../../components/NavbarDashboard/NavbarDashboard";
import Paragraph from "../../components/Typography/Paragraph";
import useStyles from "./styles";
import Button from "../../components/Button/Button";
import CopyIcon from '../../assets/images/copy.svg'
import FacebookIcon from '../../assets/images/facebook.svg'
import WhatsAppIcon from '../../assets/images/whatsapp.svg'
import TwitterIcon from '../../assets/images/twitter.svg'
import ReferralImg from '../../assets/images/referral.svg'
import DeleteIcon from '../../assets/images/bin.svg'
import { WhatsApp } from "@mui/icons-material";
import FormInput from "../../components/FormInput/FormInput";
import Loader from "react-js-loader";
import toast from 'react-simple-toasts';
import {
  get_profile,
  get_wallet,
  get_member,
} from "../../API/API";

export default function DashboardAllPoint() {
  const classes = useStyles();
  const navigate = useNavigate();
  const [walletListData, setWalletListData] = useState(null)

  const getData = async () => {
    var resWallet = await get_wallet({})
    if (resWallet.status) {
      setWalletListData(resWallet.data)
    }
  }

  useEffect(() => {
    getData();
  }, [])
  return (
    <React.Fragment>
      <NavbarDashboard
        children={
          <div>
            <div className={classes.theBGDashboard}>
              <div className={classes.theTableCard}>
                <div className={classes.theTFlex}>

                  <Paragraph
                    color="#402313"
                    size="14px"
                    bold="500"
                    margin="0px 0 15px"
                  >
                    Point History
                  </Paragraph>
                </div>
                <div className={classes.theTable}>
                  <div className={classes.theTableHeader}>
                    <div style={{ width: "5%", wordBreak: "break-all" }}>
                      <Paragraph
                        color="#000"
                        size="12px"
                        bold="normal"
                        center
                      >
                        No.
                      </Paragraph>
                    </div>
                    <div style={{ width: "28%", wordBreak: "break-all" }}>
                      <Paragraph
                        color="#000"
                        size="12px"
                        bold="normal"
                        center
                      >
                        Date / Time
                      </Paragraph>
                    </div>
                    <div style={{ width: "28%", wordBreak: "break-all" }}>
                      <Paragraph
                        color="#000"
                        size="12px"
                        bold="normal"
                        center
                      >
                        Description
                      </Paragraph>
                    </div>
                    <div style={{ width: "12%", wordBreak: "break-all" }}>
                      <Paragraph
                        color="#000"
                        size="12px"
                        bold="normal"
                        center
                      >
                        Amount
                      </Paragraph>
                    </div>
                    <div style={{ width: "25%", wordBreak: "break-all" }}>
                      <Paragraph
                        color="#000"
                        size="12px"
                        bold="normal"
                        center
                      >
                        Status
                      </Paragraph>
                    </div>
                  </div>
                  {walletListData != null &&
                    <React.Fragment>
                      {walletListData.length == 0 ?
                        <Paragraph
                          color="#402313"
                          size="16px"
                          bold="bold"
                          margin="0px 0 15px"
                          center
                        >
                          No Point History
                        </Paragraph>
                        :
                        walletListData.map((res, index) =>
                          <div className={classes.theTableBodyCard}>
                            <div style={{ width: "5%", wordBreak: "break-all" }}>
                              <Paragraph
                                color="#000"
                                size="12px"
                                bold="normal"
                                center
                              >
                                1
                              </Paragraph>
                            </div>
                            <div style={{ width: "28%", wordBreak: "break-all" }}>
                              <Paragraph
                                color="#000"
                                size="12px"
                                bold="normal"
                                center
                              >
                                03 Nov 2022 13:06:41
                              </Paragraph>
                            </div>
                            <div style={{ width: "28%", wordBreak: "break-all" }}>
                              <Paragraph
                                color="#000"
                                size="12px"
                                bold="normal"
                                center
                              >
                                code applied by friend
                              </Paragraph>
                            </div>
                            <div style={{ width: "12%", wordBreak: "break-all" }}>
                              <Paragraph
                                color="#000"
                                size="12px"
                                bold="normal"
                                center
                              >
                                + 000
                              </Paragraph>
                            </div>
                            <div style={{ width: "25%", wordBreak: "break-all" }}>
                              <Paragraph
                                color="#00BE00"
                                size="12px"
                                bold="normal"
                                center
                              >
                                Successful
                              </Paragraph>
                            </div>
                          </div>
                        )}
                    </React.Fragment>
                  }
                </div>

              </div>
            </div>
          </div>
        }
      />
    </React.Fragment >
  );
}
