import React from "react";
import Slider from "react-slick";
import useStyles from "./styles";
import NavbarCentered from "../../components/NavbarCentered/NavbarCentered";
import Section from "../../components/Section/Section";
import Footer from "../../components/Footer/Footer";
import FormInputNoLabel from "../../components/FormInputNoLabel/FormInputNoLabel";

import func004 from "../../assets/images/func004.png";
import func005 from "../../assets/images/func005.png";
import func006 from "../../assets/images/func006.png";
import func007 from "../../assets/images/func007.png";

import detail001 from "../../assets/images/detail001.png";
import detail002 from "../../assets/images/detail002.png";
import detail003 from "../../assets/images/detail003.png";

import prod001 from "../../assets/images/prod001.png";
import prod002 from "../../assets/images/prod002.png";
import prod003 from "../../assets/images/prod003.png";
import prod004 from "../../assets/images/prod004.png";

import Paragraph from "../../components/Typography/Paragraph";
import Button from "../../components/Button/Button";
import { Container, Grid } from "@mui/material";
import { ArrowForward, LocationOn, Mail, Phone } from "@mui/icons-material";
import { display } from "@mui/system";
import FormInput from "../../components/FormInput/FormInput";

export default function ProductMattressDetail() {
  const classes = useStyles();

  const [chooseSelection, setChooseSelection] = React.useState(0);

  const setting = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <React.Fragment>
      <NavbarCentered bg="#FFF" />
      <Container>
        <Section pd="4rem 0 2rem 0">
          <div style={{ display: "flex" }}>
            <Paragraph>Home > </Paragraph>
            <a style={{ marginLeft: "10px" }}> Blue Dream Mattress</a>
          </div>
          <br />
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={6}>
              <div className={classes.theProd}>
                <img src={prod004} />
              </div>
              <div className={classes.theGalleryList}>
                <div className={classes.theGallery}>
                  <img src={prod004} />
                </div>
                <div className={classes.theGallery}>
                  <img src={prod004} />
                </div>
                <div className={classes.theGallery}>
                  <img src={prod004} />
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Paragraph
                bold="700"
                color="#D5AE35"
                size="34px"
                margin="0 0 2rem 0"
              >
                Iceland Series Pillow
              </Paragraph>
              <Paragraph bold="600" margin="0 0 1rem 0">
                Made for design and sleep.
              </Paragraph>
              <Paragraph size="14px">
                Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean
                commodo ligula eget dolor. Aenean massa. Cum sociis natoque
                penatibus et Lorem ipsum dolor sit amet, consectetuer adipiscing
                elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis
                natoque penatibus et
              </Paragraph>
              <br />
              <Paragraph margin="0 0 1rem 0">Type:</Paragraph>
              <div>
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  <label className={classes.theTypeBox}>
                    <input
                      className={classes.theHiddenRadioButton}
                      type="radio"
                      // value={item.product_option_selection_id}
                      name="product_option_selection_id"
                      checked={chooseSelection == 0}
                      // onChange={handleChangeChooseSelection}
                      // onClick={() =>
                      //   handleChooseSelection(
                      //     item.product_option_selection_id
                      //   )
                      // }
                    />
                    <div
                      className={
                        chooseSelection == 0
                          ? classes.theActive
                          : classes.theDefault
                      }
                    >
                      <Paragraph>Single</Paragraph>
                    </div>
                  </label>

                  <label className={classes.theTypeBox}>
                    <input
                      className={classes.theHiddenRadioButton}
                      type="radio"
                      // value={item.product_option_selection_id}
                      name="product_option_selection_id"
                      checked={chooseSelection == 1}
                      // onChange={handleChangeChooseSelection}
                      // onClick={() =>
                      //   handleChooseSelection(
                      //     item.product_option_selection_id
                      //   )
                      // }
                    />
                    <div
                      className={
                        chooseSelection == 1
                          ? classes.theActive
                          : classes.theDefault
                      }
                    >
                      <Paragraph>Super Single</Paragraph>
                    </div>
                  </label>
                  <label className={classes.theTypeBox}>
                    <input
                      className={classes.theHiddenRadioButton}
                      type="radio"
                      // value={item.product_option_selection_id}
                      name="product_option_selection_id"
                      checked={chooseSelection == 2}
                      // onChange={handleChangeChooseSelection}
                      // onClick={() =>
                      //   handleChooseSelection(
                      //     item.product_option_selection_id
                      //   )
                      // }
                    />
                    <div
                      className={
                        chooseSelection == 2
                          ? classes.theActive
                          : classes.theDefault
                      }
                    >
                      <Paragraph>King</Paragraph>
                    </div>
                  </label>

                  <label className={classes.theTypeBox}>
                    <input
                      className={classes.theHiddenRadioButton}
                      type="radio"
                      // value={item.product_option_selection_id}
                      name="product_option_selection_id"
                      checked={chooseSelection == 2}
                      // onChange={handleChangeChooseSelection}
                      // onClick={() =>
                      //   handleChooseSelection(
                      //     item.product_option_selection_id
                      //   )
                      // }
                    />
                    <div
                      className={
                        chooseSelection == 2
                          ? classes.theActive
                          : classes.theDefault
                      }
                    >
                      <Paragraph>Queen</Paragraph>
                    </div>
                  </label>
                </div>
              </div>
              <FormInput
                label="Quantity"
                type="number"
                border="1p solid #8C8074"
                style={{ width: "30%" }}
              />
              <br />
              <div style={{ width: "50%" }}>
                <Button
                  label="Add to Cart"
                  br="4px"
                  color="#fff"
                  bg="#D5AE35"
                  pd=".6rem 1rem"
                  bold="600"
                  href="/cart"
                />
              </div>
            </Grid>
          </Grid>
          <br />
        </Section>
      </Container>
      <div className={classes.theRoll}>
        {/* <img src={pillowbanner} /> */}
        <div className={classes.theAb}>
          <Grid container spacing={5}>
            <Grid item xs={12} sm={12} md={3}>
              <div className={classes.theFunc}>
                <img src={func004} />
              </div>
              <Paragraph color="#fff" bold="700" center>
                Non-Toxic
              </Paragraph>
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
              <div className={classes.theFunc}>
                <img src={func005} />
              </div>
              <Paragraph color="#fff" bold="700" center>
                High Resilience
              </Paragraph>
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
              <div className={classes.theFunc}>
                <img src={func006} />
              </div>
              <Paragraph color="#fff" bold="700" center>
                Premium Quality Stitching
              </Paragraph>
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
              <div className={classes.theFunc}>
                <img src={func007} />
              </div>
              <Paragraph color="#fff" bold="700" center>
                To Improve Blood Circulation
              </Paragraph>
            </Grid>
          </Grid>
        </div>
      </div>
      <br />
      <br />
      <Section>
        <Container>
          {/* <div className={classes.theFuncc}>
            <img src={funcc001} />
          </div>
          <div className={classes.theFuncc}>
            <img src={funcc002} />
          </div> */}
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={6}>
              <div className={classes.theDetail}>
                <img src={detail001} />
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <div>
                <Paragraph
                  color="#8C8074"
                  bold="700"
                  margin="0 0 1rem 0"
                  size="24px"
                >
                  Graphene Functional Fabric
                </Paragraph>
                <Paragraph>
                  Certified for safe by OEKO-TEX Environmental Textile
                  Association and it consist of 450 grams of graphene Graphene
                  is a strong and durable material, non-toxic 99%
                  anti-bacterial, hypoallergenic, and non-cytotoxic. It is an
                  excellent thermal conductor due to its heat-preserving
                  properties. texpels heatin warn surroundigos but preserves the
                  heat in cold weather by evenly distributing bod heat. Thus, it
                  is perfect for all seasons
                </Paragraph>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <div className={classes.theDetail}>
                <img src={detail002} />
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <div>
                <Paragraph
                  color="#8C8074"
                  bold="700"
                  margin="0 0 1rem 0"
                  size="24px"
                //   style={{ textAlign: "right" }}
                >
                  High Resilience Sponge
                </Paragraph>
                <Paragraph >
                  After many compression tests. the rebound is fast and not easy
                  to collapse, and the sleep comfort is greatly improved.
                </Paragraph>
              </div>
            </Grid>

            <Grid item xs={12} sm={12} md={6}>
              <div className={classes.theDetail}>
                <img src={detail003} />
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <div>
                <Paragraph
                  color="#8C8074"
                  bold="700"
                  margin="0 0 1rem 0"
                  size="24px"
                >
                  Premium Memory Foam
                </Paragraph>
                <Paragraph>
                  Memory foam offer great Body-contouring. This means they'll
                  form to the contours of your body shape to evenly distribute
                  your weight and elieve pressure. Great at absorbing motion. So
                  if you share a bed with a restless sleeper, you might
                  feel less disturbed
                </Paragraph>
              </div>
            </Grid>
          </Grid>
          <br />
          <br />
          <br />
          <Paragraph
            bold="700"
            center
            size="34px"
            color="#8C8074"
            margin="0 0 2rem 0"
          >
            Product Specification
          </Paragraph>
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                borderBottom: "2px solid #8C8074",
                padding: "2rem 1rem",
              }}
            >
              <Paragraph bold="700" color="#8C8074" style={{ width: "50%" }}>
                Mattress Size
              </Paragraph>
              <div style={{ width: "50%" }}>
                <Paragraph margin="0 0 .4rem 0">
                  Single: 91cm x 190cm x 20cm
                </Paragraph>
                <Paragraph margin="0 0 .4rem 0">
                  Super Single: 107cm x 190cm x 20cm
                </Paragraph>
                <Paragraph margin="0 0 .4rem 0">
                  Queen: 152cm x 190cm x 20cm
                </Paragraph>
                <Paragraph margin="0 0 .4rem 0">
                  King: 182cm x 190cm x 20cm
                </Paragraph>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                borderBottom: "2px solid #8C8074",
                padding: "2rem 1rem",
              }}
            >
              <Paragraph bold="700" color="#8C8074" style={{ width: "50%" }}>
                Model
              </Paragraph>
              <Paragraph style={{ width: "50%" }}>Blue Dream</Paragraph>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                // borderBottom: "2px solid #8C8074",
                padding: "2rem 1rem",
              }}
            >
              <Paragraph bold="700" color="#8C8074" style={{ width: "50%" }}>
                Delivery
              </Paragraph>
              <Paragraph style={{ width: "50%" }}>
                Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean
                commodo ligula eget dolor. Aenean massa. Cum sociis natoque
                penatibus et magnis dis parturient montes, nascetur ridiculus
                mus.{" "}
              </Paragraph>
            </div>
          </div>
        </Container>
      </Section>
      <div className={classes.theBGReview}>
        <div className={classes.theReview}>
          <Slider className={classes.root} {...setting}>
            <div className={classes.theBox}>
              <Paragraph center>
                "Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                Aenean commodo ligula eget dolor. Aenean massa. Cum sociis
                natoque penatibus et magnis dis parturient montes, nascetur
                ridiculus mus. Donec quam felis"
              </Paragraph>
            </div>
            <div className={classes.theBox}>
              <Paragraph center>
                "Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                Aenean commodo ligula eget dolor. Aenean massa. Cum sociis
                natoque penatibus et magnis dis parturient montes, nascetur
                ridiculus mus. Donec quam felis"
              </Paragraph>
            </div>
          </Slider>
        </div>
      </div>
      <Section pd="3rem 0">
        <Container>
          <Paragraph center bold="700" color="#402313" size="34px">
            You might also need
          </Paragraph>
          <br />
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={4}>
              <a href="/pillowdetail">
                <div className={classes.theProd}>
                  <img src={prod001} />
                </div>
                <Paragraph margin="0 0 .4rem 0">
                  Iceland Series Pillow
                </Paragraph>
                <Paragraph>RM 100.00</Paragraph>
              </a>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <a href="/pillowdetail">
                <div className={classes.theProd}>
                  <img src={prod002} />
                </div>
                <Paragraph margin="0 0 .4rem 0">
                  Contour Memory Pillow
                </Paragraph>
                <Paragraph>RM 100.00</Paragraph>
              </a>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <a href="/pillowdetail">
                <div className={classes.theProd}>
                  <img src={prod003} />
                </div>
                <Paragraph margin="0 0 .4rem 0">
                  Premium Clound Memory Pillow
                </Paragraph>
                <Paragraph>RM 100.00</Paragraph>
              </a>
            </Grid>
          </Grid>
        </Container>
      </Section>

      <Footer />
    </React.Fragment>
  );
}
