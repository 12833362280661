import styled from "styled-components";
import { TextField, FormControl } from "@mui/material";
import { Link } from "react-router-dom";
import { Menu, Search } from "@mui/icons-material";

import { makeStyles } from "@mui/styles";
import { md } from "../../styles/responsive";


export const NavLinking = styled.a`
  position: relative;
  width: 100%;
  color: #1e1e1e;
  text-align: center;
  display: block;
  // padding: 0.4rem 0;

  .MuiMenuItem-root {
    display: block;
  }
  .MuiListItem-button:hover {
    background-color: #7f222c !important;
  }
`;


export const MenuIcon = styled(Menu)`
  color: ${({ color }) => color};
`;

export const NavbarContainer = styled.div`
  // background: linear-gradient(
  //   0deg,
  //   rgba(255, 128, 1, 1) 35%,
  //   rgba(255, 215, 95, 1) 100%
  // );
  position: ${(props) => (props.position ? props.position : "relative")};
  background: ${({ bg }) => bg};
  .MuiPaper-root {
    background-color: transparent;
  }
`;

export const NavbarList = styled.div`
  position: relative;
  width: 100%;
`;

export const NavbarUpper = styled.div`
  justify-content: space-between;
  align-items: center;
  display: flex;
  flex-grow: 1;
`;

export const NavbarLogoCentered = styled.div`
  margin-left: auto;
  margin-right: auto;
  display: flex;
`;

export const NavbarLogo = styled.a`
  position: relative;
  width: 100px;
  display: block;
  img {
    width: 100px;
    margin: 0.4rem 0;
  }
`;

export const NavbarUpperList = styled.div`
  justify-content: flex-end;
  align-items: center;
  display: flex;
  padding: 1rem 0;
  width: 100%;
`;

export const BadgeCount = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  color: ${({ color }) => color};
  background: ${({ bg }) => bg};
  font-size: 12px;
`;

export const NavbarLower = styled.div`
  justify-content: flex-start;
  display: flex;
  padding: 1rem 0;
  flex-grow: 1;
`;

export const NavbarLowerList = styled.div`
  align-items: center;
  justify-content: space-between;
  display: flex;
  margin: 0 auto;
  width: ${({ wx }) => wx};
`;

export const NavbarLink = styled.a`
  align-items: center;
  display: flex;
  position: relative;
  color: ${({ color }) => color};
  font-size: 1rem;
  font-weight: ${({ bold }) => bold};
  padding: 0 1rem;

  // &:last-child {
  //   border-right: unset;
  // }
`;

export const NavbarIconLink = styled.a`
  position: relative;
  display: flex;
  align-items: center;
`;

// MOBILE STYLE

export const NavbarMobileLogo = styled.a`
  align-items: center;
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 0 0 0 20vw;

  @media screen and (min-width: 768px) {
    margin: 0 0 0 24vw;
  }
  img {
    width: 70px;
    margin: 0.8rem 0.2rem;

    @media screen and (min-width: 992px) {
      width: 100px;
    }
  }
`;

export const NavbarIconMobileList = styled.div`
  padding-left: 0;
  margin-bottom: 0;
  margin-top: 0;
  list-style: none;
  align-items: center;
  display: flex;
  justify-content: end;
  width: 100%;

  .MuiListItem-root {
    display: flex;
    @media screen and (min-width: 992px) {
      display: block;
    }
  }
`;

export const NavbarMobieLinkList = styled.div`
  position: relative;
  width: 100%;
  display: block;

  .MuiMenuItem-root {
    display: block;
  }
  // .MuiListItem-button:hover {
  //   background-color: #7f222c !important;
  // }
`;

export const Box = styled.div`
  padding: 0.66rem 1rem;
  border-top: 1px solid #eeeeee;
  border-left: 1px solid #eeeeee;
  border-bottom: 1px solid #eeeeee;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  background: #f8f8f8;
  p {
    color: #000;
    margin: 0;
  }
`;

export const SearchField = styled(TextField)`
  display: block !important;
  padding: 0.375rem 0.75rem !important;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #eeeeee;
  background-clip: padding-box;
  .MuiInput-underline:before {
    border-bottom: none !important;
  }
  .MuiInput-underline:after {
    border-bottom: none !important;
  }
  .MuiInputBase-root {
    width: 100%;
  }
  .MuiInputBase-root {
    font-weight: 500 !important;
  }
  .MuiInput-underline {
    &:before {
      position: relative !important;
    }
  }
`;

export const SearchButton = styled(Link)`
  /* background: #7f222c; */
  background: ${({ background }) => background};
  color: ${({ color }) => color};
  padding: 0.6rem 0.8rem;
  /* border-top-right-radius: 20px;
  border-bottom-right-radius: 20px; */
  align-items: center;
  display: flex;
`;

export const SearchIcon = styled(Search)`
  color: ${({ color }) => color};
`;

export const ModalBox = styled.div`
  position: absolute;
  background-color: #2c3248;
  /* background-image: url("../assets/images/bgoverlaymodal.png"); */
  /* width: 100%;
	background-size: 100% 100%; */
  top: 50%;
  width: 90%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* box-shadow: 0 0 5px #ccc; */
  /* padding: 1rem; */
  /* height: 750px; */
  overflow: scroll;
  border-radius: 25px;
  @media screen and (min-width: 768px) {
    width: 50%;
  }
  @media screen and (min-width: 992px) {
    width: 40%;
  }
  @media screen and (min-width: 1920px) {
    width: 24%;
  }
`;

export const FormInputSelect = styled(FormControl)`
  display: block;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #c5c5c5;
  border-radius: 6px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  .MuiInput-underline:before {
    border-bottom: none !important;
  }
  .MuiInput-underline:after {
    border-bottom: none !important;
  }
  .MuiInputBase-root {
    width: 100%;
  }
  .MuiInputBase-root {
    font-weight: 500 !important;
  }
  .MuiInput-underline {
    &:before {
      position: relative !important;
    }
  }
`;

export const CustomInput = styled(TextField)`
  display: block;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #1e1e1e;
  //   background-color: #eeeeee;
  background-clip: padding-box;
  border-bottom-right-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  /* border-radius: 6px; */
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  /* text-indent: 14px; */
  width: 100%;

  .MuiInput-underline:before {
    border-bottom: none !important;
  }
  .MuiInput-underline:after {
    border-bottom: none !important;
  }
  .MuiInputBase-root {
    font-weight: 500 !important;
  }
  .MuiInput-underline {
    &:before {
      position: relative !important;
    }
  }
`;

const config = {
  theNavLink: {
    position: "relative",
    width: "5vw",
    margin: "0 auto",

    "& img": {
      width: "100%",
    },
  },
  sidenavbar: {
    width: 300,
  },
  theStickyHeader: {
    position: "relative",
    background: "#5b5b5b",
    padding: ".4rem 2rem",
  },
  theBox: {
    display: "flex",
    justifyContent: "end",
  },
  theFlex_: {
    display: "block",
    ...md("sm", {
      display: "flex",
      alignItems: "center",
    }),
  },
  theDivider: {
    borderBottom: "1px dashed #979797",
    margin: "2rem 0",
  },
  theBtnSize: {
    position: "relative",
    width: "100%",
    margin: "0 1rem 1rem 0",

    ...md("sm", {
      width: "22%",
    }),
  },
  theBtnSizex: {
    position: "relative",
    // width: "100%",
    margin: "0 1rem 2rem 0",
    fontSize: "14px",
  },
  theFlex__: {
    display: "block",
    flexWrap: "wrap",

    ...md("sm", {
      display: "flex",
      flexWrap: "wrap",
    }),
  },
  theFlexBtn: {
    display: "block",

    ...md("sm", {
      display: "flex",
    }),
  },

  theUserBox: {
    position: "relative",
  },

  theIconIMG: {
    position: "relative",
    width: "40px",
    margin: "0 auto",
    // marginRight: ".6rem",
    paddingBottom: ".6rem",

    ...md("sm", {
      width: "40px",
    }),

    ...md("md", {
      width: "30px",
    }),

    ...md("xmd", {
      width: "40px",
    }),

    ...md("lg", {
      width: "40px",
    }),

    "& img": {
      width: "100%",
    },
  },

  theIconIMG_: {
    position: "relative",
    width: "10%",
    marginRight: ".6rem",
    "& img": {
      width: "100%",
    },
  },

  theFont: {
    fontSize: "14px",

    ...md("sm", {
      fontSize: "12.5px",
    }),

    ...md("xmd", {
      fontSize: "14px",
    }),
  },

  theLorry: {
    width: "6%",
    marginRight: "1rem",
    "& img": {
      width: "100%",
    },

    ...md("sm", {
      width: "3%",
    }),

    ...md("md", {
      width: "2%",
    }),

    ...md("lg", {
      width: "1.5%",
    }),
  },

  theDashboard: {
    display: 'flex',
    width: "100%",
    borderTop: "1px solid #000",
    padding: '20px 0 0',
    minHeight: "75vh"
  },
  theDNav: {
    width: "16%",
    borderRight: "0.25px solid #707070",
    padding: "12px 20px",
    margin: "0 0 20px",
    display: "none",

    ...md("md", {
      display: "block",
    }),
  },
  theDBody: {
    width: "100%",
    padding: "12px 20px",

    ...md("md", {
      width: "84%",
      padding: "12px 20px",
    }),
  },
  theTag: {
    backgroundColor: "#402313",
    padding: "12px 24px",
    borderRadius: "6px",
    margin: "0 0 20px",

    ...md("lg", {
      padding: "12px 36px",
      margin: "0",
    }),
  },
  theTFlex: {
    display: "flex",
    alignItems: 'center',
    justifyContent: 'center'
  },
  theNavItem: {
    padding: "15px 0"
  }
};

export default makeStyles(config);
