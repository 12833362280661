import React, { useEffect, useState } from "react";
import useStyles, { FooterContainer, NavbarLogo, NavbarLink } from "./styles";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { Box, Container, Grid } from "@mui/material";
import Section from "../Section/Section";
import Paragraph from "../Typography/Paragraph";
import FormInputNoLabel from "../FormInputNoLabel/FormInputNoLabel";
import { ArrowForward } from "@mui/icons-material";

import social001 from "../../assets/images/social001.png";
import social002 from "../../assets/images/social002.png";
import social003 from "../../assets/images/social003.png";
import social004 from "../../assets/images/social004.png";
import social005 from "../../assets/images/social005.png";
import Loader from "react-js-loader";
import toast from "react-simple-toasts";

import { get_footer_product, newsletter } from "../../API/API";

export default function Footer() {
  const classes = useStyles();
  const navigate = useNavigate();

  const [productData, setProductData] = useState(null);
  const [contactData, setContactData] = useState({
    name: "",
    email: "",
    contact: "",
    message: "",
  });

  const handleChange = (event) => {
    setContactData({
      ...contactData,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmitContact = () => {
    if (contactData.email.length == 0) {
      toast("Please enter email");
      return;
    }
    newsletter(contactData).then((resMessage) => {
      if (resMessage.status) {
        toast("We receive your request!");
        setContactData({
          email: "",
        });
      } else {
        toast(resMessage.message);
      }
    });
  };

  const getData = async () => {
    var resProduct = await get_footer_product({});
    if (resProduct.status) {
      setProductData(resProduct.data);
    }
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <React.Fragment>
      <Box>
      <FooterContainer>
        <Container>
          <Section pd="0">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={4} md={4}>
                <Paragraph color="#fff" bold="700" margin="0 0 1rem 0">
                  Product
                </Paragraph>
                {productData !== null &&
                  productData.map((res) => (
                    <NavbarLink
                      onClick={() => navigate("../product/" + res.product_id)}
                      color="#fff"
                    >
                      {res.name}
                    </NavbarLink>
                  ))}
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <Paragraph color="#fff" bold="700" margin="0 0 1rem 0">
                  Company
                </Paragraph>
                <NavbarLink onClick={() => navigate("../about")} color="#fff">
                  Aboout Us
                </NavbarLink>
                <NavbarLink color="#fff">Term and Condition</NavbarLink>
                <NavbarLink color="#fff">Privacy Policy</NavbarLink>
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <Paragraph color="#fff" bold="700" margin="0 0 1rem 0">
                  Help
                </Paragraph>
                <NavbarLink color="#fff" onClick={() => navigate("../contact")}>
                  Contact Us
                </NavbarLink>
                <NavbarLink onClick={() => navigate("../faq")} color="#fff">
                  FAQ
                </NavbarLink>
              </Grid>
              {/* <Grid item xs={12} sm={5} md={5}>
                <Paragraph color="#fff" bold="700" margin="0 0 1rem 0">
                  Follow Us
                </Paragraph>
                <div style={{ display: "flex" }}>
                  <a className={classes.theSocial}>
                    <img src={social001} />
                  </a>
                  <a className={classes.theSocial}>
                    <img src={social002} />
                  </a>
                  <a className={classes.theSocial}>
                    <img src={social003} />
                  </a>
                  <a className={classes.theSocial}>
                    <img src={social004} />
                  </a>
                  <a className={classes.theSocial}>
                    <img src={social005} />
                  </a>
                </div>
              </Grid> */}
            </Grid>
          </Section>
        </Container>
      </FooterContainer>
      <Box
        sx={{
          background: "#402313",
          padding: "16px 0",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <a style={{ color: "#fff" }}>© 2023 Woodpecker. All Rights Reserved.</a>
      </Box>
      </Box>
    </React.Fragment>
  );
}
