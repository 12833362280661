import React, {
  useState,
  useEffect
} from "react";
import Slider from "react-slick";
import useStyles from "./styles";
import NavbarCentered from "../../components/NavbarCentered/NavbarCentered";
import Section from "../../components/Section/Section";
import Footer from "../../components/Footer/Footer";
import FormInputNoLabel from "../../components/FormInputNoLabel/FormInputNoLabel";

import logo from "../../assets/images/logobig.png";

import prod001 from "../../assets/images/prod001.png";
import icon001 from "../../assets/images/icon001.png";
import icon002 from "../../assets/images/icon002.png";
import icon003 from "../../assets/images/icon003.png";
import icon004 from "../../assets/images/icon004.png";

import cat001 from "../../assets/images/cat001.png";
import cat002 from "../../assets/images/cat002.png";

import bannercontact from "../../assets/images/bannerContact.png";

import paymentmethod from "../../assets/images/payment.png";

import Paragraph from "../../components/Typography/Paragraph";
import Loader from "react-js-loader";
import toast from 'react-simple-toasts';
import Button from "../../components/Button/Button";
import { Container, Grid } from "@mui/material";
import {
  ArrowForward,
  Email,
  LocationOn,
  Mail,
  Phone,
} from "@mui/icons-material";
import { contact_us } from "../../API/API";

export default function Contact() {
  const classes = useStyles();
  const setting = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const [contactData, setContactData] = useState({
    name: "",
    email: "",
    contact: "",
    message: ""
  })

  const handleChange = (event) => {
    setContactData({
      ...contactData,
      [event.target.name]: event.target.value,
    });
  }

  const handleSubmitContact = () => {
    if (contactData.name.length == 0) {
      toast('Please enter name');
      return
    }
    if (contactData.email.length == 0) {
      toast('Please enter email');
      return
    }
    if (contactData.contact.length == 0) {
      toast('Please enter contact number');
      return
    }
    if (contactData.message.length == 0) {
      toast('Please enter message');
      return
    }
    contact_us(contactData).then((resMessage) => {
      if (resMessage.status) {
        toast('We receive your message!');
        setContactData({
          name: "",
          email: "",
          contact: "",
          message: ""
        });
      } else {
        toast(resMessage.message);
      }
    });
  }
  return (
    <React.Fragment>
      <NavbarCentered bg="#FFF" />
      <div className={classes.theBG_}>
        <Paragraph color="#fff" bold="700" center size="34px">
          GET IN TOUCH
        </Paragraph>
      </div>

      <Container>
        <Section pd="4rem 0 2rem 0">
          <Paragraph size="30px" center margin="0 0 3rem 0">
            Why Choose Us ?
          </Paragraph>
          <div>
            <Grid container spacing={5}>
              <Grid item xs={12} sm={6} md={4}>
                <div className={classes.theBox_}>
                  <div style={{ display: "flex", marginBottom: "1rem" }}>
                    <LocationOn
                      style={{ color: "#402313", marginRight: ".4rem" }}
                    />
                    <Paragraph color="#402313" bold="700">
                      ADDRESS
                    </Paragraph>
                  </div>
                  <Paragraph margin="0 0 1rem 0">
                    12, Jalan Seri Austin 3, Taman Seri Austin 81000, Johor
                    Bahru, Johor.
                  </Paragraph>
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <div className={classes.theBox_}>
                  <div style={{ display: "flex", marginBottom: "1rem" }}>
                    <Phone style={{ color: "#402313", marginRight: ".4rem" }} />
                    <Paragraph color="#402313" bold="700">
                      CONTACT
                    </Paragraph>
                  </div>
                  <Paragraph margin="0 0 1rem 0">+6 012 3556789</Paragraph>
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <div className={classes.theBox_}>
                  <div style={{ display: "flex", marginBottom: "1rem" }}>
                    <Email style={{ color: "#402313", marginRight: ".4rem" }} />
                    <Paragraph color="#402313" bold="700">
                      EMAIL
                    </Paragraph>
                  </div>
                  <Paragraph margin="0 0 1rem 0">
                    woodpeckerjb@email.com
                  </Paragraph>
                </div>
              </Grid>
            </Grid>
          </div>
        </Section>
      </Container>
      <div style={{ background: "#F5F5F5" }}>
        <Section pd="4rem 0">
          <Container>
            <div>
              <Paragraph color="#402313" size="30px" margin="0 0 1rem 0">
                Contact Us
              </Paragraph>
              <FormInputNoLabel
                placeholder="Name"
                name="name"
                value={contactData.name}
                onChange={handleChange}
                style={{
                  borderBottom: "1px solid #707070",
                  marginBottom: "3rem",
                }}
              />
              <FormInputNoLabel
                placeholder="Email"
                name="email"
                value={contactData.email}
                onChange={handleChange}
                style={{ borderBottom: "1px solid #707070" }}
              />
              <FormInputNoLabel
                placeholder="Contact Number"
                name="contact"
                value={contactData.contact}
                onChange={handleChange}
                style={{ borderBottom: "1px solid #707070" }}
              />
              <FormInputNoLabel
                placeholder="Your Message"
                name="message"
                value={contactData.message}
                onChange={handleChange}
                style={{ borderBottom: "1px solid #707070" }}
              />
              <br />
              <a style={{ float: "right" }}
                onClick={() => handleSubmitContact()}
              >
                <ArrowForward />
              </a>
            </div>
          </Container>
        </Section>
      </div>
      <Footer />
    </React.Fragment>
  );
}
