import React, { useState } from "react";
import {
  Container,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Grid,
  styled,
} from "@mui/material";
import NavbarDashboard from "../../components/NavbarDashboard/NavbarDashboard";
import { Link, useNavigate } from "react-router-dom";
import Paragraph from "../../components/Typography/Paragraph";
import useStyles from "./styles";
import Button from "../../components/Button/Button";
import CopyIcon from '../../assets/images/copy.svg'
import FacebookIcon from '../../assets/images/facebook.svg'
import WhatsAppIcon from '../../assets/images/whatsapp.svg'
import TwitterIcon from '../../assets/images/twitter.svg'
import ReferralImg from '../../assets/images/referral.svg'
import { WhatsApp } from "@mui/icons-material";
import FormInput from "../../components/FormInput/FormInput";
import NavbarDashboardAgent from "../../components/NavbarDashboardAgent/NavbarDashboardAgent";

export default function DashboardOrderAgent() {
  const navigate = useNavigate();
  const classes = useStyles();

  const [type, setType] = useState(1)

  return (
    <React.Fragment>
      <NavbarDashboardAgent
        children={
          <div>
            <div className={classes.theBGDashboard}>
              <div className={classes.theFilter}>
                <div className={type == 1 ? classes.theFCardActive : classes.theFCard}
                  onClick={() => setType(1)}
                >
                  <Paragraph
                    color="#402313"
                    size="14px"
                    bold="500"
                    margin="0px 12px 8px"
                  >
                    All
                  </Paragraph>
                </div>
                <div className={type == 2 ? classes.theFCardActive : classes.theFCard}
                  onClick={() => setType(2)}
                >
                  <Paragraph
                    color="#402313"
                    size="14px"
                    bold="500"
                    margin="0px 12px 8px"
                  >
                    To Ship
                  </Paragraph>
                </div>
                <div className={type == 3 ? classes.theFCardActive : classes.theFCard}
                  onClick={() => setType(3)}
                >
                  <Paragraph
                    color="#402313"
                    size="14px"
                    bold="500"
                    margin="0px 12px 8px"
                  >
                    To Receive
                  </Paragraph>
                </div>
                <div className={type == 4 ? classes.theFCardActive : classes.theFCard}
                  onClick={() => setType(4)}
                >
                  <Paragraph
                    color="#402313"
                    size="14px"
                    bold="500"
                    margin="0px 12px 8px"
                  >
                    Completed
                  </Paragraph>
                </div>
                <div className={type == 5 ? classes.theFCardActive : classes.theFCard}
                  onClick={() => setType(5)}
                >
                  <Paragraph
                    color="#402313"
                    size="14px"
                    bold="500"
                    margin="0px 12px 8px"
                  >
                    Cancelled
                  </Paragraph>
                </div>
              </div>
              <Paragraph
                color="#402313"
                size="14px"
                bold="500"
                margin="0px 0 15px"
              >
                My Orders
              </Paragraph>
              <div className={classes.theOrderCard}>
                <div className={classes.theOCFlex}>
                  <div className={classes.theSCLeft}>
                    <Paragraph
                      size='10px'
                      color="#402313"
                      bold="500"
                      margin="0 0 4px 12px"
                    >
                      Order No:
                    </Paragraph>
                    <Paragraph
                      size='14px'
                      color="#0F4D72"
                      bold="500"
                      margin="0 0 0 12px"
                      onClick={() => navigate('../order-detail')}
                    >
                      22110200001&gt;
                    </Paragraph>
                  </div>
                  <div className={classes.theSCRight}>
                    <Paragraph
                      size='18px'
                      color="#0F4D72"
                      bold="bold"
                      margin="0 12px 0 0"
                    >
                      RM 10,255
                    </Paragraph>
                  </div>
                </div>
                <div className={classes.theOCFlex2}>
                  <div className={classes.theSCLeft}>
                    <Paragraph
                      size='10px'
                      color="#402313"
                      bold="500"
                      margin="0 0 4px 12px"
                    >
                      Order Created At: 2022-11-02 21:30:40
                    </Paragraph>
                    <Paragraph
                      size='10px'
                      color="#402313"
                      bold="500"
                      margin="0 0 4px 12px"
                    >
                      Payment Method: Credit / Debit Card
                    </Paragraph>
                  </div>
                  <div className={classes.theSCRight}>
                    <Paragraph
                      size='14px'
                      color="#00BE00"
                      bold="500"
                      margin="0 12px 0 0"
                    >
                      Completed
                    </Paragraph>
                  </div>
                </div>
              </div>
              <div className={classes.theOrderCard}>
                <div className={classes.theOCFlex}>
                  <div className={classes.theSCLeft}>
                    <Paragraph
                      size='10px'
                      color="#402313"
                      bold="500"
                      margin="0 0 4px 12px"
                    >
                      Order No:
                    </Paragraph>
                    <Paragraph
                      size='14px'
                      color="#0F4D72"
                      bold="500"
                      margin="0 0 0 12px"
                      onClick={() => navigate('../order-detail')}
                    >
                      22110200001&gt;
                    </Paragraph>
                  </div>
                  <div className={classes.theSCRight}>
                    <Paragraph
                      size='18px'
                      color="#0F4D72"
                      bold="bold"
                      margin="0 12px 0 0"
                    >
                      RM 10,255
                    </Paragraph>
                  </div>
                </div>
                <div className={classes.theOCFlex2}>
                  <div className={classes.theSCLeft}>
                    <Paragraph
                      size='10px'
                      color="#402313"
                      bold="500"
                      margin="0 0 4px 12px"
                    >
                      Order Created At: 2022-11-02 21:30:40
                    </Paragraph>
                    <Paragraph
                      size='10px'
                      color="#402313"
                      bold="500"
                      margin="0 0 4px 12px"
                    >
                      Payment Method: Touch N' Go E-wallet
                    </Paragraph>
                  </div>
                  <div className={classes.theSCRight}>
                    <Paragraph
                      size='14px'
                      color="#BE0000"
                      bold="500"
                      margin="0 12px 0 0"
                    >
                      Cancelled
                    </Paragraph>
                  </div>
                </div>
              </div>
              <div className={classes.theOrderCard}>
                <div className={classes.theOCFlex}>
                  <div className={classes.theSCLeft}>
                    <Paragraph
                      size='10px'
                      color="#402313"
                      bold="500"
                      margin="0 0 4px 12px"
                    >
                      Order No:
                    </Paragraph>
                    <Paragraph
                      size='14px'
                      color="#0F4D72"
                      bold="500"
                      margin="0 0 0 12px"
                      onClick={() => navigate('../order-detail')}
                    >
                      22110200001&gt;
                    </Paragraph>
                  </div>
                  <div className={classes.theSCRight}>
                    <Paragraph
                      size='18px'
                      color="#0F4D72"
                      bold="bold"
                      margin="0 12px 0 0"
                    >
                      RM 10,255
                    </Paragraph>
                  </div>
                </div>
                <div className={classes.theOCFlex2}>
                  <div className={classes.theSCLeft}>
                    <Paragraph
                      size='10px'
                      color="#402313"
                      bold="500"
                      margin="0 0 4px 12px"
                    >
                      Order Created At: 2022-11-02 21:30:40
                    </Paragraph>
                    <Paragraph
                      size='10px'
                      color="#402313"
                      bold="500"
                      margin="0 0 4px 12px"
                    >
                      Payment Method: Credit / Debit Card
                    </Paragraph>
                  </div>
                  <div className={classes.theSCRight}>
                    <Paragraph
                      size='14px'
                      color="#0F4D72"
                      bold="500"
                      margin="0 12px 0 0"
                    >
                      #402313
                    </Paragraph>
                  </div>
                </div>
              </div>
              <div className={classes.theOrderCard}>
                <div className={classes.theOCFlex}>
                  <div className={classes.theSCLeft}>
                    <Paragraph
                      size='10px'
                      color="#402313"
                      bold="500"
                      margin="0 0 4px 12px"
                    >
                      Order No:
                    </Paragraph>
                    <Paragraph
                      size='14px'
                      color="#0F4D72"
                      bold="500"
                      margin="0 0 0 12px"
                      onClick={() => navigate('../order-detail')}
                    >
                      22110200001&gt;
                    </Paragraph>
                  </div>
                  <div className={classes.theSCRight}>
                    <Paragraph
                      size='18px'
                      color="#0F4D72"
                      bold="bold"
                      margin="0 12px 0 0"
                    >
                      RM 10,255
                    </Paragraph>
                  </div>
                </div>
                <div className={classes.theOCFlex2}>
                  <div className={classes.theSCLeft}>
                    <Paragraph
                      size='10px'
                      color="#402313"
                      bold="500"
                      margin="0 0 4px 12px"
                    >
                      Order Created At: 2022-11-02 21:30:40
                    </Paragraph>
                    <Paragraph
                      size='10px'
                      color="#402313"
                      bold="500"
                      margin="0 0 4px 12px"
                    >
                      Payment Method: Touch N' Go E-wallet
                    </Paragraph>
                  </div>
                  <div className={classes.theSCRight}>
                    <Paragraph
                      size='14px'
                      color="#402313"
                      bold="500"
                      margin="0 12px 0 0"
                    >
                      To Receive
                    </Paragraph>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      />
    </React.Fragment>
  );
}
