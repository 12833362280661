// import styled from "styled-components/macro";
import { makeStyles } from "@mui/styles";
import { md } from "../../styles/responsive";

const config = {
  theHeadCard: {
    backgroundColor: "#402313",
    padding: "12px 0",
    display: 'flex',
    justifyContent: 'space-between',
  },
  theHeadBody: {
    border: "1px solid #707070",
    backgroundColor: "#fff",
    padding: "12px 0",
    display: 'flex',
    justifyContent: 'space-between',
    margin: "0 0 25px"
  },
  theDetailCard: {
    border: "1px solid #707070",
    margin: "0 0 25px"
  },
  theDCHead: {
    backgroundColor: "#402313",
    padding: "12px 22px",
  },

  theWidth: {
    width: "100vw",

    ...md("sm", {
      width: "50vw",
    }),

    ...md("md", {
      width: "100%",
    }),
  },

  theIMG: {
    position: "relative",
    width: "10%",
    "& img": {
      width: "100%",
    },

    ...md("sm", {
      width: "20%",
    }),

    ...md("md", {
      width: "10%",
    }),
  },

  theWidth2: {
    width: "100px",

    ...md("sm", {
      width: "100%",
    }),
  },
  theEbutton: {
    width: "max-content",
    margin: "0 0 0 auto"
  }
};

export default makeStyles(config);
