import React, { useEffect } from "react";
import Footer from "../../components/Footer/Footer";
import NavbarCentered from "../../components/NavbarCentered/NavbarCentered";
import { Box, Container } from "@mui/material";
import Section from "../../components/Section/Section";

const MemoryFoam = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (<>
        <React.Fragment>
            <NavbarCentered bg="#FFF" />

            <img src="/images/article_3.jpg" style={{
                width: "100%"
            }} />
            <Box>
                <Container>
                    <Section pd="0">
                        <h2>
                            What is Memory Foam?
                        </h2>
                        <p>
                            If you’ve been in the market for mattresses lately, you’ve probably heard the term “memory foam.” Mattresses come in many different styles, using many different materials, and memory foam has become one of the most popular.
                            <br /><br />
                            First designed in the 1960’s for use in NASA spaceship seats and seatbelts, shock-absorbing, pressure-relieving memory foam has also been used as cushioning in helmets and shoes, and use in prosthetics and wheelchair seating pads. However, it wasn’t until the 1990’s, when Fagerdella World Foams released their flagship TempurPedic Swedish mattress, that memory foam was introduced as a mattress material. Since then, memory foam mattresses have exploded onto the market, especially with the growth of mattress-in-a-box brands.
                            <br /><br />
                            But what exactly is memory foam? What is it made of, how does it work, and how do you know if it’s the right choice for you?
                            <br /><br />
                            We’ve got you covered. In this guide to all things memory foam, we’ll break down the ins and outs of the material, how it’s used in mattresses, and what to look for as a smart mattress shopper.
                        </p>
                        <hr />
                        <h3>
                            What is Memory Foam Made Of?
                        </h3>
                        <p>
                            First thing’s first: what is memory foam, physically?
                            <br /><br />
                            The main component of memory foam is a polymer (a substance with large molecules, consisting of many small, similar subunits bonded together) called polyurethane. Polyurethane is an incredibly common and versatile plastic polymer that can be used to produce a wide range of materials and products, including furniture like sofas and mattresses, but also products like insulation, liquid paints and primers, spray foam, elastic fibers, car parts, and even tough elastomers like roller blade wheels.
                            <br /><br />
                            Memory foam is what is known as “viscoelastic” polyurethane foam, or low-resistance polyurethane foam (LRPu). It is created by adding various compounds and additives to polyurethane, which change depending on the type of foam being created.
                            <br /><br />
                            Ultimately, these chemicals affect two of the main qualities of memory foam: viscosity and elasticity. In the context of memory foam, when we say that the material is “viscous”, we mean that it takes a long time to change shape under pressure, or to transfer energy from one place to another. When we say a material is “elastic”, it means it can stretch or contort but return to its original shape or size when the stretching force is taken away,
                            <br /><br />
                            Different manufacturers have different “recipes” for the chemicals they add to the foam and the processes they use to create the foam. These recipes and processes affect the feel and function of each individual memory foam product, and are often proprietary secrets. However, as a general rule, the viscoelastic foam used in most memory foam mattresses contains at least some degree of a compound called polyether polyol, which helps give the foam both elasticity and viscosity.
                        </p>
                        <h3>How Does Memory Foam Work?</h3>
                        <p>
                            As we outlined above, memory foam mattresses are designed to slowly mold to the body in response to pressure, and therefore to evenly distribute body weight. They are also designed to be resilient, and to return to their original shape once body weight and pressure is removed.
                            <br /><br />
                            Memory foam softens when it comes into contact with your body heat, molds to your unique contours, and distributes your weight equally. The material responds differently depending on how you apply pressure, or “force”. If you apply pressure quickly, the foam tends to change shape more slowly, or “reluctantly.” This means that memory foam is excellent at absorbing the force of an impact, which is why it was originally used in areas like space shuttle construction. When memory foam is used as a mattress material, this quality helps the mattress cushion the body evenly, and creates the slow contouring feeling as the mattress adapts to the impact of your body and re-forms around your curves.
                            <br /><br />
                            Memory foam also returns relatively slowly to its original shape when force is removed–on average, around 5-10 seconds. During this recovery period, energy from the impact is absorbed and dissipated. This time lag, a phenomenon known as “hysteresis”, also helps cushion sleepers, because it removes some of the pressure (or, impact energy) produced by the prone body.
                            <br /><br />
                            Another important part of how memory foam works is that its viscosity decreases with temperature. This means that it becomes less stiff and more flexible the hotter it gets. This is the reason why memory foam mattresses feel softer or more pliable after you’ve been lying on them for a time, since your body heat increases the temperature of the sleep surface.


                        </p>
                        <hr />
                        <h2>Different Types of Memory Foam Mattresses</h2>
                        <p>
                            Memory foam mattresses come in a wide variety of sizes, shapes, and construction-styles. Beyond the proprietary differences in compounds and processes when it comes to each individual foam product, there are several basic types of memory foam.
                        </p>
                        <h2>Types of Memory Foam</h2>
                        <p>By and large, there are three main types of memory foam:</p>
                        <h3>Traditional</h3>
                        <p>
                            This is the original, “classic” memory foam. It was the first memory foam to enter the market for consumer use. Traditional memory foam is engineered to mold to your body and is constructed the way we have outlined above. One issue with traditional memory foam is that it has a tendency to retain heat, which can cause the sleeping surface of mattresses using that foam to become uncomfortably warm. The two other types of memory foam were created in part to address that issue.
                        </p>

                        <h3>Open-Cell</h3>

                        <p>Open-cell memory foam has the same ingredients as traditional memory foam, but with a different internal structure. Open-cell memory foam mattresses have internal pockets (or, “open cells”) that allow for ventilation and air flow throughout the mattress, which helps disperse heat.
                            <br /><br />
                            Originally, open-cell memory foam mattresses were far less dense, which sometimes affected the firmness of the mattress and made them feel less supportive. However, new production technologies have been developed to address that issue while also retaining the open-celled structure of the mattress (and therefore retaining the cooling effect).
                        </p>
                        <h3>Gel</h3>
                        <p>
                            Another type of memory foam incorporates gel in its construction. Gels are usually added to the mattress by pumping gel-based microbeads into the foam. These microbeads create pockets similar to those that exist in open-cell mattresses. Rather than simply letting air through the mattress, these gels are generally “phase-changing” materials, meaning they actively absorb and release heat from your body.
                            <br /><br />
                            Some mattresses use foams that only fit one of these categories, but many modern memory foam mattresses incorporate several, if not all three.
                            <br /><br />
                            In addition to these three basic types, some memory foam mattress developers now add other materials to their mattresses, often specifically for the purpose of cooling. One of those materials is copper. Copper is highly conductive, and helps with both heat-dispersal and moisture-wicking when used in memory foam mattresses. There are mattresses that fit the description of all three of the main types of memory foam that incorporate copper in their construction.
                            <br /><br />
                            Another up-and-coming innovation in memory foam is the development of more eco-friendly foams. These eco-friendly memory foams (or, “greener memory foams”) incorporate more plant-based materials than the average memory foam mattress. For instance, they may replace some of the petroleum-based compounds and derivatives used in standard memory foam manufacturing with compounds and derivatives made from soy or corn oil. These manufacturing techniques are still relatively new, but they are becoming more common as the technology develops.
                        </p>
                        <hr />
                        <h2>Mattress Construction
                        </h2>
                        <p>Memory foam is used in mattresses in several different ways. For one thing, different memory foam mattresses have different constructions designed to achieve specific qualities. However, there is a basic template that most memory foam mattresses follow, even if the unique components differ. That template has three components:</p>
                        <ol>
                            <li>Comfort Layer: This top section of the mattress is composed of one or more foam layers that are generally designed to provide contouring and cushioning, depending on the level of firmness of the mattress. Many mattress designs use more breathable foams in this section, in order to keep heat away from the sleeping surface.</li>
                            <li>Transition Layer: This section of the mattress is composed of one or more foam layers meant to work between the comfort layers and the core. They tend to be slightly firmer than the foam in the comfort levels, and often help wick heat away from the comfort layer.</li>
                            <li>Core: This is the base of the mattress. It is often composed of much firmer foam, and is the largest layer, usually by far. It provides stability and support to the mattress through the other foam layers. In some mattresses, called hybrid mattresses, the core is not foam, but rather is the same sort of core you would find on an innerspring mattress. This tends to provide additional support, bounce, and ventilation.</li>
                        </ol>
                        <br /><br />
                        <br /><br />
                    </Section>
                </Container>
            </Box>
            <Footer />
        </React.Fragment>
    </>)
};

export default MemoryFoam;