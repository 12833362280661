import React from "react";
import classNames from "classnames";
import useStyles, { Text } from "./styles";

const FormLabel = ({
  textLabelSize,
  textLabelColor,
  textLabelBold,
  textLabelMargin,
  className,
  children,
  ...others
}) => {
  const classes = useStyles({
    textLabelSize,
    textLabelMargin,
    textLabelColor,
    textLabelBold,
  });

  return (
    <Text
      {...others}
      textLabelSize={textLabelSize}
      textLabelBold={textLabelBold}
      textLabelMargin={textLabelMargin}
      textLabelColor={textLabelColor}
      className={classNames(classes.root, className)}
    >
      {children}
    </Text>
  );
};

export default FormLabel;
