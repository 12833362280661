import React, { useState, useEffect } from "react";
import {
  AppBar,
  Container,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  useMediaQuery,
  Box,
  Button as Btn,
  Dialog,
  DialogContent,
  Grid,
  Select,
  Divider,
  Checkbox,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";
import { Link, useLocation, useNavigate } from "react-router-dom";
import SidebarMenuDashboard from "../SidebarMenuDashboard/SidebarMenuDashboard";

import Button from "../Button/Button";
import FormInput from "../FormInput/FormInput";
import logo from "../../assets/images/logo.png";
import lorry from "../../assets/images/delivery.png";

import useStyles, {
  MenuIcon,
  NavbarContainer,
  NavbarList,
  NavbarLogo,
  NavbarLogo_,
  NavbarMobileLogo,
  NavbarIconMobileList,
  NavLinking,
  NavbarLower,
  NavbarLowerList,
  NavbarLink,
  NavbarIconLink,
  NavbarUpper,
  BadgeCount,
  NavbarUpperList,
  NavbarLogoCentered,
} from "./styles";

import {
  AccountCircleOutlined,
  FavoriteBorderOutlined,
  Close,
  LockOutlined,
  Phone,
  CorporateFareOutlined,
  EmailOutlined,
  PersonOutlined,
  FeedOutlined,
  BuildOutlined,
  Public,
  InsertPhoto,
  AutoFixNormal,
  AccountCircleRounded,
  ShoppingBagOutlined,
  Mail,
} from "@mui/icons-material";
import Paragraph from "../Typography/Paragraph";
import "./styles.css";
import Loader from "react-js-loader";
import toast from "react-simple-toasts";
import { get_profile, get_wallet, edit_name } from "../../API/API";
import useUser from "../../hooks/useUser";
import useCart from "../../hooks/useCart";

const drawerWidth = 240;

const NavbarDashboard = ({ position, bg, children }) => {
  const history = useNavigate();
  const { set_user, get_user } = useUser();
  const location = useLocation();
  const navigate = useNavigate();
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [profileData, setProfileData] = useState(null);
  const [walletData, setWalletData] = useState(null);

  const [openSideMenu, setOpenSideMenu] = React.useState({ left: false });

  const handleDrawerToggle = (anchor, open) => (e) => {
    if (e.type == "keydown" && (e.key == "Tab" || e.key == "Shift")) {
      return;
    }
    setOpenSideMenu({ [anchor]: open });
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // HANDLE LOGOUT

  const handleLogout = () => {
    set_user(null);
    window.localStorage.removeItem("token");
    history("../");
  };

  const getData = async () => {
    var resProfile = await get_profile({});
    if (resProfile.status) {
      setProfileData(resProfile.data);
    }
    var resWallet = await get_wallet({});
    if (resWallet.status) {
      setWalletData(resWallet);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  // TOKEN

  const [token, setToken] = useState([]);

  useEffect(() => {
    let token = window.localStorage.getItem("token");
    setToken(token || "");
    console.log("CURRENT TOKEN", token);
  }, []);

  // CART

  const { getCart, clearCart } = useCart();

  const getcart = JSON.stringify(getCart());

  const [cart, setCart] = React.useState(getCart);

  React.useEffect(() => {
    setCart(getCart());
  }, [getCart]);

  // NAVBAR ITEM

  const NAVBARLIST = [
    {
      title: "HOME",
      link: "",
    },
    {
      title: "PILLOW",
      link: "pillow",
    },
    {
      title: "MATTRESS",
      link: "mattress",
    },
    {
      title: "SALE",
      link: "sale",
    },
    {
      title: "WHY WOODPECKER",
      link: "about",
    },
    {
      title: "CONTACT US",
      link: "contact",
    },
  ];

  return (
    <>
      <Box
        sx={{
          background: "#D5AE35",
          position: "relative",
          display: "flex",
          justifyContent: "center",
          padding: "8px 0",
        }}
      >
        <Paragraph bold="500" color="#fff">
          20& off everything Use code SUMMERSALES
        </Paragraph>
      </Box>
      <NavbarContainer position={position} bg={bg}>
        <Container maxWidth="xxl">
          <AppBar position="static" sx={{ boxShadow: "unset" }}>
            <Toolbar>
              {isMobile ? (
                <>
                  <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    onClick={handleDrawerToggle("left", true)}
                  >
                    <MenuIcon color="#D5AE35" />
                  </IconButton>
                  <NavbarMobileLogo>
                    <img src={logo} />
                  </NavbarMobileLogo>
                  <NavbarIconMobileList>
                    {token ? (
                      <>
                        <NavbarIconLink
                          href="/cart"
                          style={{ marginRight: "1.5rem" }}
                        >
                          <ShoppingBagOutlined sx={{ color: "#D5AE35" }} />
                          <BadgeCount bg="#D5AE35" color="#fff">
                            {cart.length}
                          </BadgeCount>
                        </NavbarIconLink>

                        <NavbarIconLink
                          // onClick={handleMenu}
                          onClick={() =>
                            get_user() !== null
                              ? navigate("../dashboard")
                              : navigate("../login")
                          }
                        >
                          <AccountCircleOutlined sx={{ color: "#D5AE35" }} />
                        </NavbarIconLink>
                      </>
                    ) : (
                      <>
                        <NavbarIconLink
                          href="/cart"
                          style={{ marginRight: "1.5rem" }}
                        >
                          <ShoppingBagOutlined sx={{ color: "#D5AE35" }} />
                          <BadgeCount bg="#D5AE35" color="#fff">
                            {cart.length}
                          </BadgeCount>
                        </NavbarIconLink>
                        <NavbarIconLink
                          //  onClick={handleMenu}
                          onClick={() =>
                            get_user() !== null
                              ? navigate("../dashboard")
                              : navigate("../login")
                          }
                        >
                          <AccountCircleOutlined sx={{ color: "#D5AE35" }} />
                        </NavbarIconLink>
                      </>
                    )}
                  </NavbarIconMobileList>

                  <SidebarMenuDashboard
                    anchor="left"
                    open={openSideMenu.left}
                    onOpen={handleDrawerToggle("left", true)}
                    onClose={handleDrawerToggle("left", false)}
                    handleDrawerToggle={handleDrawerToggle("left", false)}
                  />
                </>
              ) : (
                <>
                  <NavbarList>
                    <Container>
                      <NavbarUpper>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            width: "100%",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              // width: "70%",
                              alignItems: "center",
                            }}
                          >
                            <Box sx={{ display: "flex" }}>
                              <a
                                style={{
                                  display: "flex",
                                  margin: "0 calc(32px/2)",
                                }}
                              >
                                <Phone
                                  sx={{ marginRight: "8px", color: "#D5AE35" }}
                                />
                                <Paragraph bold="500">
                                  (+021) 345 678 910
                                </Paragraph>
                              </a>
                            </Box>
                            <Box sx={{ display: "flex" }}>
                              <a
                                style={{
                                  display: "flex",
                                  margin: "0 calc(32px/2)",
                                }}
                              >
                                <Mail
                                  sx={{ marginRight: "8px", color: "#D5AE35" }}
                                />
                                <Paragraph bold="500">
                                  info@woodpecker.com
                                </Paragraph>
                              </a>
                            </Box>
                          </div>
                        </div>
                        <NavbarLogoCentered>
                          <NavbarLogo>
                            <img src={logo} />
                          </NavbarLogo>
                        </NavbarLogoCentered>
                        <NavbarUpperList>
                          {token ? (
                            <>
                              <div style={{ display: "flex" }}>
                                <NavbarIconLink
                                  href="/cart"
                                  style={{ marginRight: "1.5rem" }}
                                >
                                  <ShoppingBagOutlined
                                    sx={{ color: "#D5AE35" }}
                                  />
                                  <BadgeCount bg="#D5AE35" color="#fff">
                                    {cart.length}
                                  </BadgeCount>
                                </NavbarIconLink>
                              </div>

                              <NavbarIconLink onClick={handleMenu}>
                                <AccountCircleOutlined
                                  sx={{ color: "#D5AE35" }}
                                />
                              </NavbarIconLink>
                            </>
                          ) : (
                            <>
                              <div style={{ display: "flex" }}>
                                <NavbarIconLink
                                  href="/cart"
                                  style={{ marginRight: "1.5rem" }}
                                >
                                  <ShoppingBagOutlined
                                    sx={{ color: "#D5AE35" }}
                                  />
                                  <BadgeCount bg="#D5AE35" color="#fff">
                                    {cart.length}
                                  </BadgeCount>
                                </NavbarIconLink>
                                <a
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    marginRight: "2rem",
                                  }}
                                  onClick={() =>
                                    get_user() !== null
                                      ? navigate("../dashboard")
                                      : navigate("../login")
                                  }
                                >
                                  <AccountCircleOutlined
                                    style={{ color: "#D5AE35" }}
                                  />
                                </a>
                              </div>
                            </>
                          )}
                        </NavbarUpperList>
                      </NavbarUpper>
                      <NavbarLower>
                        <NavbarLowerList>
                          {NAVBARLIST.map((item) => (
                            <NavbarLink
                              color={
                                location.pathname.replace(/[^A-Za-z]+/g, "") ===
                                item.link
                                  ? "#D5AE35"
                                  : "#000"
                              }
                              bold={
                                location.pathname.replace(/[^A-Za-z]+/g, "") ===
                                item.link
                                  ? "700"
                                  : "normal"
                              }
                              href={"/" + item.link}
                            >
                              {item.title}
                            </NavbarLink>
                          ))}
                        </NavbarLowerList>
                      </NavbarLower>
                    </Container>
                  </NavbarList>
                </>
              )}

              <>
                <Menu
                  id="menu-appbar"
                  elevation={0}
                  // getContentAnchorEl={null}
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                  // anchorPosition={{
                  //   top: 300,
                  // }}
                  keepMounted
                  open={open}
                  onClose={handleClose}
                >
                  <MenuItem>
                    <NavLinking>
                      <div>
                        <Button
                          label="Profile"
                          bg="#28329B"
                          color="#fff"
                          bold="500"
                          br="4px"
                          pd=".2rem 1rem"
                          size="12px"
                          href="/profile"
                          style={{ marginBottom: ".6rem" }}
                        />

                        <Button
                          label="Sign Out"
                          bg="#28329B"
                          color="#fff"
                          bold="500"
                          br="4px"
                          pd=".2rem 1rem"
                          size="12px"
                          href="/profile"
                        />
                      </div>
                    </NavLinking>
                  </MenuItem>
                </Menu>
              </>
            </Toolbar>
          </AppBar>
        </Container>
      </NavbarContainer>
      {profileData !== null && (
        <div className={classes.theDashboard}>
          <div className={classes.theDNav}>
            <div className={classes.theTag}>
              <Paragraph
                center
                color="#fff"
                size="16px"
                bold="bold"
                style={{ overflowWrap: "break-word" }}
              >
                {profileData.name}
              </Paragraph>

              {profileData.role === "Member" ? (
                <div className={classes.theTFlex}>
                  <Paragraph
                    center
                    color="#fff"
                    size="12px"
                    margin="0 6px 0 0"
                    style={{
                      borderRight: "1px solid #fff",
                      paddingRight: "6px",
                    }}
                  >
                    {walletData != null && walletData.balance} points
                  </Paragraph>
                  <Paragraph center color="#fff" size="12px">
                    {profileData.role}
                  </Paragraph>
                </div>
              ) : (
                <Paragraph
                  center
                  color="#fff"
                  size="10px"
                  bold="normal"
                  // margin="6px 0 0"
                >
                  {profileData.role}
                </Paragraph>
              )}
            </div>
            <div className={classes.theNavItem}>
              <Paragraph
                center
                color={
                  location.pathname.replace(/[^A-Za-z]+/g, "") === "dashboard"
                    ? "#402313"
                    : "#000"
                }
                size="14px"
                bold={
                  location.pathname.replace(/[^A-Za-z]+/g, "") === "dashboard"
                    ? "bold"
                    : "light"
                }
                style={{ padding: "15px" }}
                onClick={() => history("../dashboard")}
              >
                My Profile
              </Paragraph>
              {profileData.role !== "Member" && (
                <Paragraph
                  center
                  color={
                    location.pathname.replace(/[^A-Za-z]+/g, "") ===
                    "dashboardagentwallet"
                      ? "#402313"
                      : "#000"
                  }
                  size="14px"
                  bold={
                    location.pathname.replace(/[^A-Za-z]+/g, "") ===
                    "dashboardagentwallet"
                      ? "bold"
                      : "light"
                  }
                  style={{ padding: "15px" }}
                  onClick={() => history("../dashboard-agent-wallet")}
                >
                  My Wallet
                </Paragraph>
              )}
              <Paragraph
                center
                color={
                  location.pathname.replace(/[^A-Za-z]+/g, "") ===
                  "dashboardorder"
                    ? "#402313"
                    : "#000"
                }
                size="14px"
                bold={
                  location.pathname.replace(/[^A-Za-z]+/g, "") ===
                  "dashboardorder"
                    ? "bold"
                    : "light"
                }
                style={{ padding: "15px" }}
                onClick={() => history("../dashboard-order")}
              >
                My Orders
              </Paragraph>
              <Paragraph
                center
                color={
                  location.pathname.replace(/[^A-Za-z]+/g, "") ===
                    "dashboardreferral" ||
                  location.pathname.replace(/[^A-Za-z]+/g, "") ===
                    "dashboardallreferral"
                    ? "#402313"
                    : "#000"
                }
                size="14px"
                bold={
                  location.pathname.replace(/[^A-Za-z]+/g, "") ===
                    "dashboardreferral" ||
                  location.pathname.replace(/[^A-Za-z]+/g, "") ===
                    "dashboardallreferral"
                    ? "bold"
                    : "light"
                }
                style={{ padding: "15px" }}
                onClick={() => history("../dashboard-referral")}
              >
                My Referrals
              </Paragraph>
              <Paragraph
                center
                color={
                  location.pathname.replace(/[^A-Za-z]+/g, "") ===
                  "dashboardsetting"
                    ? "#402313"
                    : "#000"
                }
                size="14px"
                bold={
                  location.pathname.replace(/[^A-Za-z]+/g, "") ===
                  "dashboardsetting"
                    ? "bold"
                    : "light"
                }
                style={{ padding: "15px" }}
                onClick={() => history("../dashboard-setting")}
              >
                Settings
              </Paragraph>
              <Paragraph
                center
                color="#000"
                size="14px"
                bold="light"
                style={{ padding: "15px" }}
                onClick={() => handleLogout()}
              >
                Logout
              </Paragraph>
            </div>
          </div>
          <div className={classes.theDBody}>
            {isMobile && (
              <div className={classes.theTag}>
                <Paragraph
                  center
                  color="#fff"
                  size="16px"
                  bold="bold"
                  style={{ overflowWrap: "break-word" }}
                >
                  {profileData.name}
                </Paragraph>
                <div className={classes.theTFlex}>
                  <Paragraph
                    center
                    color="#fff"
                    size="12px"
                    margin="0 6px 0 0"
                    style={{
                      borderRight: "1px solid #fff",
                      paddingRight: "6px",
                    }}
                  >
                    {walletData != null && walletData.balance} points
                  </Paragraph>
                  <Paragraph center color="#fff" size="12px">
                    {profileData.role}
                  </Paragraph>
                </div>
              </div>
            )}
            {children}
          </div>
        </div>
      )}
    </>
  );
};

export default NavbarDashboard;
