import styled from "styled-components";
import { makeStyles } from "@mui/styles";

export const NavbarSidebarList = styled.div`
  padding: 4rem 1rem;
`;

export const NavbarSidebarListLogo = styled.a`
  justify-content: center;
  align-items: center;
  display:flex;
  img {
    width: 120px;
  }
`;

export const CloseButtonBox = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
`;

const config = {
  theSideBar: {
    width: "300px",
  },
};

export default makeStyles(config);