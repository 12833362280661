import React, {
  useState,
  useEffect
} from "react";
import {
  Container,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Grid,
  styled,
} from "@mui/material";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import NavbarDashboard from "../../components/NavbarDashboard/NavbarDashboard";
import Paragraph from "../../components/Typography/Paragraph";
import useStyles from "./styles";
import Button from "../../components/Button/Button";
import CopyIcon from '../../assets/images/copy.svg'
import FacebookIcon from '../../assets/images/facebook.svg'
import WhatsAppIcon from '../../assets/images/whatsapp.svg'
import TwitterIcon from '../../assets/images/twitter.svg'
import ReferralImg from '../../assets/images/referral.svg'
import DeleteIcon from '../../assets/images/bin.svg'
import { WhatsApp } from "@mui/icons-material";
import FormInput from "../../components/FormInput/FormInput";
import Loader from "react-js-loader";
import toast from 'react-simple-toasts';
import {
  get_profile,
  get_wallet,
  get_member,
} from "../../API/API";

export default function DashboardReferral() {
  const classes = useStyles();
  const navigate = useNavigate();

  const [profileData, setProfileData] = useState(null)
  const [walletData, setWalletData] = useState(null)
  const [walletListData, setWalletListData] = useState(null)
  const [memberData, setMemberData] = useState(null)

  const getData = async () => {
    var resProfile = await get_profile({})
    if (resProfile.status) {
      setProfileData(resProfile.data)
    }
    var resWallet = await get_wallet({})
    var temp_Walletdata = []
    if (resWallet.status) {
      setWalletData(resWallet)
      if (resWallet.data.length > 5) {

        for (var i = 0; i < 5; i++) {
          temp_Walletdata.push(resWallet.data[i])
        }
        setWalletListData(temp_Walletdata)
      } else {
        setWalletListData(resWallet.data)
      }
    }
    var resMember = await get_member({})
    var temp_data = []
    if (resMember.status) {
      if (resMember.data.length > 5) {

        for (var i = 0; i < 5; i++) {
          temp_data.push(resMember.data[i])
        }
        setMemberData(temp_data)
      } else {
        setMemberData(resMember.data)
      }
    }
  }

  useEffect(() => {
    getData();
  }, [])

  const handleUpgrade = () => {
    window.location.href = "https://api.whatsapp.com/send?phone=https://wa.me/60123456789&text=Hi%20,%20i%20want%20upgrade%20to%20agent"
  }
  return (
    <React.Fragment>
      {profileData !== null &&
        <NavbarDashboard
          children={
            <div>
              <div className={classes.theBGDashboard}>
                <div className={classes.theLevelCard}>
                  <div className={classes.theLTFlex}>
                    <Paragraph
                      size='14px'
                      color="#000"
                      bold="normal"
                    >
                      Level<span style={{ margin: "0 30px" }}>:</span><span style={{ fontWeight: "bold" }}>{profileData.role}</span>
                    </Paragraph>

                    {profileData.role === 'Member' &&
                      <div className={classes.theFlexText}>
                        <Paragraph
                          size='14px'
                          color="#000"
                          bold="normal"
                        >
                          Total Points<span style={{ margin: "0 30px" }}>:</span><span style={{ fontWeight: "bold" }}>{walletData != null && walletData.balance} points</span>
                        </Paragraph>
                      </div>
                    }
                  </div>


                  {profileData.role === 'Member' &&
                    <Button label="Become an Agent" color="#fff" bg="#402313" pd="10px 35px" br="6px"
                      onClick={() => handleUpgrade()} />
                  }
                </div>
                <div className={classes.theShareCard}>
                  <div className={classes.theSCLeft}>
                    <Paragraph
                      size='14px'
                      color="#402313"
                      bold="bold"
                      margin="0 0 4px"
                    >
                      SHARE YOUR UNIQUE CODE
                    </Paragraph>
                    <Paragraph
                      size='12px'
                      color="#000"
                      bold="normal"
                      margin="0 0 20px"
                    >
                      Get points when every friend signs up
                    </Paragraph>
                    <div className={classes.theSCLFlex}>
                      <div className={classes.theReferal}>
                        <Paragraph
                          size='12px'
                          color="#707070"
                          bold="bold"
                          center
                        >
                          {profileData.referral_code}
                        </Paragraph>
                      </div>
                      <div className={classes.theCopy}
                        onClick={() => {
                          navigator.clipboard.writeText(profileData.referral_code)
                          toast("Copy succesfully")
                        }}
                      >
                        <img src={CopyIcon} />
                      </div>
                    </div>
                    {/* <div className={classes.theSocialFlex}>
                      <div className={classes.theSocialIcon}>
                        <img src={WhatsAppIcon} />
                      </div>
                      <div className={classes.theSocialIcon}>
                        <img src={FacebookIcon} />
                      </div>
                      <div className={classes.theSocialIcon}>
                        <img src={TwitterIcon} />
                      </div>
                    </div> */}
                  </div>
                  <div className={classes.theSCRight}>
                    <div className={classes.theSCRImg}>
                      <img src={ReferralImg} />
                    </div>
                  </div>
                </div>
                <div className={classes.theTableCard}>
                  <div className={classes.theTFlex}>

                    <Paragraph
                      color="#402313"
                      size="14px"
                      bold="500"
                      margin="0px 0 15px"
                    >
                      My Referrals
                    </Paragraph>

                    <Paragraph
                      color="#402313"
                      size="14px"
                      bold="500"
                      margin="0px 0 15px"
                      style={{ textDecoration: 'underline' }}
                      onClick={() => navigate('../dashboard-all-referral')}
                    >
                      view all &gt;&gt;
                    </Paragraph>
                  </div>

                  <div className={classes.theTable}>
                    <div className={classes.theTableHeader}>
                      <div style={{ width: "10%" }}>
                        <Paragraph
                          color="#000"
                          size="12px"
                          bold="normal"
                          center
                        >
                          No.
                        </Paragraph>
                      </div>
                      <div style={{ width: "40%" }}>
                        <Paragraph
                          color="#000"
                          size="12px"
                          bold="normal"
                          center
                        >
                          Name
                        </Paragraph>
                      </div>
                      <div style={{ width: "40%" }}>
                        <Paragraph
                          color="#000"
                          size="12px"
                          bold="normal"
                          center
                        >
                          Email
                        </Paragraph>
                      </div>
                      {/* <div style={{ width: "25%" }}>
                        <Paragraph
                          color="#000"
                          size="12px"
                          bold="normal"
                          center
                        >
                          Contact Number
                        </Paragraph>
                      </div> */}
                      {/* <div style={{ width: "10%" }}>
                      </div> */}
                    </div>
                    {memberData != null &&
                      <React.Fragment>
                        {memberData.length == 0 ?
                          <Paragraph
                            color="#402313"
                            size="16px"
                            bold="bold"
                            margin="0px 0 15px"
                            center
                          >
                            No Member
                          </Paragraph>
                          :
                          memberData.map((res, index) =>

                            <div className={classes.theTableBodyCard}>
                              <div style={{ width: "10%", wordBreak: "break-all" }}>
                                <Paragraph
                                  color="#000"
                                  size="12px"
                                  bold="normal"
                                  center
                                >
                                  {index + 1}
                                </Paragraph>
                              </div>
                              <div style={{ width: "40%", wordBreak: "break-all" }}>
                                <Paragraph
                                  color="#000"
                                  size="12px"
                                  bold="normal"
                                  center
                                >
                                  {res.name}
                                </Paragraph>
                              </div>
                              <div style={{ width: "40%", wordBreak: "break-all" }}>
                                <Paragraph
                                  color="#000"
                                  size="12px"
                                  bold="normal"
                                  center
                                >
                                  {res.username}
                                </Paragraph>
                              </div>
                              {/* <div style={{ width: "25%", wordBreak: "break-all" }}>
                                <Paragraph
                                  color="#000"
                                  size="12px"
                                  bold="normal"
                                  center
                                >
                                  {res.contact}
                                </Paragraph>
                              </div> */}
                              {/* <div style={{ width: "10%", wordBreak: "break-all" }}>
                                <div className={classes.theDeleteIcon}>
                                  <img src={DeleteIcon} />
                                </div>
                              </div> */}
                            </div>
                          )}
                      </React.Fragment>
                    }
                  </div>

                </div>

                {profileData.role === 'Member' &&
                  <div className={classes.theTableCard}>
                    <div className={classes.theTFlex}>

                      <Paragraph
                        color="#402313"
                        size="14px"
                        bold="500"
                        margin="0px 0 15px"
                      >
                        Point History
                      </Paragraph>

                      <Paragraph
                        color="#402313"
                        size="14px"
                        bold="500"
                        margin="0px 0 15px"
                        style={{ textDecoration: 'underline' }}
                        onClick={() => navigate('../dashboard-all-point')}
                      >
                        view all &gt;&gt;
                      </Paragraph>
                    </div>

                    <div className={classes.theTable}>
                      <div className={classes.theTableHeader}>
                        <div style={{ width: "5%", wordBreak: "break-all" }}>
                          <Paragraph
                            color="#000"
                            size="12px"
                            bold="normal"
                            center
                          >
                            No.
                          </Paragraph>
                        </div>
                        <div style={{ width: "28%", wordBreak: "break-all" }}>
                          <Paragraph
                            color="#000"
                            size="12px"
                            bold="normal"
                            center
                          >
                            Date / Time
                          </Paragraph>
                        </div>
                        <div style={{ width: "28%", wordBreak: "break-all" }}>
                          <Paragraph
                            color="#000"
                            size="12px"
                            bold="normal"
                            center
                          >
                            Description
                          </Paragraph>
                        </div>
                        <div style={{ width: "12%", wordBreak: "break-all" }}>
                          <Paragraph
                            color="#000"
                            size="12px"
                            bold="normal"
                            center
                          >
                            Amount
                          </Paragraph>
                        </div>
                        <div style={{ width: "25%", wordBreak: "break-all" }}>
                          <Paragraph
                            color="#000"
                            size="12px"
                            bold="normal"
                            center
                          >
                            Status
                          </Paragraph>
                        </div>
                      </div>
                      {walletListData != null &&
                        <React.Fragment>
                          {walletListData.length == 0 ?
                            <Paragraph
                              color="#402313"
                              size="16px"
                              bold="bold"
                              margin="0px 0 15px"
                              center
                            >
                              No Point History
                            </Paragraph>
                            :
                            walletListData.map((res, index) =>
                              <div className={classes.theTableBodyCard}>
                                <div style={{ width: "5%", wordBreak: "break-all" }}>
                                  <Paragraph
                                    color="#000"
                                    size="12px"
                                    bold="normal"
                                    center
                                  >
                                    1
                                  </Paragraph>
                                </div>
                                <div style={{ width: "28%", wordBreak: "break-all" }}>
                                  <Paragraph
                                    color="#000"
                                    size="12px"
                                    bold="normal"
                                    center
                                  >
                                    03 Nov 2022 13:06:41
                                  </Paragraph>
                                </div>
                                <div style={{ width: "28%", wordBreak: "break-all" }}>
                                  <Paragraph
                                    color="#000"
                                    size="12px"
                                    bold="normal"
                                    center
                                  >
                                    code applied by friend
                                  </Paragraph>
                                </div>
                                <div style={{ width: "12%", wordBreak: "break-all" }}>
                                  <Paragraph
                                    color="#000"
                                    size="12px"
                                    bold="normal"
                                    center
                                  >
                                    + 000
                                  </Paragraph>
                                </div>
                                <div style={{ width: "25%", wordBreak: "break-all" }}>
                                  <Paragraph
                                    color="#00BE00"
                                    size="12px"
                                    bold="normal"
                                    center
                                  >
                                    Successful
                                  </Paragraph>
                                </div>
                              </div>
                            )}
                        </React.Fragment>
                      }
                    </div>

                  </div >
                }
              </div >
            </div >
          }
        />
      }
    </React.Fragment >
  );
}
