import styled from "styled-components/macro";
import { makeStyles } from "@mui/styles";
import { md } from "../../styles/responsive";
import { FormControl, TextField } from "@mui/material";

// import backgrounddash from "../../assets/images/backgrounddash.png";

export const FormInputSelect = styled(FormControl)`
  display: block;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #28329b !important;
  border-radius: 6px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  margin-bottom: 1rem;
  .MuiInput-underline:before {
    border-bottom: none !important;
  }
  .MuiInput-underline:after {
    border-bottom: none !important;
  }
  .MuiInputBase-root {
    width: 100%;
  }
  .MuiInputBase-root {
    font-weight: 500 !important;
  }
  .MuiInput-underline {
    &:before {
      position: relative !important;
    }
  }
`;

const config = {
  theCardChildren: {
    position: "relative",
    background: "#FFB90F",
    padding: "1rem 2rem",
    borderRadius: "10px",
    marginBottom: "2rem",
    overflow: "hidden",
  },

  theListCourse: {
    position: "relative",
    borderRadius: "10px",
    border: "2px solid #eaeaea",
    padding: "1rem",
    display: "block",
    background: "#fff",
    marginBottom: "1rem",

    ...md("sm", {
      display: "flex",
      justifyContent: "space-between",
    }),
  },

  theFlexDetail: {
    display: "block",
    width: "100%",

    ...md("sm", {
      display: "flex",
      //   justifyContent: "space-between",
      alignItems: "center",
      width: "100%",
    }),
  },

  theFlexDetail_: {
    display: "flex",

    width: "100%",
    marginTop: "1rem",

    ...md("sm", {
      display: "flex",
      //   justifyContent: "space-between",
      alignItems: "center",
      justifyContent: "flex-end",
      //   width: "18%",
      marginTop: "0",
    }),
  },

  theCourseIMG: {
    position: "relative",
    width: "95%",
    marginBottom: "1rem",

    ...md("sm", {
      width: "20%",
      marginBottom: "0",
      marginRight: "1rem",
    }),

    ...md("md", {
      width: "25%",
      marginBottom: "0",
      marginRight: "1rem",
    }),

    ...md("xmd", {
      width: "15%",
      marginBottom: "0",
      marginRight: "1rem",
    }),

    "& img": {
      width: "100%",
    },
  },

  theAbsolute: {
    display: "block",
    position: "absolute",
    right: "2%",
    top: "2%",
    zIndex: "1",

    ...md("sm", {
      display: "none",
    }),
  },

  theHide: {
    display: "none",

    ...md("sm", {
      display: "flex",
    }),
  },

  // PROFILE

  theTab_: {
    position: "relative",
  },

  theList: {
    position: "relative",

    alignItems: "center",
    display: "block",
    width: "100%",
    marginBottom: "1rem",
    ...md("sm", {
      alignItems: "center",
      display: "flex",
      marginBottom: "0",
    }),
  },

  theTabActive: {
    position: "relative",
    background: "#00B9F0",
    padding: ".4rem 1rem",
    display: "block",
    marginBottom: ".4rem",

    ...md("sm", {
      borderRadius: "8px 8px 0px 0px",
      marginRight: ".4rem",
      marginBottom: "0",
    }),
  },

  theTab: {
    position: "relative",
    background: "#fff",
    padding: ".4rem 1rem",
    display: "block",
    marginBottom: ".4rem",
    ...md("sm", {
      borderRadius: "8px 8px 0px 0px",
      marginRight: ".4rem",
      marginBottom: "0",
    }),
  },

  theContent: {
    position: "relative",
    padding: "2rem",
    background: "#fff",
    borderRadius: "0px 8px 8px 8px",
  },

  // MY CHILDREN DETAIL

  theCardB: {
    position: "relative",
    background: "#fff",
    boxShadow: "0px 6px 10px 2px rgba(0,0,0,0.16)",
    borderRadius: "8px",
    padding: "1rem",
    marginBottom: "1rem",
  },
  theRow: {
    padding: ".8rem 0",
    // borderTop: "1px solid #d7d7d7",
    borderBottom: "1px solid #e0e0e0",
  },

  theFlexx: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

  // OVERLAY BACKGROUND

  theOverlayx: {
    position: "absolute",
    width: "calc(100% - 240px)",
    // overflow: "hidden",
    // height: "1500px",

    "& img": {
      width: "100%",
    },
  },

  theBGDashboard: {
    position: "relative",
    width: "100%",
    height: "auto",
    // backgroundImage: "url(" + backgrounddash + ")",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    padding: "0 0 46px",

    ...md("sm", {
      height: "auto",
    }),

    ...md("md", {
      // height: "100vh",
    }),
  },

  theLTFlex: {
    display: 'flex',
    flexDirection: 'column',

    ...md("sm", {
      flexDirection: 'row',
    }),
  },
  theLevelCard: {
    width: "100%",
    backgroundColor: "#fff",
    boxShadow: "0px 3px 6px #00000029",
    borderRadius: "6px",
    padding: "10px 0",
    display: 'flex',
    justifyContent: "space-between",
    alignItems: 'center',
    margin: "0 0 20px",
    flexDirection: 'column',

    ...md("md", {
      width: "85%",
      flexDirection: 'row',
      padding: "10px 35px",
    }),
  },
  theFlexText: {
    margin: "0 42px 10px 0",

    ...md("sm", {
      margin: "0 0 10px 56px"
    }),

    ...md("md", {
      margin: "0 0 0 56px"
    }),

    ...md("lg", {
      margin: "0 0 0 112px"
    }),
  },
  theShareCard: {
    width: "100%",
    backgroundColor: "#fff",
    boxShadow: "0px 3px 6px #00000029",
    borderRadius: "6px",
    padding: "22px 0",
    display: 'flex',
    justifyContent: "space-between",
    alignItems: 'center',
    margin: "0 0 20px",
    flexDirection: 'column',

    ...md("md", {
      width: "85%",
      flexDirection: 'row',
      padding: "22px 35px",
    }),
  },
  theSCLeft: {

  },
  theSCLFlex: {
    display: 'flex',
    alignItems: 'center',
  },
  theReferal: {
    border: "1px solid #707070",
    padding: "12px 70px",
    borderRadius: "9px",
    margin: "0 8px 0 0"
  },
  theCopy: {
    width: "40px",
    height: "40px",
    borderRadius: "9px",
    backgroundColor: "#D5AE35",
    display: 'flex',
    justifyContent: "center",
    alignItems: "center"
  },
  theSocialFlex: {
    display: 'flex',
    alignItems: "center",
    justifyContent: "center",
    margin: "20px 0 10px",

    ...md("md", {
      justifyContent: "flex-start",
      margin: "20px 0 0",
    }),
  },

  theSocialIcon: {
    width: "20px",
    height: "20px",
    margin: "0 12px 0 0",

    "& img": {
      width: "100%",
      height: "20px",
    },
  },

  theSCRImg: {
    width: "100%",

    "& img": {
      width: "100%",
    },
  },
  theTableCard: {
    width: "100%",
    margin: '0 0 20px',

    ...md("md", {
      width: "91%",
    }),
  },
  theTFlex: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  theTable: {
    // overflowY: 'scroll',
  },
  theTableHeader: {
    backgroundColor: "#F6F6F6",
    display: 'flex',
    width: "100%",
    padding: "12px 0",
    margin: "0 0 15px"
  },
  theTableBodyCard: {
    backgroundColor: "#fff",
    display: 'flex',
    width: "100%",
    padding: "12px 0",
    margin: "0 0 15px",
    boxShadow: "0px 3px 6px #00000029"
  },

  theDeleteIcon: {
    width: "16px",
    height: "16px",

    "& img": {
      width: "100%",
      height: "16px",
    },
  },
};

export default makeStyles(config);
