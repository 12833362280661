import React, {
  useState,
  useEffect
} from "react";
import {
  Drawer,
  List,
  ListItem,
  ListItemText,
  TextField,
  Button as Btn,
  Menu,
  MenuItem,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Close, Search } from "@mui/icons-material";
import LanguageIcon from "@mui/icons-material/Language";
// import { useLocalStorage } from "../../hooks/useLang";

import logo from "../../assets/images/logo.png";

import {
  NavbarSidebarList,
  NavbarSidebarListLogo,
  CloseButtonBox,
  CloseIcon,
  SearchContainer,
  Box,
  SearchField,
  SearchButtonMoblie,
  SearchIcon,
} from "./styles";
import Button from "../Button/Button";
import { Modal } from "@mui/material";
// import commingbg from "../../assets/images/commingbg.png";
// import comingsoon from "../../assets/images/comingsoon.png";
import { ModalBox } from "./styles";
import Paragraph from "../Typography/Paragraph";
import Loader from "react-js-loader";
import toast from 'react-simple-toasts';
import {
  get_profile,
  get_wallet,
  edit_name,
} from "../../API/API";
import useUser from "../../hooks/useUser";

const useStyle = makeStyles((theme) => ({
  sidenavbar: {
    width: 300,
  },
  theBGColor: {
    background: "#2C3248",
  },
  theBGMain: {
    position: "relative",
    width: "100%",

    "& img": {
      width: "100%",
    },
  },

  theContent: {
    position: "absolute",
    left: "0",
    right: "0",
    marginRight: "auto",
    marginLeft: "auto",
    top: "50%",
    transform: "translateY(-50%)",
    padding: "3rem",
  },
}));

const SidebarMenuDashboard = ({ open, onClose, handleDrawerToggle }) => {
  const classes = useStyle();
  const location = useLocation();
  const {
    set_user,
    get_user
  } = useUser();

  const [profileData, setProfileData] = useState(null)

  const sideDrawerList = (anchor) => (
    <div
      className={classes.sidenavbar}
      role="presentation"
      onClick={() => handleDrawerToggle(anchor, false)}
      onKeyDown={() => handleDrawerToggle(anchor, false)}
    ></div>
  );

  const handleLogout = () => {
    set_user(null);
    window.localStorage.removeItem("token");
    history("../");
  };

  const getData = async () => {
    var resProfile = await get_profile({})
    if (resProfile.status) {
      setProfileData(resProfile.data)
    }
  }

  useEffect(() => {
    getData();
  }, [])

  const history = useNavigate();

  const [openAll, setOpenAll] = React.useState(false);

  const handleClickOpenAll = () => {
    setOpenAll(true);
  };

  const handleCloseAll = () => {
    setOpenAll(false);
  };

  return (
    <>
      <Drawer
        className="theBGColor"
        anchor="left"
        open={open}
        onClose={onClose}
      >
        {sideDrawerList("left")}
        <List component="nav">
          <NavbarSidebarList>
            <NavbarSidebarListLogo>
              <Link to="/">
                <img src={logo} />
              </Link>
            </NavbarSidebarListLogo>
            <CloseButtonBox>
              <a onClick={handleDrawerToggle}>
                <CloseIcon />
              </a>
            </CloseButtonBox>

            <br />
            <ListItem>
              <a
                style={{
                  textDecoration: "none",
                  color: "#000",
                  width: "100%",
                }}
                href="/dashboard"
              >
                <ListItemText
                  primary="MY PROFILE"
                  style={{
                    color: location.pathname.replace(/[^A-Za-z]+/g, '') === 'dashboard' ? "#D5AE35" : "#000",
                    fontWeight: location.pathname.replace(/[^A-Za-z]+/g, '') === 'dashboard' ? "bold!important" : "light",
                  }}
                />
              </a>
            </ListItem>
            {profileData != null && profileData.role !== 'Member' &&
              <ListItem>
                <a
                  style={{
                    textDecoration: "none",
                    color: "#000",
                    width: "100%",
                  }}
                  href="/dashboard-agent-wallet"
                >
                  <ListItemText
                    primary="MY WALLET"
                    style={{
                      color: location.pathname.replace(/[^A-Za-z]+/g, '') === 'dashboardagentwallet' ? "#D5AE35" : "#000",
                      fontWeight: location.pathname.replace(/[^A-Za-z]+/g, '') === 'dashboardagentwallet' ? "bold!important" : "light",
                    }}
                  />
                </a>
              </ListItem>
            }
            <ListItem>
              <a
                style={{
                  textDecoration: "none",
                  color: "#000",
                  width: "100%",
                }}
                href="/dashboard-order"
              >
                <ListItemText
                  primary="MY ORDERS"
                  style={{
                    color: location.pathname.replace(/[^A-Za-z]+/g, '') === 'dashboardorder' ? "#D5AE35" : "#000",
                    fontWeight: location.pathname.replace(/[^A-Za-z]+/g, '') === 'dashboardorder' ? "bold!important" : "light",
                  }}
                />
              </a>
            </ListItem>
            <ListItem>
              <a
                style={{
                  textDecoration: "none",
                  color: "#000",
                  width: "100%",
                }}
                href="/dashboard-member-referral"
              >
                <ListItemText
                  primary="MY REFERRALS"
                  style={{
                    color: location.pathname.replace(/[^A-Za-z]+/g, '') === 'dashboardreferral' ? "#D5AE35" : "#000",
                    fontWeight: location.pathname.replace(/[^A-Za-z]+/g, '') === 'dashboardreferral' ? "bold!important" : "light",
                  }}
                />
              </a>
            </ListItem>
            <ListItem>
              <a
                style={{
                  textDecoration: "none",
                  color: "#000",
                  width: "100%",
                }}
                href="/dashboard-setting"
              >
                <ListItemText
                  primary="SETTINGS"
                  style={{
                    color: location.pathname.replace(/[^A-Za-z]+/g, '') === 'dashboardsetting' ? "#D5AE35" : "#000",
                    fontWeight: location.pathname.replace(/[^A-Za-z]+/g, '') === 'dashboardsetting' ? "bold!important" : "light",
                  }}
                />
              </a>
            </ListItem>
            <ListItem>
              <a
                style={{
                  textDecoration: "none",
                  color: "#000",
                  width: "100%",
                }}
                // href="/"
                onClick={() => handleLogout()}
              >
                <ListItemText
                  primary="LOGOUT"
                  style={{
                    color: "#000",
                    fontWeight: "light !important",
                  }}
                />
              </a>
            </ListItem>
            <hr />
            <ListItem>
              <a
                style={{
                  textDecoration: "none",
                  color: "#000",
                  width: "100%",
                }}
                href="/"
              >
                <ListItemText
                  primary="HOME"
                  style={{
                    color: "#000",
                    fontWeight: "700 !important",
                  }}
                />
              </a>
            </ListItem>
            <ListItem>
              <a
                style={{
                  textDecoration: "none",
                  color: "#000",
                  width: "100%",
                }}
                href="/pillow"
              >
                <ListItemText
                  primary="PILLOW"
                  style={{
                    color: "#000",
                    fontWeight: "700 !important",
                  }}
                />
              </a>
            </ListItem>
            <ListItem>
              <a
                style={{
                  textDecoration: "none",
                  color: "#000",
                  width: "100%",
                }}
                href="/materess"
              >
                <ListItemText
                  primary="MATTRESS"
                  style={{
                    color: "#000",
                    fontWeight: "700 !important",
                  }}
                />
              </a>
            </ListItem>
            <ListItem>
              <a
                style={{
                  textDecoration: "none",
                  color: "#000",
                  width: "100%",
                }}
                href="/sale"
              >
                <ListItemText
                  primary="SALE"
                  style={{
                    color: "#000",
                    fontWeight: "700 !important",
                  }}
                />
              </a>
            </ListItem>
            <ListItem>
              <a
                style={{
                  textDecoration: "none",
                  color: "#000",
                  width: "100%",
                }}
                href="/about"
              >
                <ListItemText
                  primary="WHY WOODPECKER"
                  style={{
                    color: "#000",
                    fontWeight: "700 !important",
                  }}
                />
              </a>
            </ListItem>
            <ListItem>
              <a
                style={{
                  textDecoration: "none",
                  color: "#000",
                  width: "100%",
                }}
                href="/contact"
              >
                <ListItemText
                  primary="CONTACT US"
                  style={{
                    color: "#000",
                    fontWeight: "700 !important",
                  }}
                />
              </a>
            </ListItem>
          </NavbarSidebarList>
        </List>
      </Drawer>

      <Modal
        open={openAll}
        onClose={handleCloseAll}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModalBox>
          <div className={classes.BGMain}>
            {/* <img src={commingbg} /> */}
            <div className={classes.theContent}>
              <div>{/* <img src={comingsoon} /> */}</div>
              <br />
              <div>
                <Paragraph center color="#fff" bold="700">
                  Working is still in progress. Stay Tuned!
                </Paragraph>
              </div>
              <br />
              <Button
                label="Back"
                color="#fff"
                bg="#3094EB"
                br="8px"
                onClick={() => handleCloseAll()}
              />
            </div>
          </div>
        </ModalBox>
      </Modal>
    </>
  );
};

export default SidebarMenuDashboard;
